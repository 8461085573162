@import '../../marketplace.css';

.root {
  position: relative;
  display: inline-block;
  border-right: 2px solid var(--matterColorNegative);

  @media (--viewportMobile) {
    position: unset;
  }
}

.label {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceSearchFilterLabelFontStyles;

  padding: 8px 10px 8px 14px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 25px;
  border: none;
  background: none;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-transform: capitalize;
  color: var(--marketplaceColor);
  min-width: 93px;

  &:focus {
    outline: none;
    background-color: var(--matterColorLight);
    border-color: transparent;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
  }

  @media (--viewportMobile) {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    padding: 0 14px;
  }
}

.labelSelected {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceSearchFilterLabelFontStyles;

  padding: 8px 10px 8px 14px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 25px;
  color: var(--marketplaceColor);
  border: none;
  background: none;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  &:hover,
  &:focus {
    border: 1px solid var(--marketplaceColorDark);
  }

  @media (--viewportMobile) {}
}
