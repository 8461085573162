@import '../../../../marketplace.css';


.listingCards {
  /* padding: 0 24px 96px 24px; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* justify-content: space-between; */

  @media (--viewportMedium) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 7px 5px;
  }

  @media (--viewportLarge) {
    padding: 0;
  }

  @media (--viewportMobile) {
    justify-content: center;
  }
}

.listingCard {
  margin-bottom: 20px;
  flex-basis: 100%;

  @media (--viewportLarge) {
    /**
      * resultPanelWidthRatio = 0.625 aka 62.5%

      resultPanel: 62.5vw from 1024px = 640px
      panelPaddings: - 2*36px = 72px

      columnCount: 2
      guttersBetweenColumns: 24px

      (resultPanel - (panelPaddings + guttersBetweenColumns)) / columnCount = listingCardWidth
      ergo => listingCardWidth: 272px

    * flex-basis: calc((100%/columnCount) - (guttersBetweenColumns / columnCount))
    */
    flex-basis: calc(50% - 12px);
    margin-right: 24px;
  }

  @media (--viewportXLarge) {
    /**
     * resultPanelWidthRatio = 0.625 aka 62.5%

      resultPanel: 62.5vw from 1920px = 1200px
      panelPaddings: - 2*36px = 72px

      columnCount: 3
      guttersBetweenColumns: 2*24px = 48px

      (resultPanel - (panelPaddings + guttersBetweenColumns)) / columnCount = listingCardWidth
      ergo => listingCardWidth: 360px

     * flex-basis: calc((100%/columnCount) - (guttersBetweenColumns / columnCount))
     */

    flex-basis: calc(33.33% - 16px);
    margin-right: 24px;
  }
}

/**
 * Remove margin-right from listingCards on the last column
 * This is a special case in the world of breakpoints. Nth-of-type effects every nth item.
 * Here we take margin-right away, but we don't want it affect the next column breakpoint.
 */

.listingCard:nth-of-type(2n) {
  @media screen and (min-width: 1024px) and (max-width: 1920px) {
    margin-right: 0;
  }
}

.listingCard:nth-of-type(3n) {
  @media (--viewportXLarge) {
    margin-right: 0;
  }
}

.viewButtonClass {
  background: var(--marketplaceColor);
  border-radius: 4.57592px;
  font-style: italic;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: var(--matterColorLight);
  width: 100%;
  height: 39px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    text-decoration: none;
  }
}

.authorNameClass {
  font-weight: 700;
  font-size: 16.5px;
  line-height: 20px;
  color: #0d1624;
  margin-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.startingAtClass {
  font-style: normal;
  font-weight: 400;
  font-size: 9.92653px;
  text-transform: capitalize;
  color: var(--matterColorLight);
  border: 1px solid var(--matterColorLight);
  border-radius: 34px;
  position: absolute;
  top: -22px;
  right: 5px;
  min-width: 130px;
  min-height: 30px;
  display: flex;
  align-items: center;
  padding: 2px 5px;
  justify-content: center;
  color: var(--matterColorLight);
  background-color: var(--marketplaceColorDarkest);

  @media (--viewportMobile) {
    top: -27px;
    right: 10px;
  }

  & > div {
    height: 16px;
  }
}

.startingAtClass span {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-transform: capitalize;
  color: var(--matterColorLight);
  padding-left: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  @media (--viewportMobile) {
    font-size: 18px;
  }
}

.cardContainerClass {
  background-color: var(--matterColorLight);
  display: flex;
  /* flex-shrink: 0; */
  flex-direction: column;
  padding-bottom: 0;
  height: fit-content;
  width: 218px;
  position: relative;
  border-radius: 10px;
  margin: 10px 5px;
  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.047);
  font-family: 'HelveticaNeue', 'Source Sans Pro', sans-serif;
  box-sizing: border-box;
  border: 0.826475px solid #ccd7ea;

  @media (min-width: 360px) and (max-width: 400px) {
    width: 340px;
  }

  @media (min-width: 401px) and (max-width: 480px) {
    width: 380px;
  }
}

.imageWrapperClass {
  position: relative;
  width: 100%;
  aspect-ratio: 380 / 300;
  border-radius: 4px;
  transition: var(--transitionStyleButton);
  cursor: pointer;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
}

.addressWrapperClass {
  display: flex;
  column-gap: 4px;
  align-items: center;
  padding: 4px 0px;
  margin-top: 7px;
}

.reviewsWrapperClass {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 2px;
  margin-top: 11px;
}

.descriptionClass {
  padding-top: 8px;
  margin: 0;
  height: 34px;
  min-height: 76px;
  width: 100%;
  height: 100%;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #6e6f71;

  @media (--viewportMobile) {
    font-size: 16px;
    line-height: 18px;
  }
}

.buttonWrapperClass {
  /* margin-top: 0.5rem; */
  margin-top: 10px;
  padding: 8px 0px;
}