@import '../../marketplace.css';

.mainCategoryFilters {
  font-weight: 600;
  font-size: 25px;
  line-height: 34px;
  color: #B2AEAB;
  position: relative;
}

.selectedMainCategory{
  color: var(--marketplaceColor);
}
.selectedMainCategory::after {
  content: "";
  position: absolute;
  height: 5px;
  width: 100%;
  background-color: var(--marketplaceColor);
  bottom: -31px;
}

.proCategoriesWrapper {
  max-width: 1175px;
  margin: 0 auto;
  padding-bottom: 20px;

  & .proCategoriesMain {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    column-gap: 70px;

    @media (--viewportMobile) {
      justify-content: space-between;
      column-gap: 0;
    }
    
    & .proSelectedCategory{
         color: #101C30;
        font-weight: 700;
        font-size: 25px;
       line-height: 16px;
       cursor: pointer;
        & span{
          color: #03e4fc;
        }
        @media (--viewportMobile) {
          font-size: 20px;
        }
    }

    & .proCategory {
      color: #c4c4c4;
      font-weight: 600;
      font-size: 25px;
      line-height: 16px;
      @media (--viewportMobile) {
        font-size: 20px;
      }
    }
  }
}

.mainCategoryFiltersBox {
  border-top: 1px solid #D9D9D9;
  border-bottom: 1px solid #D9D9D9;
}

.mainCategoryFiltersWrapper {
  max-width: 1175px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 16px 0 31px 0;
  &:before{
    content: '';
    position: absolute;
    top: -9px;
    left: 32px;
    width: 15px;
    height: 15px;
    border-top: 1px solid #D9D9D9;
    border-left: 1px solid #D9D9D9;
    z-index: -1;
    transform: rotate(44deg);
  }
  @media (--viewportMobile) {
    grid-template-columns: repeat(2, 1fr);
    padding: 17px 16px 24px 16px;
  }
}

.mainAdventureWrapper {
  &:after {
    left: 19%;
    @media (--viewportMobile) {
      left: 45%;
    }
  }
}

.mainWellnessWrapper {
  &:after {
    left: 34%;
    @media (--viewportMobile) {
      left: 74%;
    }
  }
}

.advanceFilterContainer {
  position: relative;
}

.advanceFilterWrapper {
  padding: 0 34px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 16px;
  @media (--viewportMedium) {
    padding-bottom: 20px;
  }

  @media screen and (max-width: 1023px) {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 19px;
  }

  @media (--viewportTabletDown) {
    flex-direction: column;
    padding: 10px 24px 0;
  }
}

.subCategoryItemsWrap {
  display: flex;
  column-gap: 11px;
  padding: 26px 0 30px;
  column-gap: 16px;

  @media screen and (max-width: 1200px) {
    overflow-x: scroll;
    max-width: 100%;
    overflow-y: hidden;
  }
}

.mobileSubCategoryItemsWrap {
  display: flex;
  padding: 19px 24px 19px;
  column-gap: 11px;
  overflow-x: scroll;
  width: 100%;
  background: var(--matterColorLight);
}
.subCategoryItem {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-transform: capitalize;
  color: #6e6f71;
  background: #f7f7f7;
  border-radius: 40px;
  padding: 8px 22px;
  white-space: nowrap;
  max-height: 34px;

  @media (--viewportTabletDown) {
    width: 100%;
    padding: 33px 15px 18px;
  }
}

.subCategoryItem {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-transform: capitalize;
  color: #6e6f71;
  background: #f7f7f7;
  border-radius: 40px;
  padding: 8px 22px;
  white-space: nowrap;
  max-height: 34px;

  max-width: 154px;
  white-space: nowrap;
}

.DropdownItems {
  font-weight: 400;
  font-size: 12.5046px;
  line-height: 16px;
  text-transform: capitalize;
  color: #455A64;
  display: flex;
  align-items: center;
  column-gap: 6px;
  padding: 8px 19px 10px;
  max-height: 35px;
  background: var(--matterColorLight);
  border: 1.04205px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 35.4297px;
  @media (--viewportMobile) {
    width: 88px;
  }

  & svg {
    margin-left: 4px;
    & path {
      fill: var(--matterColorDark);
    }
  }

  @media (--viewportMobile) {
    color: var(--matterColorDarkBlue);
    & svg path {
      fill: var(--matterColorDarkBlue);
    }
  }
}

.selectedSubCategoryItem {
  color: var(--matterColorLight);
  background: var(--marketplaceColor);
  @media (--viewportMobile) {
    background: var(--marketplaceColorBackground);
  }
}

.rightSideFilters {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 7px;
  padding-bottom: 4px;

  @media screen and (max-width: 400px) {
    overflow-x: scroll;
    max-width: 300px;
    padding-bottom: 15px;
    justify-content: flex-start;
  }
}

.labelSellerLevels {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-transform: capitalize;
  color: var(--marketplaceColor);
  display: flex;
  align-items: center;
  column-gap: 6px;
  white-space: nowrap;
  padding: 9px 14px;
  background: var(--matterColorLight);
  border: 1px solid var(--marketplaceColor);
  box-sizing: border-box;
  border-radius: 34px;
  max-height: 34px;

  & svg {
    margin-left: 5px;
    & path {
      fill: var(--matterColorDark);
    }
  }

  @media (--viewportMobile) {
    justify-content: space-between;
  }
}

.filterSection{

  position: relative;
}

.filterToolWrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 10px;
  padding: 57px 0 53px 0;
  max-width: 1175px;
  margin: 0 auto;
  @media (--viewportMobile) {
    max-width: 100%;
    width: 100%;
    justify-content: space-between;
    padding: 15px;
    border: 1px solid #d9d9d9;
    position: relative;
  }
}
