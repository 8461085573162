@import '../../marketplace.css';

.root {
  @apply --marketplaceModalBaseStyles;
  padding-top: 70px;

  @media (--viewportMedium) {
    flex-basis: 567px;
  }
}

.modalContent {
  flex-grow: 1;
}

/* Icon of the modal */
.modalIcon {
  @apply --marketplaceModalIconStyles;
  height: 38px;
}

/* Title of the modal */
.modalTitle {
  @apply --marketplaceModalTitleStyles;
  line-height: 20px;
  font-size: 23px;
}

.reviewee {
  white-space: nowrap;
}

/* Paragraph for the Modal */
.modalMessage {
  @apply --marketplaceModalParagraphStyles;
  margin: 16px 0 0 0;
  font-size: 15px;
}

.listingDetails {
  margin: 5px 0;
}

.listingTitle,
.listingPre {
  margin: 0;
}

.listingPre {
  margin:8px 0 3px;
  font-size: 16px;
  color: var(--matterColorDimmedText);
  line-height: 20px;
}

.listingTitle {
  font-size: 18px;
  color: var(--matterColor);
  line-height: 20px;
  font-weight: bold;
}
