@import '../../../../marketplace.css';

.languageTitle {
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: var(--matterColor);
  margin: 23px 0 13px;

  @media (--viewportMobile) {
    margin: 17px 0 16px;
  }
}

.languageOptionWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--matterColor);

  @media (--viewportMobile) {
    display: block;
    column-count: 2;
  }
}

.checkboxContainer {
  display: block;
  margin-bottom: 15px;

  @media (--viewportMobile) {
    margin-bottom: 24px;
  }
}

.checkboxContainer input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.checkboxContainer label {
  position: relative;
  cursor: pointer;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: var(--matterColor);
}

.checkboxContainer label:before {
  content: '';
  -webkit-appearance: none;
  border-radius: 3px;
  background-color: #1c2a3f;
  border: 2px solid #2f3d54;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 10px;
}

.checkboxContainer input:checked+label:after {
  content: '';
  display: block;
  position: absolute;
  top: 8px;
  left: 9px;
  width: 6px;
  height: 14px;
  border: solid var(--marketplaceColor);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);

  @media (--viewportMobile) {
    top: 3px;
  }
}

.filterButtonItem {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-transform: capitalize;
  color: #272727;
  background: var(--matterColorLight);
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 35px;
  padding: 9px 12px 9px 14px;
  white-space: nowrap;
  max-height: 34px;
  display: flex;
  column-gap: 9px;

  @media (--viewportMobile) {
    padding: 11px 19px 8px 19px;

    & svg path {
      fill: #6e6f71;
    }

    & span {
      display: none;
    }
  }
}

.filterButtonItemOpen {
  position: relative;
}

.filterButtonItemOpen:after {
  content: '';
  position: absolute;
  bottom: -30px;
  right: 37%;
  background-color: #101c30;
  width: 20px;
  height: 10px;
  -webkit-clip-path: polygon(50% 0%, 3% 76%, 100% 76%);
  clip-path: polygon(50% 0%, 3% 76%, 100% 76%);

  @media (--viewportMobile) {
    bottom: -20px;
  }
}

.topbarFilterButtonItemOpen {
  position: relative;
}

.topbarFilterButtonItemOpen:after {
  content: '';
  position: absolute;
  bottom: -30px;
  right: -14%;
  background-color: #101c30;
  width: 20px;
  height: 10px;
  -webkit-clip-path: polygon(50% 0%, 3% 76%, 100% 76%);
  clip-path: polygon(50% 0%, 3% 76%, 100% 76%);
}

.DropdownFilterPopup {
  position: absolute;
  left: 0;
  width: 1024px;
  top: 96%;
  z-index: 1;

  @media screen and (max-width: 1366px) {
    top: 46%;
  }

  @media (--viewportMobile) {
    width: 100%;
    top: 100%;
    z-index: 2;
  }
}

.advancedFiltersWrapper {
  padding: 20px 44px 51px 70px;
  background: white;
  border: 1px solid #E7E7E7;
  border-radius: 3px;
  @media (--viewportMobile) {
    padding: 19px 24px 51px;
  }
}

.advancedFiltersWrapper .FilterByTitle {
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: var(--matterColor);

  @media (--viewportMobile) {
    display: flex;
    justify-content: center;
  }
}

.filterButtonWrapper {
  display: flex;
  justify-content: flex-end;
  gap: 0 10px;

  @media (--viewportMobile) {
    margin: 22px 17px 22px 0;
  }
}

.filterResetButton {
  width: 84px;
  min-height: 40px;
  font-weight: 600;
  font-size: 12.8801px;
  line-height: 18px;
  color: var(--matterColorLight);
  background: rgba(255, 255, 255, 0.07) !important;
  border: 0;
  border-radius: 20.5px;
}

.filterApplyButton {
  width: 84px;
  min-height: 40px;
  font-weight: 600;
  font-size: 12.8801px;
  line-height: 16px;
  color: var(--matterColorLight);
  background: var(--marketplaceColorLight) !important;
  border: 0;
  border-radius: 20.5px;
}

.switch {
  display: flex;
  align-items: center;
  column-gap: 20px;
  margin: 20px 0 15px;

  & h4 {
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    color: var(--matterColor);
    margin: 0;
  }

  & input[type=checkbox] {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  & label {
    cursor: pointer;
    text-indent: -9999px;
    width: 30px;
    height: 16px;
    background: grey;
    display: block;
    border-radius: 100px;
    position: relative;
  }

  & label:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 3px;
    width: 13px;
    height: 12px;
    background: var(--marketplaceColorLightWhite);
    border-radius: 90px;
    transition: 0.3s;
  }

  & input:checked+label {
    background: var(--marketplaceColor);
  }

  & input:checked+label:after {
    left: calc(100% - 3px);
    transform: translateX(-100%);
  }

  & label:active:after {
    width: 30px;
  }
}