@import '../../marketplace.css';

:root {
  --cardSizeDesktop: 185px;
  --cardSizeMobile: 175px;
}

.root {
  /* Layout */
  display: flex;
  flex-direction: row;
  -webkit-box-shadow: 0px 1px 8px -1px #00000090;
  box-shadow: 0px 1px 8px -1px #00000090;

  &:hover {
    text-decoration: none;
  }
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
  transition: var(--transitionStyleButton);

  &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowListingCard);
  }
}

.cardNamePlaceholder {
  height: 181px;
  text-align: center;
  vertical-align: middle;
  font-weight: 700;
  font-size: 16.5px;
  line-height: 181px;
  color: #272727;
}

.descForImage {}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border-radius: var(--borderRadius);
}

.info {
  /* padding: 0.2rem; */
  flex: 1 1 auto;
  margin: 10px;

  @media (--viewportLarge) {
    /* padding: 0.6rem; */
    height: unset;
  }
}

.price {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-right: 18px;
}

.priceValue {
  /* Font */
  @apply --marketplaceH3FontStyles;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  font-size: 14px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.no_bold {
  font-weight: 300;
}

.perUnit {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.title {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 300;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.review {
  font-size: 12px;
}

.review_withYellow {
  font-size: 12px;
  color: #ffc400;
}

.star {
  display: inline-flex;
}

.star-container {
  vertical-align: unset;
}

.heart {
  float: right;
}

.certificateInfo {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.linkText {
  width: 100%;
}

.avatarWrapper {
  display: flex;
  align-items: center;
  font-size: 10px;

  @media (--viewportMobile) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.avatarImg {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  margin-right: 10px;

  @media (--viewportLarge) {
    height: 40px;
    width: 40px;
  }

  display: none;
}

.name {
  font-weight: bold;
  font-size: 13px !important;
  line-height: 16px !important;
  color: var(--marketplaceColor);
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: break-spaces;
}

.levelContainer {
  margin-left: auto;

  & div {
    padding: 0px;
  }

  @media (--viewportMobile) {
    margin-left: initial;
  }
}

.listingLink {
  /* margin: 0 auto; */
  font-size: 16px !important;

  & a {
    text-decoration: none !important;
  }
}

.headline {
  display: -webkit-box;
  color: var(--matterColorDarkBlue);
  margin-top: 5px;
  margin-bottom: 8px;
  max-height: 42px;
  font-size: 14px;
  overflow: hidden;
  width: 125px;
  text-align: left;
  line-height: 18px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: nowrap;
  white-space: break-spaces;
  word-wrap: break-word;
  font-weight: bold;

  @media (--viewportLarge) {
    margin-top: 5px;
    width: unset;
    margin: 0;
    min-height: 32px;
  }

  @media (--viewportMobile) {
    min-height: 38px;
  }
}

.star_Wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0px;
}

.rating {
  color: #e82953;
  font-size: 10px;
  font-weight: 550;

  @media (--viewportLarge) {
    font-size: 14px;
  }
}

.reviewCount {
  font-size: 12px;
  margin-left: 5px;
  color: #707070;
}

.favourite {
  margin-top: 10px;
  font-size: 12px;
  text-align: right;
  color: #30c7be;
}

.favourite a {
  display: flex;
  justify-content: flex-end;
  color: #30c7be;
}

.popular {
  background-color: var(--matterColorLight);
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  padding-bottom: 0;
  height: fit-content;
  width: 218px;
  min-width: 210px;
  position: relative;
  border-radius: 10px;
  margin: 10px 5px;
  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.047);
  font-family: 'HelveticaNeue', 'Source Sans Pro', sans-serif;
  box-sizing: border-box;
  border: 0.826475px solid #ccd7ea;

  @media (--viewportMobile) {
    width: var(--cardSizeMobile);
    margin: 10px auto;
  }

  @media (min-width: 360px) and (max-width: 400px) {
    width: 145px;
  }

  @media (min-width: 401px) and (max-width: 460px) {
    width: 170px;
  }
}

.placeholderContainer {
  background-color: #e5e5e5;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  padding-bottom: 0;
  height: fit-content;
  width: 218px;
  min-width: 210px;
  position: relative;
  border-radius: 10px;
  margin: 10px 5px;
  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.047);
  font-family: 'HelveticaNeue', 'Source Sans Pro', sans-serif;
  box-sizing: border-box;
  border: 0.826475px solid #ccd7ea;

  @media (--viewportMobile) {
    width: var(--cardSizeMobile);
    margin: 10px auto;
  }

  @media (min-width: 360px) and (max-width: 400px) {
    width: 145px;
  }

  @media (min-width: 401px) and (max-width: 460px) {
    width: 170px;
  }
}

.popular a,
.popular span,
.popular p,
.popular div {
  font-family: 'HelveticaNeue', 'Source Sans Pro', sans-serif;
}

.popularImage {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border-radius: 0px;
  object-fit: cover;
  height: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.popularImageHover {
  animation: ZoomIn 2.15s linear infinite;
}

@keyframes ZoomIn {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.3);
  }
}

.imageWrapper {
  position: relative;
  width: 100%;
  border-radius: 4px;
  transition: var(--transitionStyleButton);
  cursor: pointer;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
}

.aspectWrapper {
  aspect-ratio: 380 / 300;
  object-fit: cover;
}

.startingAt {
  font-style: normal;
  font-weight: 400;
  font-size: 7.62653px;
  line-height: 16px;
  text-transform: capitalize;
  color: var(--matterColorLight);
  border: 1px solid var(--matterColorLight);
  border-radius: 34px;
  position: absolute;
  top: -20px;
  right: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--matterColorLight);
  background-color: var(--marketplaceColorDarkest);

  & > div {
    height: 16px;
  }
}

.startingAt span {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-transform: capitalize;
  color: var(--matterColorLight);
  padding-left: 6px;
}

.whatsappSection {
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin: 11px 0 0 0;

  @media (--viewportMobile) {
    width: 100%;
  }
}

.iconWhatsapp {
  width: 21px;
  height: 21px;
}

.cardDetailsWrapper {
  padding: 5px 18px 10px;
}
.cardDetailsPlaceholder {
  padding: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  height: 262.52px;
  
  & p {
    font-size: 14px;
    line-height: 16px;
    color: #6e6f71;
  }
}

.authorName {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #272727;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.addressWrapper {
  display: flex;
  column-gap: 4px;
  align-items: center;
  padding: 4px 0px;
  min-height: 42px;

  & span {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #6e6f71;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    @media (--viewportMobile) {
      font-size: 15px;
    }
  }

  & svg {
    fill: transparent;
  }
}

.reviewsWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 2px;
}

.reviersCountRating {
  background: rgba(52, 107, 246, 0.1);
  border-radius: 5px;
  padding: 2px 5px;
  font-weight: 600;
  font-size: 16px;
  line-height: 14px;
  color: #346bf6;
  margin-right: 8.27px;
  display: flex;
  align-items: center;
}

.ratingWrapper {
  margin-left: 5px;
  vertical-align: middle;
}

.reviersCount {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #346bf6;
}

.description {
  padding-top: 8px;
  margin: 0;
  height: 34px;
  min-height: 76px;
  width: 100%;
  height: 100%;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #6e6f71;

  @media (--viewportMobile) {
    font-size: 16px;
    line-height: 18px;
  }
}

.viewButton {
  background: var(--marketplaceColor);
  border-radius: 5px;
  font-style: italic;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: var(--matterColorLight);
  width: 100%;
  height: 39px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    text-decoration: none;
  }
}

.buttonWrapper {
  /* margin-top: 1rem; */
  margin-top: 10px;
  padding: 8px 0px;
}

.likeButton {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 1;
}

.favButtonNotSelected {
  cursor: pointer;
  display: block;

  &:hover {
    transform: scale(1.1);
    filter: invert(18%) sepia(96%) saturate(6390%) hue-rotate(332deg) brightness(93%) contrast(108%);
  }
}

.favButtonSelected {
  cursor: pointer;
  display: block;
  filter: invert(18%) sepia(96%) saturate(6390%) hue-rotate(332deg) brightness(93%) contrast(108%);

  &:hover {
    transform: scale(1.1);
  }
}

.referencePriceClass {
  font-size: 14px;
  color: var(--marketplaceRefCurrencyColor);
}

.skeletonClass {
  min-width: 230px;
  height: 440px;
  border-radius: 4px;
  background: var(--matterColorAnti);
  animation: 1.5s ease-in-out 0.5s infinite;
  -webkit-animation: cardanimation 1.5s ease-in-out 0.5s infinite;
}

@keyframes cardanimation {
  0% {opacity: 0.5;}
  50% {opacity: 1;}
  100% {opacity: 0.5;}
}