@import '../../marketplace.css';

.root {
  position: relative;
  width: 100%;
  @media (--viewportMedium) {
    flex-direction: column;
  }
  @media (--viewportLaptop) {
    flex-direction: row;
  }
}

.longInfo {
  flex-wrap: wrap;
}

.filters {
  /* margin-bottom: 24px; */
  padding-bottom: 21px;
  /* border-bottom: 1px solid #e1e1e1; */
  & > *:not(:last-child) {
    margin-bottom: 20px;
    @media (--viewportMobile) {
      margin-bottom: 5px;
    }
  }
  @media (--viewportMobile) {
    margin-top: 21px;
    margin-right: 0px !important;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.otherFilters {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  align-items: center;
  width: 100%;
  @media (--viewportMedium) {
    overflow-x: visible;
    margin-bottom: 0;
  }
  @media (--viewportMobile) {
    justify-content: center;
  }
  & > div {
    flex-shrink: 0;
    @media (--viewportMobile) {
      margin-bottom: 23px;
    }
    & > div:first-child{
      padding-left: 0;
    }
  }
}

.languageFilter{
  @media (--viewportMobile) {
    display: none !important;
  }
}

.searchResultSummary {
  @apply --marketplaceH4FontStyles;
  line-height: 20px;
  margin-top: 9px;
  margin-bottom: 11px;

  /* parent uses flexbox: this defines minimum width for text "6 results" */
  flex-shrink: 0;

  display: flex;
  align-items: center;
  @media (--viewportMobile) {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 6px;
  }
}
.addEnquiryBtn {
  padding: 9px 16px 10px 16px;
  font-size: 16px;
  color: var(--marketplaceColorSecond);
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-weight: bold;
  transition: all ease-in-out 0.1s;
  &:hover,
  &:focus {
    outline: none;
    background-color: var(--matterColorLight);
    border-color: transparent;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
  }
  &:hover {
    text-decoration: none;
  }
}

.noSearchResults {
  @apply --marketplaceH4FontStyles;

  /* Reserves 48px of vertical space */
  line-height: 20px;
  margin-top: 16px;
  margin-bottom: 12px;

  /* parent uses flexbox: this defines minimum width for translated text "noResults" */
  flex-shrink: 0;
  @media (--viewportMobile) {
    flex-basis: auto;
  }
}

.loadingResults {
  @apply --marketplaceH4FontStyles;
  line-height: 20px;

  /* Cover parent element's space*/
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* Layout */
  padding: 9px 24px 0 24px;
  margin: 0;
  background-color: var(--matterColorBright);
}

.resultsFound {
  margin-right: 12px;
  font-weight: bold;
  white-space: nowrap;
}

.searchFiltersPanelClosed {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceSearchFilterLabelFontStyles;

  display: inline-block;
  padding: 9px 16px 10px 16px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;

  &:focus {
    outline: none;
    background-color: var(--matterColorLight);
    border-color: transparent;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
  }
}

.searchFiltersPanelOpen {
  @apply --marketplaceButtonStyles;
  @apply --marketplaceSearchFilterLabelFontStyles;
  font-weight: var(--fontWeightSemiBold);

  display: inline-block;
  padding: 9px 16px 10px 16px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  border: 1px solid var(--marketplaceColor);

  &:hover,
  &:focus {
    border: 1px solid var(--marketplaceColorDark);
  }
}

.additional {
  @media (--viewportMobile) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 24px;
  }
  @media (--viewportLarge) {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  @media (--viewportLaptop) {
    margin-top: 0;
  }
}

.sortBy {
  & > button {
    color: var(--marketplaceColorSecond);
  }
  @media (--viewportMobile) {
    margin-left: initial !important;
  }
  @media (--viewportMedium) {
    margin-left: initial !important;
  }
}

.classCalendarContainer {
  margin: 24px 0;
  max-width: 1040px;
  @media (--viewportMobile) {
    margin: 33px 12px 24px;
  }
}

.filterKeywordWrap {
  display: flex;
  justify-content: left;
  align-items: center;
  @media (--viewportMobile) {
    justify-content: center;
    width: 100%;
  }
}

.classLeftSpace{
  margin-left: 30px;
}

.filterWrap {
  width: auto;
  max-height: 41px;
  background: var(--matterColorLight);
  border-radius:28px;
  border: 1px solid #d6d6d6;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media (--viewportMobile) {
    width: 100%;
    border-radius: 28px;
    margin-bottom: 18px;
  }
}
.filterWrap label {
  padding: 0 10px;
  margin: 0 20px 0 0;
  @media (--viewportMobile) {
    margin: 0 6px 0 0;
  }
}
.filterKeywordWrap input {
  width: 160px;
  border-bottom: none;
  font-weight: normal;
  font-size: 16px;
  line-height: 15px;
  color: var(--matterColorDimmedText);
  @media (--viewportMobile) {
    width: 330px;
  }
  &::placeHolder{
    color: var(--marketplaceColor)
  }
}
.labelText {
  color: var(--sectionHeroBg);
  cursor: pointer;
}

.btnSearchIcon{
  min-height: 45px;
  background: transparent;
  border-radius: 30px;
  color: var(--matterColorLight);
  font-weight: bold;
  padding: 11px 15px 11px 8px;
  transform: scaleX(-1);
  & svg{
    width: 21px;
    height: 24px;
  }
  @media (--viewportMobile) {
    width: 40px;
  }
}
.btnSearchIcon:hover,
.btnSearchIcon:focus {
  background-color: transparent;
  box-shadow: none;
}

.btnapply {
  min-width: 63px;
  min-height: 38px;
  font-weight: 600;
  font-size: 11px;
  line-height: 14px;
  text-align: center;
  text-transform: capitalize;
  color: var(--matterColorLight);
  background: var(--marketplaceColor);
  @media (--viewportMobile) {
    display: none;
  }
}

.mobLabel {
  display: none;
}
.deviceLabel{
  display: block;
}
@media screen and (max-width: 767px) {
 
  .mobLabel {
    display: block;
  }
  .deviceLabel{
    display: none;
  }
  .filterKeywordWrap input {
    width: 180px;
    @media (--viewportMobile) {
      width: 100%;
    }
  }
  .filterKeywordWrap {
    flex-direction: column;
    align-items: flex-start;
    padding: 0 16px ;
  }
}

.buttonWrap{
  display: flex;
  align-items: center;
}

.inputWrap{
 width: 100%;
}

.horizontalScroll{
  width: 100%;
}

.scrollArrow {
  & > div {
    background-image: url('../../assets/arrow_btn_calendar.png') !important;
    /* width: 28px !important;
    height: 28px !important; */
    left: 0;
    right: 0;
    margin: 0 auto;
    @media (--viewportTabletDown){
      display: none;
      width: 0;
    }
  }
}

.sportsBtnWrap{
  display: flex;
  overflow-x: scroll;
  @media (--viewportMobile) {
    margin-bottom: 30px;
  }
  &::-webkit-scrollbar {
    display: none;
  }
}

.sportsBtn{
  margin: 0 3.5px;
  padding: 8px 22px;
  height: 39px;
  width: 109px;
  color: #617189;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  text-transform: capitalize;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--matterColorLight);
  border: 1.5px solid #F1F1F1;
  box-sizing: border-box;
  border-radius: 136.902px;
  & span{
    white-space: nowrap;
  }
}

.selectedSportsBtn{
  background: var(--marketplaceColor);
  color: var(--matterColorLight);
  border: 0;
}

.scrollArrowContainer{
  @media (--viewportTabletDown) {
    display: none;
  }
}
