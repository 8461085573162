.wrapper {
  /* min-height: 50px; */
  min-height: 0px;
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  /* padding: 20px 0; */
}

.chip {
  background-color: rgb(240, 239, 239, 0.5);
  border: 1px solid rgb(240, 239, 239, 0.7);
  color: rgb(85, 85, 85);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 111px;
  padding: 2px 15px;
  font-size: 14px;
  margin-right: 15px;
  margin-bottom: 15px;
}

.clearIcon {
  color: rgb(85, 85, 85) !important;
  transform: scale(0.7) !important;
  cursor: pointer;
  & svg {
    color: rgb(85, 85, 85) !important;
  }

  &:hover {
    color: red !important;
  }
}

.resetAllButton {
  background-color: #00ab99;
  border: 1px solid rgb(240, 239, 239, 0.7);
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 111px;
  padding: 2px 15px;
  font-size: 14px;
  margin-right: 15px;
  margin-bottom: 15px;
  cursor: pointer;

  &:hover {
    background-color: #2cefdc;
  }
}
