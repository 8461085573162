@import '../../marketplace.css';

.bookingInfoWrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  font-size: 14px;
  line-height: 14px;
  margin-top: 0;
  padding-top: 2px;

  @media (--viewportMedium) {
    padding-top: 0px;
    line-height: 16px;
  }
}
