@import '../../marketplace.css';

.root {
  display: inline-block;
  flex-shrink: 0;
  border-right: 2px solid var(--matterColorNegative);
  &:last-of-type {
    padding-right: 0;
  }
}

.menuLabel {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceSearchFilterLabelFontStyles;
  padding: 6px 14px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 25px;
  border: none;
  background: none;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  color: var(--marketplaceColorDarker);
  min-width: 120px;

  &:focus {
    outline: none;
    background-color: transparent;
    border-color: transparent;
    text-decoration: none;
    box-shadow: unset;
  }
  @media (--viewportMobile) {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    padding: 0 14px;
  }
}

.menuLabelSelected {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceSearchFilterLabelFontStyles;
  padding: 6px 14px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 25px;
  color: var(--marketplaceColorLight);
  background: none;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  border: 1px solid transparent;
  text-transform: capitalize;

  & svg{
    & path{
      fill: #00bfe6;
    }
  }
  &:hover,
  &:focus {
    border: 1px solid var(--marketplaceColorDark);
  }
  @media (--viewportMobile) {
    padding: 0 14px;
  }
}

.menuContent {
  margin-top: 7px;
  padding-top: 13px;
  min-width: 300px;
  border-radius: 4px;
  @media (--viewportMobile) {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
  }
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  background-color: var(--marketplaceColor);
  transition: width var(--transitionStyleButton);
}

/* left static border for selected element */
.menuItemBorderSelected {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 7px);
  width: 6px;
  background-color: var(--matterColorDark);
}

.selectedItem {
  color: var(--marketplaceColorLight) !important;
}

.menuItem {
  @apply --marketplaceListingAttributeFontStyles;
  color: var(--matterColor);

  /* Layout */
  position: relative;
  min-width: 300px;
  margin: 0;
  padding: 4px 30px;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  white-space: nowrap;

  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--matterColorDark);
  }

  &:hover .menuItemBorder {
    width: 6px;
  }
}

.clearMenuItem {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);

  /* Layout */
  position: relative;
  min-width: 300px;
  margin: 0;
  padding: 32px 30px 18px 30px;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;

  cursor: pointer;
  transition: width var(--transitionStyleButton);

  &:focus,
  &:hover {
    color: var(--matterColor);
    transition: width var(--transitionStyleButton);
  }
}
