@import '../../marketplace.css';

.overviewSection {
  display: flex;
  justify-content: center;
  padding: 65px 0;

  @media (--viewportMobile) {
    padding: 50px 0;
  }
}

.redFontClass {
  color: var(--marketplaceRedDarkColor);
}

.containerWrapper {
  max-width: 1501px;
  margin: 0 120px;

  @media (--viewportLaptopDown) {
    margin: 0 50px;
  }

  @media (--viewportMobile) {
    margin: 0 30px;
  }
}

.processDiagramWrapper {
  width: 100%;
}

.sectionDiagramWrapper {
  text-align: center;

  &>img {
    width: 50%;

    @media (--viewportMobile) {
      width: 100%;
    }
  }
}

.bookmarkLink {
  padding-left: 10px;
}

.sectionContainer {
  margin: 30px 0;

  & p {
    color: #6e6f71;
  }
}

.expandableBar {
  border: 2px solid black;
  /* background-color: var(--marketplaceColor); */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 40px;
  margin: 40px 0 10px 0;
  /* color: white; */

  @media (--viewportMobile) {
    padding: 10px 20px;
  }
}

.accordionArrow {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  justify-items: center;
  margin-left: 0.5em;
  padding: 0 0.5em;
  transform: rotateX(0);
  transition: transform .3s ease;

  &:before {
    border-color: #1a2b49;
    border-style: solid;
    border-width: 0 0 0.15em 0.15em;
    content: "";
    height: 0.6em;
    transform: rotate(-135deg);
    transition: transform .2s ease;
    width: 0.6em;
  }
}

.accordionArrowOpen {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  justify-items: center;
  margin-left: 0.5em;
  padding: 0 0.5em;
  transform: rotateX(90);
  transition: transform .3s ease;

  &:before {
    border-color: #1a2b49;
    border-style: solid;
    border-width: 0 0 0.15em 0.15em;
    content: "";
    height: 0.6em;
    transform: rotate(-45deg);
    transition: transform .2s ease;
    width: 0.6em;
  }
}