.tripExpertFormCard {
    background-color: white;
    min-height: 50rem;
    border: none;
    border-radius: 20px;
    display: flex;
    flex-flow: column;
    
    padding: 2rem 3rem;

    & hr {
        margin: 30px 0 30px;
    }

    & h2 {
        align-self: center;
        font-size: 36px;
        text-align: center;
    }

    & h3 {
        margin: 1rem 2rem;
        font-size: 25px;

    }
}

.checkbox {
    margin: 1rem 0;
}

.crossIcon {
    width: 15px;
    height: 15px;
}

.formButton {
    border-radius: 30px;
    min-height: 60px;
}

.checkboxLabel {
    font-size: 18px;
    line-height: 28px;
}