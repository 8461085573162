@import '../../marketplace.css';

.listingPicker {
}

.select {
  z-index: 3;
}

.selectFieldRoot {
  background-color: white;
  padding: 5px 10px;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
}

.selectFieldInput {
  padding-bottom: 5px;
  border: none;
  font-size: 18px !important;
}