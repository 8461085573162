@import '../../marketplace.css';

.root {
  display: block;
  position: relative;
  width: 100%;
  margin: 0;
  overflow: hidden;
  background-color: var(--matterColorNegative); /* Loading BG color */
}

.threeToTwoWrapper {
  position: relative;
}

.aspectWrapper {
  padding-bottom: calc(100% * (2 / 3));
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  /* height: 100%; */
  object-fit: cover;
  border-radius: var(--borderRadius);
}
