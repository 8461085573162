@import "../../../../marketplace.css";

.tableContainer {
  width: 100%;
  background-color: white;
  padding: 30px 0;
  overflow-x: auto;
}

.table {
  width: 100%;
  font-size: 16px;
  border-collapse: unset;
  background: #ffffff;
  border: 2px solid #e5e5e5;
  border-radius: 12px;
}

.table th,
.table td {
  padding: 8px;
  text-align: left;
  vertical-align: middle;
}

.table th {
  background-color: #f2f2f2;
}

.table tr:nth-child(even) {
  background-color: #f2f2f2;
}

@media screen and (max-width: 600px) {
  .table th,
  .table td {
    padding: 4px;
  }

  .table th {
    font-size: 14px;
  }

  .table td {
    font-size: 16px;
  }
}

.nav {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.nav button {
  margin-right: 1rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: bold;
  background-color: transparent;
  border: none;
  color: #333;
  cursor: pointer;
  /* new styles */
  margin-right: 10px;
  padding: 5px 10px;
  /* color: var(--marketplaceColorDark); */
  /* border: 1px solid var(--marketplaceColorDark); */
  /* border-radius: 4px; */
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.2s linear, color 0.2s linear;
  height: 50px;
  border-radius: 25px;
  background-color: #f7f7f7;
  font-size: 15px;
  color: #949494;
  font-weight: 400;

  font-weight: normal;
  font-size: 15px;
  line-height: 19px;
  cursor: pointer;
  height: 40px;
  padding: 0 22px;
  justify-content: center;
  align-items: center;
  transition: background-color 0.2s linear, color 0.2s linear;
  border-radius: 25px;
  &:hover {
    box-shadow: var(--boxShadowButton);
    text-decoration: none;
  }
  @media (--viewportMobile) {
    margin-bottom: 10px;
  }

  /*  */
}

.nav button.active {
  color: white;
  background-color: #333;
  font-weight: normal;
  font-size: 15px;
  line-height: 19px;
  cursor: pointer;
  height: 40px;
  padding: 0 22px;
  justify-content: center;
  align-items: center;
  transition: background-color 0.2s linear, color 0.2s linear;
  border-radius: 25px;
}

tr {
  display: flex;
  justify-content: space-between;
  background-color: transparent !important;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #e7e7e7;
  padding: 12px !important;
}

.title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.expersSelectField {
  min-width: 350px;
  background-color: white;
  border-radius: 5px;
  padding: 3px 10px;
}

.stateBtnsWrap {
  display: flex;
  /* flex-wrap: wrap; */
  margin: 4px;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  & div {
    font-weight: normal;
    font-size: 15px;
    line-height: 19px;
    cursor: pointer;
    height: 40px;
    padding: 0 22px;
    justify-content: center;
    align-items: center;
    transition: background-color 0.2s linear, color 0.2s linear;
    margin: 4px;
    display: flex;

    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
}

.formControlClass * {
  font-size: 16px !important;
  font-family: 'Source Sans Pro', sans-serif !important;
}

.weekday {
  margin-bottom: 24px;
}

.weekdayRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px 18px;
  margin: 0 16px;

  @media (--viewportMobile) {
    padding: 0 0 5px;
    margin: 0;
  }
}

.weekdayName {
  display: flex;
  align-items: center;
  column-gap: 20px;
  font-weight: bold;
  font-size: 17px;
  line-height: 22px;
  color: var(--matterColorAnti);
  text-transform: capitalize;
}

.bookingNumber {
  font-size: 18px;
  line-height: 23px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  @media (--viewportMobile) {
    font-size: 14px;
    line-height: 18px;
  }
}

.itemList {
  margin: 0;
  /* padding: 20px 0 0; */
  background: var(--matterColorLight);
  padding: 40px 10px;

  @media (max-width: 768px) {
    padding: 10px 16px;
  }
}
