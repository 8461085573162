@import '../../marketplace.css';

.root {
  display: none;
  max-width: 1501px;
  margin: 0 120px;

  @media (--viewportLaptopDown) {
    margin: 0 50px;
  }

  @media (--viewportMedium) {
    display: flex;
    justify-content: flex-end;
    height: 57px;
    align-items: flex-end;
    padding: 0 20px;
  }

  @media (--viewportMobile) {
    margin: 0 30px;
  }
}

.tab {
  display: flex;
  align-items: flex-end;
  height: 100%;

  &:first-child {
    margin-left: 0;
  }
}
