@import '../../marketplace.css';

.root {
  position: relative;
}

.scrollContainer {
  display: flex;
  overflow: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
  justify-content: flex-start;
  -webkit-overflow-scrolling: touch;
  gap: 15px;

  &::-webkit-scrollbar {
    display: none;
    width: 0px;
    background: transparent;
  }

  @media (--viewportMobile) {
    overflow-x: scroll;
  }
}

.scrollArrow {
  @media (--viewportMobile) {
    display: none;
  }
}

.sectionCounter {
  display: none;
  position: absolute;
  top: -38px;
  right: 90px;
  font-weight: normal;
  font-size: 15px;
  line-height: 16px;
  color: #6E6F71;

  @media (--viewportMobile) {
    display: none;
  }
}
