@import '../../marketplace.css';

:root {
  --SectionHero_desktopTitleMaxWidth: 625px;

  --SectionHero_animation: {
    animation-name: animationTitle;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
    -webkit-animation-fill-mode: forwards;

    visibility: hidden;
    opacity: 1;
    position: relative;
  }
}

.title {
  @apply --marketplaceH1FontStyles;
  font-size: 36px;
  font-weight: 600;
  margin-top: 20px;

  @media (--viewportMobile) {
    font-size: 22px;
  }

  @media (--viewportMedium) {
    margin-bottom: 23px;
  }
}

.listContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
}

.listWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 15px;
  width: 245px;
}

.listWrapper:nth-of-type(1) {
  width: 602px;
}

.listWrapper:nth-of-type(6) {
  width: 602px;
}

.listWrapper img {
  overflow: hidden;
  height: 278px;
  max-width: 100%;
  object-fit: cover;
  border-radius: 15px 15px 0 0;
}

.listContentWrap {
  border: 1px solid #ccd7ea;
  border-radius: 0 0 15px 15px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  padding: 20px;
}

.listContentWrap span:first-child {
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #0d1624;
  margin-bottom: 10px;
}

.listContentWrap span {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #475e83;
}
