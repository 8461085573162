@import '../../marketplace.css';

.stateBtn {
  margin-right: 10px;
  padding: 5px 10px;
  /* color: var(--marketplaceColorDark); */
  /* border: 1px solid var(--marketplaceColorDark); */
  /* border-radius: 4px; */
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.2s linear, color 0.2s linear;
  height: 50px;
  border-radius: 25px;
  background-color: #f7f7f7;
  font-size: 15px;
  color: #949494;
  font-weight: 400;
  &:hover {
    box-shadow: var(--boxShadowButton);
    text-decoration: none;
  }
  @media (--viewportMobile) {
    margin-bottom: 10px;
  }
}
.stateBtnActive {
  background-color: var(--marketplaceColorBackground) !important;
  color: var(--matterColorLightGrey) !important;
  filter: drop-shadow(0px 10px 8.5px rgba(33, 227, 248, 0.11));
}
