@import '../../marketplace.css';

.root {
  display: flex;
  align-items: center;
}

.popularHeading {
  color: var(--matterColorLight);
}

.popularList {
  margin: 0 0 0 10px;
  display: flex;
}

.popularItem {
  margin-right: 10px;
  padding: 3px 6px;
  font-size: 16px;
  border: 2px solid var(--matterColorLight);
  border-radius: 3px;
  cursor: pointer;
}

.popularLink {
  color: var(--matterColorLight);
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}
