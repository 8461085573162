@import '../../marketplace.css';

.root {
  /* Font */
  @apply --marketplaceH6FontStyles;
  color: var(--matterColorLight) !important;
  background-color: var(--failColor);
  margin-top: 0;
  margin-bottom: 0;
  border-radius: 50%;
  padding: 2px 7px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;

    top: -10px;
    position: relative;
  }
}
