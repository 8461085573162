@import '../../marketplace.css';

.tabLabels {
  /* display: flex; */
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  overflow-x: auto;
&::-webkit-scrollbar {
   display: none;
   width: 0px;
   background: transparent;
 }
}

.tabLabelItem {
  padding: 3px 0;
  margin-right: 18px;
  font-weight: var(--fontWeightSemiBold);
  white-space: nowrap;
  cursor: pointer;
& :last-child {
    margin-right: 0;
  }
}
.tabLabelItemActive {
  border-bottom: 3px solid var(--marketplaceColorDark);
  color: var(--marketplaceColor);
}
.priceInfo {
  text-transform: initial;
  font-weight: normal;
  height: 18px;
}