@import '../../marketplace.css';

.error {
  color: var(--failColor);
  margin: 23px 24px 24px 24px;

  @media (--viewportLarge) {
    margin-top: 18px;
  }
}

.itemList {
  margin: 0;
  padding: 0;
}

@keyframes loadingSpinnerFadeIn {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.listItemsLoading {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 100%;
  opacity: 0;

  /* animation shorthand property doesn't work with local scope of CSS Modules */
  animation-duration: 1s;
  animation-name: loadingSpinnerFadeIn;
  animation-fill-mode: forwards;
}

.listItem {
  /* Layout */
  margin-bottom: 10px;
  padding-bottom: 12px;

  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: var(--matterColorNegative);

  &:last-child {
    border-bottom: none;
  }

  @media (--viewportLarge) {
    margin-bottom: 24px;
    padding-bottom: 15px;
  }
}

.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.itemLink {
  flex: 1;

  /* Layout */
  display: flex;

  /* Remove text underline from this link */
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  &:hover .itemUsername {
    /* Hightlight the username when the whole item is hovered */

    & .listingTitle {
      border-color: var(--marketplaceColor);
    }
  }
}

.itemAvatar {
  /* margin-right: 8px; */
  align-self: center;

  @media (--viewportLarge) {
    margin-top: 2px;
    margin-right: 15px;
  }
}

.avatarUser {
  height: 55px;
  width: 55px;
  background: #C4C4C4;

  & span {
    font-size: 24px;
  }

  @media (max-width: 1024px) {
    height: 48px;
    width: 48px;
  }
}

.rowNotificationDot {
  width: 6px;
  height: 6px;
  margin-top: 10px;
  margin-right: 8px;

  @media (--viewportLarge) {
    margin-right: 13px;
    width: 9px;
    height: 9px;
  }
}

.notificationDot {
  color: var(--matterColorLight);

  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: var(--failColor);
}

.itemInfo {
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightSemiBold);

  /* Layout */
  flex-grow: 1;
  flex: 1;
  flex-direction: column;

  margin-top: 5px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 3px;
    margin-bottom: 0;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: var(--matterColor);
  gap: 5px;

  @media (--viewportMobile) {
    align-items: center;
  }
}

.itemUsername {
  display: flex;
  flex-direction: column;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: var(--marketplaceColorDarkest);

  @media (--viewportMedium) {
    flex-direction: column;
    margin-bottom: 0;
  }
}

.bookingInfoWrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  font-size: 14px;
  line-height: 14px;
  margin-top: 2px;
  padding-top: 2px;

  @media (--viewportMedium) {
    padding-top: 0px;
    margin-top: 8px;
    line-height: 16px;
  }
}

.itemPrice {
  &::before {
    font-size: 10px;
    margin: 0 6px;
    display: inline-block;
    content: '\25CF';
    /* middot */
  }
}

.itemState {
  /* Font */
  @apply --marketplaceH5FontStyles;

  font-weight: var(--fontWeightMedium);
  margin-top: 6px;
  margin-bottom: 0px;
  text-align: right;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.lastTransitionedAt {
  text-align: right;
  color: var(--matterColor);
}

.pagination {
  margin: auto 24px 0 24px;
}

/* Transaction status affects to certain font colors and weights */

.stateName {
  /* This class is empty on purpose, it is used below for banned users */
  font-weight: normal;
  font-size: 16px;
  line-height: 17px;
  display: flex;
  flex-direction: column !important;
  align-items: end !important;
  gap: 5px 25px;

  @media (--viewportMobile) {
    /* display: block; */
    font-size: 12px;
    line-height: 15px;
    text-align: end;
  }
}

.stateDelivered {
  /* color: #31D296; */
  color: var(--marketplaceColorDark);
}

.stateAccepted {
  color: var(--marketplaceColorLight) !important;
}

.stateCancelled {
  color: var(--failColor);
}

.stateDeclined {
  color: var(--failColor);
}

.stateSuccess {
  color: var(--marketplaceColorDark);
  /* color: #31D296; */
  display: flex;
  align-items: center;

  @media (--viewportMobile) {
    gap: 0 5px;
  }

  & svg {
    fill: transparent;
  }
}

.nameEmphasized {
  font-weight: var(--fontWeightBold);

  /* baseline alignment fixes */
  /* margin-top: 8px; */
  font-weight: normal;
  font-size: 11.2701px;
  line-height: 17px;
  margin-bottom: 1px;

  @media (--viewportMobile) {
    font-size: 10px;
    line-height: 15px;
  }
}

.nameNotEmphasized {
  font-weight: 600;
}

.bookingActionNeeded {
  color: var(--matterColor);
  margin-top: 0;
  flex: 1;

  @media (--viewportMedium) {}
}

.bookingNoActionNeeded {
  flex: 1;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #6E6F71;
}

.lastTransitionedAtEmphasized {
  color: var(--matterColor);
  font-weight: var(--fontWeightMedium);
}

.lastTransitionedAtNotEmphasized {
  color: var(--matterColorAnti);
}

.bannedUserLink {

  & .itemUsername,
  &:hover .itemUsername,
  & .bookingInfo,
  & .stateName,
  & .lastTransitionedAt {
    color: var(--matterColorAnti);
  }
}

.navPlaceholder {
  @media (--viewportMedium) {
    width: 240px;
  }
}

.listingTitle {
  max-width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (--viewportMobile) {
    margin-left: 0;
    padding-left: 0;
    width: 180px;
    border-left: none;
  }
}

.stateDetail {
  display: flex;
  flex-wrap: wrap;
}

.transactionId {
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
  color: #6E6F71;
}

.clientBookListingWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media (--viewportMobile) {
    display: block;
  }

  & .userDisplayNameWrapper {
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    color: var(--marketplaceColor);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: break-spaces;
    word-wrap: break-word;

    @media (--viewportMobile) {
      line-height: 19px;
    }
  }

  & .userDisplayNameAdminWrapper {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: break-spaces;
    word-wrap: break-word;

    @media (--viewportMobile) {
      line-height: 19px;
    }
  }

  & .listingNameWrapper {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: var(--marketplaceColor);
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    white-space: break-spaces;
    word-wrap: break-word;

    @media (--viewportMobile) {
      line-height: 19px;
      flex-direction: column;
      justify-content: flex-start;
    }
  }
}

.actionRequiredMark {
  background-color: var(--marketplaceRedDarkColor);
  color: white;
  font-size: 12px;
  line-height: 10px;
  text-align: center;
  padding: 5px 10px;
  border-radius: 22px;

  @media (--viewportMobile) {
    font-size: 9px;
  }
}

.completeLabelWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.chip {
  background-color: rgb(240, 239, 239, 0.5);
  border: 1px solid rgb(240, 239, 239, 0.7);
  color: rgb(85, 85, 85);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 111px;
  padding: 2px 10px;
  font-size: 14px;
  margin: 2px 5px;

}

.mobileMaybe {
  @media (--viewportMobile) {
    display: none;
  }
}

.itemProviderName {
  font-size: 16px;
  font-weight: normal;
}

.priceContainer {
  font-size: 20px;
}