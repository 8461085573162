@import '../../marketplace.css';

.topbar {
  z-index: 1;
  /* ensure that Topbar shadow overlays content background */
}

.mobileTopbar {
  /* Size */
  width: 100%;
  /* height: var(--topbarHeight); */
  height: auto;
  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  /* fill */
  /* background-color: var(--matterColorLight); */
  background-color: var(--marketplaceColorBackground);

  /* shadows */
  box-shadow: none;
  padding: 7px 22px 5px 27px;

  @media (--viewportLarge) {
    display: none;
  }

  @media (--viewportSmall) {
    padding: 0 24px;
  }
}

.desktopTopbar,
.mobileTopbar {
  box-shadow: 0px 4px 20px 10px rgba(0, 0, 0, 0.02);

  @media (--viewportLarge) {
    box-shadow: var(--boxShadowLight);
  }
}

.navigation {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 29px 24px 0 24px;

  border-top-width: 1px;
  border-top-color: var(--matterColorNegative);
  border-top-style: solid;
  background-color: var(--marketplaceColorBackground);
  box-shadow: var(--boxShadow);

  @media (--viewportMedium) {
    padding: 24px 24px 0 24px;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 24px 0;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 59px;
    left: 0;
    overflow-x: hidden;
    padding-top: 30px;
    transition: 0.5s;
  }

  @media (--viewportLarge) {
    padding: 19px 0 80px 0;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border: none;
    max-width: 279px;
    width: 279px;
    height: 1000px;
    box-shadow: none;
    position: sticky;
    top: 0;
    left: 0;
    /* height: 100%; */
    /* background-color: transparent; */
  }
}

.title {
  /* Font */
  /* @apply --marketplaceH1FontStyles;
  color: var(--matterColor); */
  font-weight: bold;
  font-size: 25px;
  line-height: 31px;
  color: var(--matterColorLight);
  /* Layout */

  @media (--viewportMedium) {
    padding-bottom: 10px;
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (--viewportLarge) {
    padding-bottom: 0;
    font-size: 40px;
    line-height: 50px;
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 10px;
  }
}

.tabs {
  padding-top: 2px;
  overflow-x: auto;
  width: 100%;

  @media (--viewportMedium) {
    padding-top: 12px;
    padding-top: 20px;
  }

  @media (--viewportLarge) {
    padding-top: 53px;
    flex-direction: column;
  }
}

.tab {

  /* margin-left: 16px; */
  /* max-width: 150px; */
  &:first-child {
    margin-left: 0;
  }

  @media (--viewportLarge) {
    margin-left: 0;
  }
}

.error {
  color: var(--failColor);
  margin: 23px 24px 24px 24px;

  @media (--viewportLarge) {
    margin-top: 18px;
  }
}

.noResults {
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 1px;

  @media (--viewportLarge) {
    margin-top: 6px;
  }
}

.itemList {
  margin: 0;
  /* padding: 20px 0 0; */
  background: var(--matterColorLight);
  padding: 20px 10px;

  @media (max-width: 768px) {
    padding: 10px 16px;
  }
}

.enquiryItemList {
  @media (--viewportMobile) {
    padding: 0;
  }
}

@keyframes loadingSpinnerFadeIn {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.listItemsLoading {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 100%;
  opacity: 0;

  /* animation shorthand property doesn't work with local scope of CSS Modules */
  animation-duration: 1s;
  animation-name: loadingSpinnerFadeIn;
  animation-fill-mode: forwards;
}

.listItem {
  /* Layout */
  display: flex;
  margin-bottom: 10px;
  padding-bottom: 12px;
  align-items: center;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: var(--matterColorNegative);
  background: var(--matterColorLight);
  position: relative;

  @media (--viewportLaptopDown) {
    flex-direction: column;
  }

  &:first-child {
    margin-top: 20px;
  }

  &:last-child {
    border-bottom: none;
  }

  @media (--viewportLarge) {
    margin-bottom: 24px;
    padding-bottom: 15px;
  }
}

.enquirylistItem {
  border-color: transparent;
  margin-bottom: 0;
}

.paymentProofsContainer {
  margin-top: 30px;
}

.paymentProofSsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (--viewportMobile) {
    margin: 29px 24px 0 24px;
  }
}

.paymentProofSs img {
  width: 300px;
  padding: 15px;
}

.acceptPackageRequest {
  @apply --marketplaceButtonStylesPrimary;
  width: auto;
  padding: 15px 25px;
  margin-left: 20px;
}

.rejectPackageRequest {
  @apply --marketplaceButtonStylesSecondary;
  width: auto;
  padding: 15px 25px;
  margin-left: 20px;
}

.trigger button {
  width: auto;
  padding: 15px 25px;
  margin-left: 10px;
}

.trigger {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid var(--matterColorNegative);
  padding: 10px;
  cursor: pointer;
  font-size: 16px;
  color: var(--matterColorDarkBlue);

  @media (--viewportMobile) {
    flex-direction: column;
  }
}

.packageRequestActions,
.paymentInfoContainer {
  display: flex;
  border-left: 1px solid #e0e0e0;
}

.collapsible {
  width: 100%;
}

.downloadBtn {
  max-width: 280px;
  min-height: 40px;
  height: 40px;
  font-size: 14px;
  line-height: 20px;
  border-color: var(--marketplaceColorDark);
  color: var(--marketplaceColorDark);
  margin: 0 10px;

  @media (--viewportMobile) {
    font-size: 16px;
  }
}

.downloadBtnIcon {
  margin-right: 8px;
}

.userAndListingName {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
}

.sessions,
.usedSessions {
  margin-left: 10px;
  font-size: 14px;
}

.usedSessions {
  padding-left: 10px;
  border-left: 1px solid var(--matterColorDarkBlue);
}

.status {
  margin-right: 10px;
}

.triggerSectionRight {
  margin-left: auto;
  display: flex;
  align-items: center;

  @media (--viewportMobile) {
    margin: 0;
  }
}

.triggerSectionLeft {
  display: flex;
  align-items: center;
  justify-content: center;
}

.userName:hover {
  color: var(--marketplaceColor);
}

.listingTitle:hover {
  color: var(--marketplaceColor);
}

.status {
  font-weight: var(--fontWeightSemiBold);
}

.spinner {
  width: 24px;
  height: 24px;
  stroke: var(--successColorDark);
  stroke-width: 3px;
}

.salesHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  @media (--viewportMobile) {
    margin-bottom: 0;
  }
}

/* State btn */
.stateBtnsWrap {
  display: flex;
  /* flex-wrap: wrap; */
  margin: 4px;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  & div {
    font-weight: normal;
    font-size: 15px;
    line-height: 19px;
    cursor: pointer;
    height: 40px;
    padding: 0 22px;
    justify-content: center;
    align-items: center;
    transition: background-color 0.2s linear, color 0.2s linear;
    margin: 4px;
    display: flex;

    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
}

.iconArrowDown {
  display: flex;
  margin-left: 15px;
  justify-content: center;
  width: 26px;
  height: 26px;
  background-color: var(--marketplaceColorDark);
  border-radius: 50%;

  & svg {
    margin-left: 0;
    fill: var(--matterColorBright);
  }
}

.iconArrowDropDown {
  display: flex;
  margin-left: 15px;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  border-radius: 50%;

  & svg {
    width: 16px;
    height: 8px;
    margin-left: 0;
    fill: var(--matterColorBright);
  }
}

.payment_type,
.payment_status {
  padding: 15px 10px;
  font-weight: 600;
}

.createdAt {
  color: var(--matterColorAnti);
  margin-top: 10px;
  font-size: 14px;
}

/* .checkbox {
  display: flex;
  align-items: center;
  & > span {
    font-size: 16px;
  }
}*/
.checkboxInput {
  width: 25px;
}

.checkboxField {
  position: relative;
  display: flex;
  align-items: center;

  @media (--viewportMobile) {
    flex-direction: row-reverse;
  }

  &>span {
    font-size: 16px;
    padding: 0 10px;
  }
}

.checkboxField label {
  background-color: var(--matterColorLight);
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 22px;
  position: absolute;
  width: 22px;
}

.checkboxField label:after {
  border: 3px solid var(--matterColorLight);
  border-top: none;
  border-right: none;
  content: '';
  height: 6px;
  left: 50%;
  transform: translate(-50%, -75%) rotate(-45deg);
  opacity: 0;
  position: absolute;
  top: 50%;
  width: 12px;

  @media (--viewportMobile) {
    border-width: 2px;
  }
}

.checkboxField input[type='checkbox'] {
  visibility: hidden;
}

.checkboxField input[type='checkbox']:checked+label {
  background-color: var(--matterColorLightBlue);
  border-color: var(--matterColorLightBlue);
}

.checkboxField input[type='checkbox']:checked+label:after {
  opacity: 1;
}

.selectAllWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 12px 18px 12px;
  background: var(--matterColorLight);
  border-bottom: 2px solid lightgrey;
  margin-bottom: 20px;

  @media (--viewportMobile) {
    padding: 0 28px 18px 12px;
    flex-direction: row-reverse;
  }
}

.rightSideControls {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (--viewportMedium) {
    width: auto;
    column-gap: 20px;
  }
}

.mobileSortPopupWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;

  @media (--viewportMedium) {
    display: none;
  }
}

.sortStyle {
  display: inline-block;
  margin-right: 12px;
  margin-left: 12px;

  @media (--viewportMedium) {
    margin-right: 8px;
    margin-left: auto;
  }

  &:last-of-type {
    padding-right: 0;
  }
}

.desktopSortPopupWrapper {
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}

/* Schedule tab */
.scheduleContent {}

.scheduleLoading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.notes {
  display: none;
  overflow: hidden;
  width: 300px;
  text-align: left;
  line-height: 18px;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: break-spaces;
  word-wrap: break-word;
  margin-left: 20px;
  padding-left: 20px;
  border-left: 1px solid var(--matterColorDarkBlue);

  @media (--viewportMedium) {
    display: -webkit-box;
  }
}

.attachment img {
  width: 100%;
  padding: 15px;

  @media (--viewportMobile) {
    width: 250px;
  }
}

.attachment {
  display: flex;
  justify-content: center;
}

.bookingPlanBtn {
  @apply --marketplaceButtonStylesSecondary;
  display: inline-block;
  min-height: 30px;
  width: 150px;
  padding: 10px;
  margin-bottom: 30px;
}

.searchPackage,
.transactions {
  margin-bottom: 20px;
  margin-top: 20px;
  margin-right: 20px;
}

.prAmount {
  display: block;
}

.staticPageWrapper {
  width: calc(100% - 48px);
  max-width: 1056px;
  margin-top: -24px;

  @media (--viewportMedium) {
    width: calc(100% - 72px);
  }
}

.walletListingPanel {
  width: 100%;

  @media (--viewportLarge) {
    max-width: 62.5vw;
  }
}

.walletListingCards {
  @media (--viewportMedium) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.LayoutSideContainer {
  display: flex;
  justify-content: center;
  background-color: #eee;
}

/* .LayoutSideContainer{
  @media (--viewportLarge) {
    max-width: 1524px;
    width: 100%;
    padding: 0;
    margin: 0 auto;
  }
  @media (max-width: 1440px){
    max-width:100%;
    padding: 0 80px;
  }
  @media (max-width: 768px){
    padding: 0;
  }
  @media (--viewportLarge) {
    display: flex;
  }
} */

.LayoutSideContainer>div:first-child {
  background: #eee;
  max-width: 1501px;
  margin: 30px 120px;

  @media (--viewportTabletDown) {
    margin: 30px 50px;
  }

  @media (--viewportMobile) {
    margin: 0 30px;
    background: var(--matterColorLight);
  }
}

.dropdownBtn {
  @apply --marketplaceButtonStylesSecondary;
  padding: 5px 0;
  min-width: 142px;
  min-height: 30px;
  color: black;
  border: 1px solid lightgrey;
}

.dropdownSelection {
  width: 260px;
  height: 45px;
  border: 1px solid gainsboro;
  padding: 0 10px;
  background: var(--matterColorLight);
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 2px;
  margin-bottom: 20px;
}

.Selection {
  border-bottom-width: 0px;
}

.inboxMainHeading {
  font-weight: bold;
  font-size: 25px;
  line-height: 31px;
  text-transform: uppercase;
  color: var(--matterColorDarkBlue);
  margin: 0;

  @media (--viewportMobile) {
    line-height: 28px;
  }
}

.rightPanel {
  border: none;
  width: 100%;
  padding: 0;
}

.enquiryRightPanel {
  border: none;
  width: 100%;
  padding: 0;
}

.MobileNavButton {
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
}

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 13px;

  & svg {
    margin-right: 15px;
  }

  & select {
    display: none;

    @media (max-width: 768px) {
      display: block;
    }
  }

  @media (max-width: 768px) {
    margin: 16px 16px 24px;
  }
}

.inboxTitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 13px;

  & svg {
    margin-right: 15px;
  }

  & select {
    display: none;

    @media (max-width: 768px) {
      display: block;
    }
  }

  @media (--viewportMobile) {
    width: 100%;
    justify-content: flex-start;
    padding-left: 30px;
  }
}

.titleWrap {
  display: flex;
}

.stateDropDownWtap {
  background: var(--matterColorLight);
  width: 36%;
  padding: 8px;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 7px;
  text-transform: capitalize;
  background-image: url('/static/images/Icons/downArrow.png');
  background-size: 8px 5px;
  background-position: center right;
  background-repeat: no-repeat;
  background-position-x: 96%;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: var(--marketplaceColorDarkest);
}

.scheduleTitleContainer {
  justify-content: flex-start;
  column-gap: 29px;
  margin: 0 0 10px;

  @media (max-width: 769px) {
    margin: 16px 16px 24px;
    justify-content: space-between;
  }
}

.mobileStateSlider {
  display: flex;
  align-items: center;
  overflow: scroll;
  width: 100%;
  height: 84.22px;
  background: var(--marketplaceColorBackground);
  margin: 0 0 23px;
  padding: 0 22px;

  @media (--viewportMedium) {
    display: none;
  }
}

.mobileStateSliderLink {
  display: inline-block;
  white-space: nowrap;

  /* Font */
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  color: var(--matterColorLight);

  text-transform: capitalize;
  color: var(--marketplaceColorLightWhite);
  padding: 10px 4px;
  margin-right: 14px;
  /* push tabs against bottom of tab bar */
  margin-top: 0;

  border-bottom-width: 4px;
  border-bottom-style: solid;
  border-bottom-color: transparent;
  transition: var(--transitionStyleButton);
}

.mobileStateSliderSelectedLink {
  border-bottom: 4px solid var(--marketplaceColor);
  color: var(--marketplaceColorLightWhite);
  text-decoration: none !important;
}

.modalContent {
  @media (--viewportMobile) {
    height: 100%;
  }

  & form {
    box-shadow: none;
  }
}

.contentWrap {
  @media (--viewportMobile) {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
  }
}

.pagination {
  padding: 20px 0;
}

.topNavnar {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

.navLink {
  color: var(--matterColor);
  padding-bottom: 5px;
  margin: 0 5vw;

  &:hover {
    border-bottom: 5px solid var(--marketplaceColor);
    text-decoration: none;
  }
}

.navLinkSelected {
  color: var(--matterColor);
  font-weight: 600;
  padding-bottom: 5px;
  border-bottom: 5px solid var(--marketplaceColor);
  margin: 0 5vw;

  &:hover {
    text-decoration: none;
  }
}

.weekday {
  margin-bottom: 24px;
}

.weekdayRow {
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 0 12px 18px 0;
  margin: 0 16px;

  @media (--viewportMobile) {
    padding: 0 0 5px;
    margin: 0;
  }
}

.weekdayName {
  display: flex;
  align-items: center;
  column-gap: 20px;
  font-weight: bold;
  font-size: 17px;
  line-height: 22px;
  color: var(--matterColorAnti);
}

.bookingNumber {
  font-size: 18px;
  line-height: 23px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  @media (--viewportMobile) {
    font-size: 14px;
    line-height: 18px;
  }
}

.formControlClass * {
  font-size: 16px !important;
  font-family: 'Source Sans Pro', sans-serif !important;
}

.topTitle {
  color: var(--marketplaceColorBackground);
  font-weight: bold;
  font-size: 24px;
  margin: 20px 10px;

  @media (--viewportMobile) {
    font-size: 20px;
  }
}