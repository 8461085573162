@import '../../marketplace.css';

.root {}

.select {
  color: var(--matterColorAnti);
  border-bottom-color: var(--attentionColor);
  padding: 4px 20px 10px 0;
  border-radius: 0;
  background-image: url('data:image/svg+xml;utf8,<svg width="14" height="9" viewBox="0 0 14 9" xmlns="http://www.w3.org/2000/svg"><path d="M6.53 7.472c.26.26.68.26.94 0l5.335-5.333c.26-.263.26-.684 0-.944-.26-.26-.683-.26-.943 0L7 6.056l-4.862-4.86c-.26-.26-.683-.26-.943 0-.26.26-.26.68 0 .943L6.53 7.47z" stroke="%0d1624" fill="%0d1624" fill-rule="evenodd"/></svg>');
  background-size: 14px 14px;
  background-position: center right;

  & option {
    color: var(--matterColor);
  }

  & optgroup {
    color: var(--matterColor);
  }

  &>option:disabled {
    color: var(--matterColorAnti);
  }

  &:disabled {
    border-bottom-color: var(--matterColorNegative);
    color: var(--matterColorNegative);
    cursor: default;
  }
}

.selectSuccess {
  color: var(--matterColor) !important;
  /* border-bottom-color: var(--successColor); */
  border-bottom-color: var(--marketplaceColor);
}

.selectError {
  border-bottom-color: var(--failColor);
}

.headingWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & svg {
    fill: transparent;
  }
}

.support {
  position: relative;
  display: flex;
  justify-content: flex-end;
  width: 70%;
}

.support .supportText {
  font-size: 16px;
  line-height: 24px;
  font-size: 16px;
  line-height: 24px;
  background-color: var(--matterColorDark);
  color: var(--matterColorLight);
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  margin: 0 21px;
  position: absolute;
  top: 0px;
  right: 9px;
  z-index: 1;
}

.hideSupportText {
  visibility: hidden;
}

.limitError {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ff0000;
  margin-top: 6px;
  margin-bottom: 10px;
}