@import '../../../../marketplace.css';

.header {
}

.subHeadingWrapper{
  @media (--viewportMedium) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

.subHeading{
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #C4C4C4;
    margin: 0;
    width: 150px;
    padding-bottom: 20px;
    @media (--viewportMedium) {
      padding-bottom: 0;
    }
}

.tablePlaceholder {
    padding: 20px;
    text-align: center;
}
.spinner {
  display: block;
  margin: 80px auto;
}

.heading {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  padding: 15px;
  justify-content: flex-start;
  @media (--viewportMedium) {
    justify-content: space-between;
    flex-direction: row;
    gap: 0;
    padding: 20px;
  }
}



.sendMessageButton{
  font-weight: 500;
  padding: 0 16px;
  min-height: auto;
  min-width: 150px;
  height: 41px;
  display: inline-block;
  width: auto;
}

.helpers{
  display: flex;
  align-items: center;
  column-gap: 20px;
  width: 100%;
  flex-wrap: wrap;
  row-gap: 10px;
  @media (--viewportMedium) {
    width: auto;
  }
}

.filterKeywordWrap {
  justify-content: left;
  align-items: center;
  margin-left: 15px;
  display: flex;
  @media (--viewportMedium) {
    margin-left: 67px;
  }
}

.filterWrap {
  width: 375px;
  height: 46px;
  padding: 0 6px 0 16px;
  background: var(--matterColorLight);
  border-radius:28px;
  border: 1px solid #d6d6d6;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 0 8px;

  @media (--viewportMobile) {
    width: 100%;
    max-width: 287px;
    height: 44px;
    border-radius: 28px;
    padding: 0 8px 0 16px;
    /* margin-bottom: 18px; */
  }
}
.filterWrap label {
  padding: 0 10px;
  margin: 0 20px 0 0;
  @media (--viewportMobile) {
    margin: 0 6px 0 0;
  }
}

.filterWrap svg {
  width: 21px;
  height: 21px;
  transform: scaleX(-1)
}
.filterKeywordWrap input {
  width: 214px;
  border-bottom: none;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: var(--matterColorDimmedText);
  padding: 5px;

  @media (--viewportMobile) {
    width: 100%;
    min-width: 96px;
    &::placeholder {
      font-size: 12px;
      line-height: 20px;
    }
  }
}

.btnapply {
  width: 85px;
  min-width: 63px;
  border-radius: 16.5601px;
  min-height: 33px;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  text-transform: capitalize;
  margin-left: 14px;
  color: var(--matterColorLight);
  background: var(--marketplaceColor);
  @media (--viewportMobile) {
    width: 64px;
    min-width: 64px;
    min-height: 28px;
    font-weight: 600;
    font-size: 10px;
    margin: 0;
    line-height: 12px;
  }
}

.tableWrapper{
  width: 100%;
  overflow-x: auto;
  transform: scaleY(-1);
}

.scheduleTable{
  overflow: unset;
  transform: scaleY(-1);
  margin-bottom: 0;
  padding-top: 25px;
}

.WeekDayBtns {
  display: flex;
  flex-wrap: wrap;
  margin: -4px;
  & div{
    font-weight: normal;
    font-size: 15px;
    line-height: 19px;
    cursor: pointer;
    height: 40px;
    padding: 0 22px;
    justify-content: center;
    align-items: center;
    transition: background-color 0.2s linear, color 0.2s linear;
    margin: 4px;
    display: flex;
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
  @media (--viewportMobile) {
    justify-content: center;
  }
}
