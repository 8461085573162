.field {
  margin: 0;

  font-size: 18px;
  line-height: 24px;
}

.wrapper {
  margin: 20px 0;
}

.title {
  margin: 0;
  color: var(--matterColorAnti);
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-decoration: underline;
}
