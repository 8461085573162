@import '../../marketplace.css';

.companyLink {
  &:hover {
    text-decoration: none;
  }
}

.companyMain {
  max-width: 900px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
  width: 218px;
  height: 290px;
  position: relative;
  border-radius: 10px;
  margin: 10px 5px;
  box-shadow: 0 3px 20px 0 rgb(0 0 0 / 5%);
  font-family: HelveticaNeue, 'Source Sans Pro', sans-serif;
  box-sizing: border-box;
  border: 0.826475px solid #ccd7ea;

  @media (--viewportMobile) {
    width: 215px;
  }
}

.popularImage {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  object-fit: cover;
  height: 100%;
  max-height: 167px;
}

.addressWrapper {
  display: flex;
  column-gap: 4px;
  padding: 4px 0px;
  min-height: 32px;

  & span {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #6e6f71;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;

    @media (--viewportMobile) {
      font-size: 16px;
      line-height: 18px;
    }
  }

  & svg {
    fill: transparent;
  }
}

.companyInfo {
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  text-align: center;
  line-height: 23px;
  height: 46px;
  color: #272727;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.aspectWrapper {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.sliderWrapper {
  width: 215px;
  height: 167px;

  @media (--viewportMobile) {
    width: 100%;
    height: 223px;
  }
}

.ArrowLeft {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 45%;
  left: 7px;

  @media (--viewportMobile) {
    top: auto;
    right: auto;
    bottom: -20px;
    left: 0px;
    z-index: 0 !important;
  }
}

.ArrowRight {
  width: 20px;
  height: 20px;
  position: absolute;
  transform: scaleX(-1);
  top: 45%;
  right: 12px;
  display: none;

  @media (--viewportMobile) {
    top: auto;
    bottom: -20px;
    right: 5px;
    z-index: 0 !important;
  }
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
  transition: var(--transitionStyleButton);
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border-radius: var(--borderRadius);
}

.imageWrapper {
  position: relative;
  width: 100%;
  height: 167px;
  border-radius: 8px;
  transition: var(--transitionStyleButton);
  cursor: pointer;
  overflow: hidden;
}

.cardDetailsWrapper {
  padding: 5px 18px 10px;
}