@import '../../marketplace.css';

.root {
  position: sticky;
  top: 70px;
  left: 0;
  background: var(--matterColorLight);
  z-index: 1;
}

.advanceFilterContainer {
  position: relative;
}

.advanceFilterWrapper {
  /* padding: 0 34px; */
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 16px;

  @media screen and (max-width: 1023px) {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 19px;
  }

  @media (--viewportTabletDown) {
    flex-direction: column;
    padding: 10px 24px 0;
  }
}

.subCategoryItemsWrap {
  display: flex;
  column-gap: 11px;
  padding: 26px 0 30px;
  column-gap: 16px;

  @media screen and (max-width: 1200px) {
    overflow-x: scroll;
    max-width: 100%;
    overflow-y: hidden;
  }
}

.mobileSubCategoryItemsWrap {
  display: flex;
  padding: 19px 24px 19px;
  column-gap: 11px;
  overflow-x: scroll;
  width: 100%;
  background: var(--matterColorLight);
}

.subCategoryItem {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-transform: capitalize;
  color: #6e6f71;
  background: #f7f7f7;
  border-radius: 40px;
  padding: 8px 22px;
  white-space: nowrap;
  max-height: 34px;

  @media (--viewportTabletDown) {
    overflow-x: scroll;
    width: 100%;
    padding: 33px 15px 18px;
  }
}

.subCategoryItem {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-transform: capitalize;
  color: #6e6f71;
  background: #f7f7f7;
  border-radius: 40px;
  padding: 8px 22px;
  white-space: nowrap;
  max-height: 34px;

  max-width: 154px;
  white-space: nowrap;
}

.subCategoryMoreItems {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: var(--marketplaceColor);
  display: flex;
  align-items: center;
  column-gap: 6px;
  padding: 8px 19px 10px;
  max-height: 34px;

  @media (--viewportMobile) {
    width: 88px;
  }

  & svg {
    margin-left: 4px;
    & path {
      fill: var(--marketplaceColor);
    }
  }

  @media (--viewportMobile) {
    color: var(--matterColorDarkBlue);
    & svg path {
      fill: var(--matterColorDarkBlue);
    }
  }
}

.selectedSubCategoryItem {
  background: var(--matterColorLight);
  border: 1px solid var(--marketplaceColor);
  box-sizing: border-box;
  border-radius: 40px;
  color: var(--marketplaceColor);
  @media (--viewportMobile) {
    background: var(--marketplaceColorBackground);
  }
}

.rightSideFilters {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 7px;
  padding-bottom: 4px;

  @media screen and (max-width: 400px) {
    overflow-x: scroll;
    max-width: 300px;
    padding-bottom: 15px;
    justify-content: flex-start;
  }
}

.labelSellerLevels {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-transform: capitalize;
  color: var(--marketplaceColor);
  display: flex;
  align-items: center;
  column-gap: 6px;
  white-space: nowrap;
  padding: 9px 10px 9px 14px;
  background: var(--matterColorLight);
  border: 1px solid var(--marketplaceColor);
  box-sizing: border-box;
  border-radius: 34px;
  max-height: 34px;
}

.groupSizeDropdownLabel {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-transform: capitalize;
  color: var(--marketplaceColorDarkerI);

  display: flex;
  align-items: center;
  column-gap: 6px;
  white-space: nowrap;
  padding: 9px 11px 9px 14px;
  background: var(--matterColorLight);
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 34px;
  max-height: 34px;

  & svg {
    margin-left: 5px;
    & path {
      fill: var(--matterColorDark);
    }
  }

  @media (--viewportMobile) {
    justify-content: space-between;
  }
}

.priceFilter {
  background: var(--matterColorLight);
  border: 1px solid var(--marketplaceColor);
  box-sizing: border-box;
  border-radius: 34px;
  & svg {
    margin-left: 30px;
  }
  & svg path {
    fill: var(--marketplaceColorDarkerI);
  }
}

.menuContentClass {
  background-color: var(--matterColorLight);
  height: auto;
  margin-top: 20px;
  overflow: inherit;
  top: 30px;
}

.menuContentMoreClass {
  background-color: var(--matterColorLight);
  height: 85vh;
  margin-top: 20px;
  top: 30px;
}


.filterKeywordWrap {
  width: 211px;
  justify-content: left;
  align-items: center;
  display: flex;
  position: relative;
}

.filterWrap {
  width: 211px;
  height: 34px;
  padding: 0 10px 0 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0 8px;
  background: var(--matterColorLight);
  border: 1px solid #E7E7E7;
  box-sizing: border-box;
  border-radius: 35px;

  @media (--viewportMobile) {
    width: 100%;
    height: 44px;
    background: #f5f5f5;
    border-radius: 21px;
    padding: 0 8px 0 16px;
  }
}

.filterWrap label {
  padding: 0 10px;
  margin: 0 20px 0 0;

  @media (--viewportMobile) {
    margin: 0 6px 0 0;
  }
}

.filterWrap svg {
  width: 21px;
  height: 21px;
  transform: scaleX(-1);
}

.filterKeywordWrap input {
  width: 170px;
  border-bottom: none;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: var(--matterColorDimmedText);

  @media (--viewportMobile) {
    &::placeholder {
      font-size: 12px;
      line-height: 20px;
    }
  }
}

.btnapply {
  position: absolute;
  top: 2px;
  right: 2px;
  padding: 2px 7px 2px 0;

  & svg {
    width: 100%;
    height: 21px;
    margin: 0;
    & path{
      stroke: var(--marketplaceColor);
    }
    & circle{
      stroke: var(--marketplaceColor);
    }
  }
}