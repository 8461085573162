.wrapper > div{
    border: 1px solid rgb(229, 229, 229);
    border-radius: 35px;
    padding: 0 10px;
    min-width: 250px;
}

.wrapper input{
    max-height: 45px !important;
    height: 20px !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 16px !important;
    border-bottom: none !important;

}

.wrapper button{
    max-height: 45px !important;
    height: 30px !important;
    margin-top: -7px;
}

.wrapper div::before{
    border-bottom: none !important;
}

.wrapper div{
    max-height: 45px !important;

}

