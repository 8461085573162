@import '../../marketplace.css';

.iconArrowDown {
  width: 12px;
  margin-left: 10px;
  fill: var(--marketplaceColorDarker);
}
.selected {
  fill: var(--marketplaceColorLight);
}
