@import '../../marketplace.css';

.root {
  display: flex;
  & button {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 220px;
    min-height: 38px;
    border: 1px solid var(--marketplaceColorDark);
    color: var(--marketplaceColorDark);
    font-weight: normal;
    font-size: 14px;
  }
  @media (--viewportMobile) {
    justify-content: center;
  }
}

.root :first-child{
  /* z-index: 9996; */
}

.input {
  position: absolute;
  left: -999em;
}

.icon {
  margin-right: 12px;
}

.shareFontStyle {
  padding-left: 11px;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: var(--marketplaceColorDarker);
}

