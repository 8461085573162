@import '../../../../marketplace.css';


/* Passed-in props for FeedSection subcomponent */
.feedContainer {
    margin: 0;
    position: sticky;
    top: 114px;
  
    @media (--viewportMedium) {
      margin: 40px 24px 0 24px;
    }
    @media (--viewportLarge) {
      margin: 0;
    }
}

/* FeedSection subcomponent */
.feedHeading {
    color: var(--matterColorAnti);
    margin: 0;
  
    @media (--viewportMedium) {
      margin: 0;
    }
}

.enquiryFeedHead{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 0;
  @media (--viewportMedium) {
    padding: 37px 38px;
  }
  
}

.feedUserWrapper{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.feedUserName{
  font-weight: 700;
  font-size: 22px;
  line-height: 16px;
  color: var(--matterColorDarkBlue);
  @media (--viewportMobile) {
    padding-left: 21px;
    font-weight: 700;
    font-size: 26px;
    line-height: 35px;
    color: var(--marketplaceColor);
  }
}

.feedIconDot{
  display: none;
  @media (--viewportMobile) {
    display: block; 
  }
}

.feed {
  height: calc(100vh - 285px);
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 20px;
  @media (--viewportMobile) {
    height: calc(100vh - 180px);
    padding-right: 12px;
  }
  
}

.feed::-webkit-scrollbar{
	width: 6px;
  background-color: transparent;
}

.feed::-webkit-scrollbar-thumb{
	background-color: var(--matterColorAnti);
}

.messageError {
    color: var(--failColor);
    margin: 13px 0 22px 0;

    @media (--viewportMedium) {
        margin: 13px 0 23px 0;
    }

    @media (--viewportLarge) {
        margin: 12px 0 23px 0;
    }
}

.goBackIcon{
  display: none;
  @media (--viewportMobile) {
    display: block;
  }
}

.itemLink{
  color: var(--matterColorDark);
}

.messageToolsWrapper{
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 30px;
  padding: 12px 0 0 24px;
  @media (--viewportMobile) {
      width: auto;
      bottom: auto;
      top: auto;
      padding-top: 10px;
      padding-left: 0;
  }

}

.SendMsgKeywordWrap input {
  width: 100%;
  border-bottom: none;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: var(--matterColorDimmedText);
  @media (--viewportMobile) {
      width: 100%;
      &::placeholder {
      font-size: 12px;
      line-height: 20px;
      }
  }
}
.SendMsgKeywordWrap{
  width: 100%;
  display: flex;
  column-gap: 15px;
}

.sendButton{
  min-height: 37px;
  border-radius: 28px;
  width: 80px;
}

.MsgWrap {
  height: 46px;
  width: 90%;
  padding: 0 6px 0 16px;
  background: #F8F8F8;
  border-radius:30px;
  border: 1px solid #d6d6d6;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0 8px;
  @media (--viewportMobile) {
      width: 100%;
      max-width: 287px;
      height: 44px;
      border-radius: 28px;
      padding: 0 8px 0 16px;
      /* margin-bottom: 18px; */
  }
}
.MsgWrap label {
  padding: 0 10px;
  margin: 0 20px 0 0;
  @media (--viewportMobile) {
      margin: 0 6px 0 0;
  }
}

.MsgWrap svg {
  width: 21px;
  height: 21px;
  transform: scaleX(-1)
}

.sendInputWrap{
  width: 100%;
}