@import '../../../marketplace.css';

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid #e7e7e7;
  border-radius: 12px;
  box-shadow: 0px 2.71962px 2.71962px rgb(0 0 0 / 25%);
  padding: 17px;
}

.paymentHeadingWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 8px;
}

.paymentHeadingIconWrapper {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
}

.stripeIcon {
  display: block;
  margin: auto 0px;
  height: 45px;
}

.cardSectionWrapper {
  margin-bottom: 40px;
  width: 100%;
}

.spinnerMiddle>svg {
  color: var(--marketplaceColor);
  transform: scale(0.7);
}

.spinnerMiddle {
  margin: 0 auto;
}

.title {
  flex: 1;
  margin-top: 12px;
  margin-bottom: 12px;
}

.errorMsg {
  color: red;
  text-align: center;
  width: 100%;
  padding: 10px;
}
