@import '../../marketplace.css';

.popupSize {
  padding: 0 10px 7px 10px;
}

.duration {
  padding: 0 24px 24px 24px;
}

.startTime, .endTime{
  padding: 0 24px 24px 24px;
}
.bookingTime{
  width: 100%;
  display: flex;
}