.root {
  position: absolute;
  top: -30px;
  right: -30px;
  width: 50px;
  height: 100%;
  cursor: pointer;

  &>.img {
    position: absolute;
    top: -68px;
    right: 30px;
    width: 41.83px;
    height: 41.83px;
    background-color: #00AB99;
    border: 2px solid #D7E3F5;
    box-sizing: border-box;
    border-radius: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: contain;
  }

  &>.disableImg {
    position: absolute;
    top: -68px;
    right: 30px;
    width: 41.83px;
    height: 41.83px;
    background-color: #fff;
    border: 2px solid #D7E3F5;
    box-sizing: border-box;
    border-radius: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: contain;
  }

  @media (--viewportMobile) {
    display: none;
  }
}

.left {

  /* left: -15px; */
  &>.img,
  &>.disableImg {
    /* transform: translateY(-50%) rotate(180deg); */
    transform: scaleX(-1);
    top: -68px;
    right: 90px;
  }
}

.right {
  right: -18px;
  height: 55px;
}
