@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --EditListingDescriptionForm_formMargins: {
    margin-bottom: 24px;

    @media (--viewportMedium) {
      margin-bottom: 32px;
    }
  }
}

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.title,
.companyName {
  @apply --EditListingDescriptionForm_formMargins;
}

.description {
  @apply --EditListingDescriptionForm_formMargins;
  flex-shrink: 0;
}

.customField {
  @apply --EditListingDescriptionForm_formMargins;
}

.category {
  @apply --EditListingDescriptionForm_formMargins;
}

.subCategory {
  @apply --EditListingDescriptionForm_formMargins;
}

.providerName {
  @apply --EditListingDescriptionForm_formMargins;
}

.addCompanyNameNote{
  @apply --EditListingDescriptionForm_formMargins;
  color: var(--matterColorAnti);
}

.companyListing{
  @apply --EditListingDescriptionForm_formMargins;
}

.bankDetails{
  @apply --EditListingDescriptionForm_formMargins;
}

.submitButton {
  border-radius: 8px;
  min-height: 66px;
  padding: 20px 54px;
  white-space: nowrap;
  width: 257px;
  background-color: var(--marketplaceColor);
  border: 0;
  color: var(--matterColorLight);
  display: flex;
  justify-content: center;

  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;
  align-self: center;
  cursor: pointer;
  &:hover{
    background-color: var(--marketplaceColorLight);
  }

  @media (--viewportLarge) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 257px;
    margin: auto;
    /* margin-top: 100px; */
  }
}

.submitButton:disabled {
  background-color: #C4C4C4;
  color: var(--marketplaceColorDarkerI);
}

.locationAddress,
.building {
  flex-shrink: 0;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}
.validLocation {
  border-bottom-color: var(--successColor) !important;
}
.locationAutocompleteInputIcon {
  display: none;
}
.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 36px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    top: 40px;
  }
}
.locationAutocompleteInput {
  flex-grow: 1;
  height: 36px;
  line-height: unset;
  padding-left: 0;
  margin: 0;
  border-bottom: 2px solid var(--attentionColor);

  &:hover,
  &:focus {
    border-bottom-color: var(--matterColorDark);
    outline: none;
  }

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  @media (--viewportMedium) {
    height: 40px;
  }
}

.mutateBtns {
  margin-top: 24px;
  display: flex;
  justify-content: space-evenly;
  @media (--viewportMobile) {
    margin-bottom: 24px;
  }
  & > button {
    max-width: 200px;
    min-height: 40px;
  }
}

.stripeButton{
  display: flex;
  flex-direction: column;
  gap: 20px 0;
  margin: 24px 0;
  & button{
    max-width: 520px;
    margin: 0 auto;
  }
}

.formGroup {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 50px 80px 40px 80px;

  @media (--viewportMobile) {
    padding: 11px 24px 40px 24px;
  }
}

.btnWrapper {
  background-color: rgb(0, 0, 0, 0.7);
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding: 10px 20px 10px 35px;
  margin: 5px;

  @media(--viewportMobile) {
    /* padding-left: 25px; */
    display: none;
  }
}

.headingTitle {
  font-size: 35px;
  color: white;
  text-transform: capitalize;
  margin: 0;

  @media (--viewportMobile) {
    font-size: 20px;
  }
}

.alignCenter {
  display: flex;
  justify-content: center;
}

.descriptionMessage {
  display: flex;
  align-self: center;
  justify-content: center;
  text-align: center;
  max-width: 380px;
}
