@import '../../marketplace.css';

.root {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  height: 75px;
  width: 100%;
  padding: 14px 0 12px;
  background-color: var(--matterColorLight);
  z-index: 999; /* must be below 1000 to not overlap CookieConsent banner */
  box-shadow: 0px -11px 19px -10px rgb(0 0 0 / 21%);
    border-radius: 10px 10px 0 0;
  @media (--viewportMobile) {
    display: block;
  }
}

.iconWrap {
  display: flex;
  align-items: flex-end;
  padding: 0 10px;
  width: 80%;
  margin: 0 auto;
  justify-content: space-between;
}

.bottomMenuLink {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: #949494;
  &.withBadge {
    position: relative;
  }
  &:hover {
    text-decoration: none;
  }
}

.bottomMenuIcon {
  width: 31px;
  height: 31px;
}

.loginMenuIcon {
  width: 20px;
  height: 31px;
  fill: #898989;
  stroke: var(--matterColorGrey);
}

.notificationBadge {
  position: absolute;
  top: -7px;
  right: 3px;
  &:hover {
    text-decoration: none;
  }
}

.blueSvg{
  & path{
    fill: var(--marketplaceColorLight);
  }
}