@import '../../marketplace.css';

.processesSection {
  display: flex;
  justify-content: center;
  padding: 65px 0;

  @media (--viewportMobile) {
    padding: 50px 0;
  }
}

.containerWrapper {
  max-width: 1501px;
  margin: 0 120px;

  @media (--viewportLaptopDown) {
    margin: 0 50px;
  }

  @media (--viewportMobile) {
    margin: 0 30px;
  }
}

.processDiagramWrapper {
  width: 100%;
  text-align: center;

  &>img {
    width: 50%;

    @media (--viewportMobile) {
      width: 100%;
    }
  }
}