@import '../../marketplace.css';

.iconFavourite {
  stroke: #30c7be;
  color: #30c7be;
  width: 10px;
  margin-right: 5px;
}

.iconFavouritePlus {
  stroke: #30c7be;
  color: #30c7be;
  width: 6px;
  position: absolute;
  left: 9px;
  top: 3px;
}

.iconFavouriteWrapper{
 position: relative;
}
