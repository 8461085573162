@import '../../marketplace.css';

.staticPageWrapper {
  width: calc(100% - 48px);
  max-width: 1056px;
  margin: 24px auto;

  @media (--viewportMedium) {
    width: calc(100% - 72px);
    margin: 72px auto;
  }
}

.descriptionTextContainer * {
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 20px;
}

.sectionWrapper {
  font-size: 26px;
  font-weight: bold;
}

.countryListSection {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}

.countryListItem {
  flex-basis: 25%;
  max-width: 25%;
  padding: 8px;
  font-size: 15px;

  @media (max-width: 900px) {
    flex-basis: 50%;
    max-width: 50%;
  }
}

.countryNameTitle {
  margin-left: 5px;
}

.title {
  margin: 15px 0;
}