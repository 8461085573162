@import '../../marketplace.css';

.root {
  margin: 24px 0;
}

label{
  width: 100%;
}
.headingWrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    & svg{
      fill: transparent;
    }
  }
  
  .support{
    position: relative;
    display: flex;
    justify-content: flex-end;
    width: 70%;
  }
  
  .support .supportText {
    font-size: 16px;
    line-height: 24px;
    font-size: 16px;
    line-height: 24px;
    background-color: var(--matterColorDark);
    color: var(--matterColorLight);
    text-align: center;
    border-radius: 6px;
    padding: 5px 10px;
    margin: 0 21px;
  
    /* Position the tooltip */
    position: absolute;
    top: 0px;
    right: 9px;
    z-index: 1;
  }
  
  .hideSupportText{
    visibility:hidden;
  }