@import '../../marketplace.css';

.commercialModelSection {
  display: flex;
  justify-content: center;
  padding: 65px 0;

  @media (--viewportMobile) {
    padding: 50px 0;
  }

  & p,
  li {
    font-size: 20px;
    line-height: 24px;
  }

  & table td,
  th {
    line-height: 30px;
  }
}

.redFontClass {
  color: var(--marketplaceRedDarkColor);

}

.containerWrapper {
  max-width: 1501px;
  margin: 0 120px;

  @media (--viewportLaptopDown) {
    margin: 0 50px;
  }

  @media (--viewportMobile) {
    margin: 0 30px;
  }
}

.subContainerWrapper {}

.descriptionTable {
  display: flex;
  justify-content: center;

  & * {
    font-size: 20px;

    @media (--viewportMobile) {
      font-size: 16px;
    }
  }

  & table {
    max-width: 800px;
  }

  & table td {
    background-color: #f3f3f3;
    border: 1px solid white;
    text-align: left;
    vertical-align: top;
    padding: 10px;
    height: 24px;
  }

  & th {
    background-color: #cccccc;
    border: 1px solid white;
    text-align: left;
    padding: 10px;
    vertical-align: top;
    height: 24px;
  }
}

.tableSection {
  display: flex;
  flex-direction: column;

  & *,
  :not(p) {
    font-size: 20px;

    @media (--viewportMobile) {
      font-size: 16px;
    }
  }

  & table {
    max-width: 800px;
    align-self: center;
  }

  & table td {
    background-color: #f3f3f3;
    border: 1px solid white;
  }

  & th {
    background-color: #cccccc;
    border: 1px solid white;
  }

  & td:nth-child(even) {
    background-color: #cccccc;
  }

  &>p {
    font-style: italic;
  }
}

.oneRowSection {
  display: flex;
  flex-direction: column;

  & * {
    font-size: 20px;

    @media (--viewportMobile) {
      font-size: 16px;
    }
  }

  & table {
    max-width: 800px;
    align-self: center;
  }

  & th:nth-child(2) {
    background-color: #f3f3f3;
    border: 1px solid white;
  }

  & th:first {
    background-color: #cccccc;
    border: 1px solid white;
  }

  &>p {
    font-style: italic;
  }
}

.disclaimerSection {
  font-style: italic;
  margin-top: 80px;
}