@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --CheckoutPage_logoHeight: 25px;
  --CheckoutPage_logoHeightDesktop: 27px;
}

/* Dummy Topbar */

.home {
  display: flex;
  padding: calc((var(--topbarHeight) - var(--CheckoutPage_logoHeight)) / 2) 24px;

  @media (--viewportLarge) {
    padding: calc((var(--topbarHeightDesktop) - var(--CheckoutPage_logoHeightDesktop)) / 2) 36px;
  }
}

.logoMobile {
  display: block;
  width: auto;
  height: 27px;

  @media (--viewportLarge) {
    display: none;
  }
}

.logoDesktop {
  display: none;
  width: auto;
  height: 27px;

  @media (--viewportLarge) {
    display: block;
  }
}

/* /Dummy Topbar */

/**
 * Content: mobile flow vs desktop 2 columns
 * desktop has reduced version of bookListingContainer (incl. title and Stripe paymentForm).
 * priceBreakdown is inside separate column (detailsContainerDesktop)
 */
.contentContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  align-items: center;

  @media (--viewportLaptop) {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 0 48px 40px;
  }
}

.bookListingContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-width: 1000px;

  @media (--viewportLarge) {
    margin-top: 63px;
  }

  @media (--viewportLaptop) {
    flex-grow: 0;
    flex-basis: 519px;
    margin-right: 170px;
  }

  @media (--viewportLaptopDown) {
    margin-bottom: 10px;
    padding: 20px;
    width: 100%;
  }
}

.modifiedBookListingContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;

  @media (--viewportLaptop) {
    flex-grow: 0;
    flex-basis: 519px;
    /* margin-top: 29px; */
    margin-right: 200px;
  }

  @media (--viewportTabletDown) {
    margin-bottom: 10px;
    padding: 20px;
    width: 100%;
  }
}

.aspectWrapper {
  position: relative;
  padding-bottom: 66.6667%;
  /* 3:2 Aspect Ratio */
  background-color: var(--matterColorNegative);
  /* Loading BG color */

  @media (--viewportLarge) {
    display: none;
  }
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border-radius: 12px 12px 0 0;
  /* @media (--viewportLarge) {

  } */
}

.avatarWrapper {
  /* Position (over the listing image)*/
  /* margin-left: 24px;
  margin-top: -31px; */

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Layout */
  display: block;

  & div {
    width: 54px;
    height: 54px;
    background: #c4c4c4;

    & span {
      text-transform: uppercase;
    }
  }
}

.avatarMobile {
  @media (--viewportLarge) {
    display: none;
  }
}

.heading {
  margin-top: 29px;
  margin-bottom: 34px;
  padding: 0 24px;

  @media (--viewportMedium) {
    margin-top: 27px;
    margin-bottom: 30px;
  }

  @media (--viewportLarge) {
    margin-top: 0px;
    margin-bottom: 54px;
    padding: 0;
  }
}

.title {
  /* Font */
  @apply --marketplaceH1FontStyles;
  color: var(--matterColor);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 7px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.author {
  width: 100%;
  @apply --marketplaceH4FontStyles;

  /* Reset margins from font styles */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.priceBreakdownContainer {
  padding: 0 24px;
  margin-bottom: 37px;

  @media (--viewportMedium) {
    margin-bottom: 38px;
  }

  @media (--viewportLarge) {
    /* we don't show price breakdown since it will be visible within 2nd column (detailsContainerDesktop) */
    display: none;
  }
}

.priceBreakdownTitle {
  /* Font */
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 14px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 18px;
  }

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 14px;
  }
}

.paymentContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  /* padding: 0 24px; */

  @media (--viewportMedium) {
    margin-top: 2px;
  }

  @media (--viewportLarge) {
    margin-top: 4px;
    padding: 0;
  }

  & h2 {
    margin: 0;
  }
}

.orderError,
.notFoundError {
  margin: 11px 0 12px 0;

  @media (--viewportMedium) {
    margin: 10px 0 12px 0;
  }
}

.orderError {
  color: var(--failColor);
}

.notFoundError {
  color: var(--attentionColor);
}

.speculateError {
  color: var(--failColor);
  margin: 19px 0 1px 0;

  @media (--viewportLarge) {
    margin: 28px 48px 0 48px;
  }
}

.paymentForm {
  flex-grow: 1;
}

/* Details column is visible only on desktop */
.detailsContainerDesktop {
  @media (--viewportLaptop) {
    flex-shrink: 0;
    display: block;
    /* Dimensions */
    width: 369px;
    margin: 63px 0 0 0;

    /* Coloring */
    border-radius: 2px;
  }

  @media (max-width: 1366px) {
    margin-bottom: 90px;
    padding: 0 20px;
    border-radius: 12px 12px 0 0;
  }

  @media (--viewportMobile) {
    margin-bottom: 30px;
    padding: 0 23px;
    width: 100%;
  }
}

/* Details column is visible only on desktop */
.withoutPaymentDetailsContainerDesktop {
  @media (--viewportLarge) {
    flex-shrink: 0;
    display: block;
    /* Dimensions */
    width: 369px;

    /* Position */
    padding: 20px 20px 15px 20px;

    /* Coloring */
    border-radius: 2px;
  }

  @media (--viewportLaptopDown) {
    margin-bottom: 90px;
    padding: 0 20px;
    border-radius: 12px 12px 0 0;
  }

  @media (--viewportMobile) {
    margin-bottom: 30px;
    padding: 0 23px;
    width: 100%;
  }
}

.detailsAspectWrapper {
  position: relative;
  padding-bottom: 65.6667%;
  border-radius: 12px 12px 0 0;
}

.detailsHeadings {
  /* display: none; */
  padding-left: 20px;

  @media (--viewportLarge) {
    display: block;
    /* margin: 0 48px; */
  }
}

.detailsTitle {
  margin-bottom: 10px;
  margin: 0;
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  text-transform: uppercase;
  color: var(--marketplaceColorDarkest);

  @media (--viewportLarge) {
    /* margin-top: 17px; */
    margin-bottom: 9px;
  }
}

.detailsSubtitle {
  /* @apply --marketplaceH5FontStyles; */

  /* Reset margins from font styles */
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: var(--marketplaceRedDarkColor);
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.bookingBreakdownTitle {
  /* Font */
  color: var(--matterColorAnti);

  /* Layout spacing */
  margin: 5px 24px 25px 24px;

  @media (--viewportLarge) {
    margin: 37px 48px 26px 48px;
  }
}

.bookingBreakdown {
  margin-top: 24px;
}

.remainingSessions {
  color: var(--matterColorPink);
}

.topbar {
  z-index: 1;
  /* ensure that Topbar shadow overlays content background */
}

.mobileTopbar {
  /* Size */
  width: 100%;
  height: auto;

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  /* fill */
  /* background-color: var(--matterColorLight); */

  /* shadows */
  box-shadow: none;
  padding: 27px 24px;

  @media (--viewportLarge) {
    display: none;
  }
}

.desktopTopbar {
  box-shadow: none;

  @media (--viewportLarge) {
    box-shadow: var(--boxShadowLight);
  }
}

.options {
  display: grid;
  grid-template-columns: 250px;
  grid-row: auto;
  grid-column-gap: 45px;
  grid-row-gap: 34px;
  justify-content: center;

  @media (--viewportLarge) {
    grid-template-columns: 300px 300px;
  }

  @media (--viewportMobile) {
    grid-template-columns: 50% 50%;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }
}

.optionItem {
  position: relative;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 26px;
  text-align: center;
  color: var(--marketplaceColorDarkest);

  /* height: 250px;
  width: 100%; */
  width: 244px;
  height: 193px;
  margin-bottom: 18px;
  padding: 20px;
  border: 1px solid var(--matterColorNegative);
  background-color: var(--matterColorLight);
  border-radius: 15px;
  cursor: pointer;

  &:hover {
    box-shadow: var(--boxShadowButton);
  }

  @media (--viewportLarge) {
    margin-bottom: 0;
  }

  @media (--viewportMobile) {
    height: 152px;
    width: auto;
    font-size: 12px;
    line-height: 18px;
    margin: 0;
  }
}

.selectedPaymentOptionItem {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  position: relative;
  width: 245px;
  height: 193px;
  background-color: var(--marketplaceColorBackground);
  margin-bottom: 18px;
  padding: 20px;
  border: 1px solid var(--matterColorNegative);
  border-radius: 15px;
  cursor: pointer;

  & div {
    color: white;

    & svg {
      & path {
        width: 100px;
        height: 100px;
        fill: white;
      }
    }
  }

  &:hover {
    box-shadow: var(--boxShadowButton);
  }

  @media (--viewportLarge) {
    margin-bottom: 0;
  }

  @media (--viewportMobile) {
    width: auto;
    height: 152px;
    font-size: 12px;
    line-height: 18px;
  }
}

.Summary {
  border: 1px solid var(--matterColorNegative);
  padding: 20px 17px 44px;
  border-radius: 0 0 12px 12px;
  box-shadow: 0px 2.71962px 2.71962px rgb(0 0 0 / 25%);
}

.SummaryHead {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 18px;
}

.icon {

  /* width: 100px;
  margin: 0 auto 10px; */
  &>svg {
    width: 55px;
    height: 36px;
    fill: var(--marketplaceColorDark);

    @media (--viewportMobile) {
      width: 100%;
      height: 36px;
    }
  }
}

.label {
  text-align: center;
  margin-top: 25px;

  & p {
    margin: 0;
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;

    @media (--viewportMobile) {
      font-size: 12px;
    }
  }
}

.stepContainer {
  width: 26%;
  align-self: center;
  display: flex;
  justify-content: center;

  @media (--viewportMobile) {
    width: 100%;
  }
}

.pageContainer {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

.button {
  margin-top: 48px;
  width: 50%;

  @media (--viewportMobile) {
    margin-bottom: 60px;
    margin-top: 0;
  }
}

.buttonContainer {
  display: flex;
  justify-content: center;
}

.mdstepperhorizontal {
  display: table;
  width: 100%;
  margin: 0 auto;
  /* background-color:var(--matterColorLight); */
}

.mdstepperhorizontal .mdstep {
  display: table-cell;
  position: relative;
  padding: 24px;
}

.done {
  cursor: pointer;

  & .mdstepcircle {
    background-color: #3dd170 !important;
  }

  & .mdsteptitle {
    color: #6f82a2 !important;
  }
}

.mdstepperhorizontal .mdstep:active {
  background-color: rgba(0, 0, 0, 0.04);
}

.mdstepperhorizontal .mdstep:active {
  border-radius: 15% / 75%;
}

.mdstepperhorizontal .mdstep:first-child:active {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.mdstepperhorizontal .mdstep:last-child:active {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.mdstepperhorizontal .mdstep:hover .mdstepcircle {
  background-color: #757575;
  position: relative;
  z-index: 1;
}

.mdstepperhorizontal .mdstep:first-child .mdstepbarleft,
.mdstepperhorizontal .mdstep:last-child .mdstepbarright {
  display: none;
}

.mdstepperhorizontal .mdstep .mdstepcircle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin: 0 auto;
  color: #8f8f8f;
  /* border: 2px solid #EAEAEA; */
  border-radius: 50%;
  text-align: center;
  font-weight: normal;
  font-size: 20px;
  line-height: 25px;
  color: var(--matterColorLight);
  background: #35445d;
  position: relative;
  z-index: 1;
}

.mdstepperhorizontal.green .mdstep.active .mdstepcircle {
  background-color: #00ae4d;
  position: relative;
  z-index: 1;
}

.mdstepperhorizontal.activeColor .mdstep.active .mdstepcircle {
  background-color: var(--marketplaceRedDarkColor);
  border: 0px;
  color: var(--matterColorLight);
  position: relative;
  z-index: 1;
}

.mdstepperhorizontal .mdstep.active .mdstepcircle {
  background-color: rgb(33, 150, 243);
  position: relative;
  z-index: 1;
}

.mdstepperhorizontal .mdstep.done .mdstepcircle:before {
  font-family: 'FontAwesome';
  font-weight: 100;
}

.mdstepperhorizontal .mdstep.done .mdstepcircle *,
.mdstepperhorizontal .mdstep.editable .mdstepcircle * {
  display: none;
}

.mdstepperhorizontal .mdstep.editable .mdstepcircle {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.mdstepperhorizontal .mdstep.editable .mdstepcircle:before {
  font-family: 'FontAwesome';
  font-weight: 100;
  content: '\f040';
}

.mdstepperhorizontal .mdstep .mdsteptitle {
  margin-top: 16px;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #6f82a3;
}

.mdstepperhorizontal .mdstep .mdsteptitle,
.mdstepperhorizontal .mdstep .mdstepoptional {
  text-align: center;
  /* color:rgba(0,0,0,.26); */
}

.mdstepperhorizontal .mdstep.active .mdsteptitle {
  font-weight: 600;
  color: var(--matterColorLight);
}

.mdstepperhorizontal .mdstep.active.done .mdsteptitle,
.mdstepperhorizontal .mdstep.active.editable .mdsteptitle {
  font-weight: 600;
}

.mdstepperhorizontal .mdstep .mdstepoptional {
  font-size: 12px;
}

.mdstepperhorizontal .mdstep.active .mdstepoptional {
  color: rgba(0, 0, 0, 0.54);
}

/* .mdstepperhorizontal .mdstep .mdstepbarleft {
	position:absolute;
	top:36%;
  left: 53%;
	/* height:1px; */
/* border-top:1px solid #DDDDDD;
  border-top: 2px solid #35445D;
} */
.mdstepperhorizontal .mdstep .mdstepbarright {
  position: absolute;
  height: 1px;
  border-top: 2px solid #35445d;
  /* z-index: -1; */
  width: 80%;
  top: 36%;
  left: 62%;

  /* z-index: -1; */
  @media (--viewportMobile) {
    width: calc(100% - 52px);
    top: 36%;
    left: 66%;
  }
}

/* .mdstepperhorizontal .mdstep .mdstepbarleft {
	left:0;
	right:50%;
	margin-right:20px;
  /* z-index: -1;
} */

.mobileFooter {
  display: none;

  @media (--viewportMobile) {
    display: block;
  }
}

.paymentOptionsSection {
  padding: 20px 0;

  & img {
    margin: 0 16px 0 0;

    @media (--viewportMobile) {
      margin: 0 10px 0 0;
    }
  }
}

.roundedCheckIcon {
  position: absolute;
  top: 12px;
  right: 12px;
  width: 35px;

  @media (--viewportMobile) {
    top: 8px;
    right: 8px;
    height: 30px;
    width: 30px;
  }
}

.infoSpan {
  font-size: 16px;
  color: var(--matterColorDimmedText);
}

.steps {
  display: flex;
  justify-content: center;
  background: var(--marketplaceColorBackground);
  padding: 5px 0;
  z-index: 0;
}

.ContainerLine {
  width: 100%;
  height: 8px;
  background: var(--marketplaceColorBackground);
}

.detailedFormButtonWrapper {
  display: flex;
  column-gap: 20px;
}

.backButtonWrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 20px 0 0 40px;
}

.backButton {
  transform: scale(1.5);
  cursor: pointer;

  &:hover {
    color: var(--marketplaceColor);
  }
}

.testNotification {
  border: 1px solid var(--matterColorNegative);
  border-radius: 10px;
  text-align: center;
  padding: 40px 5px;
  margin-top: 20px;

  & span {
    font-size: 17px;
  }
}

.notificationTitle {
  margin: 25px 0;

  & span {
    font-size: 25px;
  }
}

.checkoutHeading {
  text-align: center;
  margin: 0px 20px;
  width: 100%;
}

.checkoutInfoClass {
  font-size: 20px;
}