@import '../../../../marketplace.css';


.itemLink {
  text-decoration: none;
  color: var(--matterColorDark);
  &:hover {
    text-decoration: none;
  }
}

.enquireCardRoot{
    background: var(--matterColorLight);
    box-sizing: border-box;
    border-bottom: 1px solid #EDEDED;
   display: flex;
   justify-content: flex-start;
   padding-top: 20px;
}

.selectedEnquiriesCardRoot{
    border-radius: 12px;
    border: 1px solid var(--marketplaceColor);
}

.userAvatar{
    padding-left: 12px;
}


.pastTitle{
    color: var(--marketplaceColor);
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.pastMessage{
    font-size: 20px;
    font-weight: 800;
    padding-right: 17px;
    padding-bottom: 20px;
}

.pastGuest{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 9px;
 
}

.pastDay{
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #6E6F71;
    padding-right: 17px;
}
.pastName{
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    color: var(--matterColorDarkBlue);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    height: 19px;
    -webkit-box-orient: vertical;
}



.pastParagraph{
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: var(--matterColorAnti);
    margin: 0px;
    padding-left: 9px;
    padding-bottom: 12px;
    padding-top: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    height: 75px;
    -webkit-box-orient: vertical;
}
.otherUserinfoWrapper{
    display: flex;
    column-gap: 9px;
    align-items: center;
    @media (--viewportMedium) {
        column-gap: 35px;
    }
}

.avatarUser{
    height: 49px;
    width: 49px;
    background: var(--matterColorLight) ;
    border: 1px solid #E7E7E7;
    padding: 4px;

    @media (--viewportMedium) {
        height:49px;
        width:49px;
      }
      
    & span{
      font-size: 24px;
    }
  
}

.selectAvatarUser{
    border: 1px solid var(--marketplaceColor);
    background: var(--matterColorLight);
}

/* .otherUserinfoWrapper .userAvatar{

} */
.enquireCardRoot .otherUserinfoWrapper .userInfo{
    display: flex;
    flex-direction: column;
}
.enquireCardRoot .otherUserinfoWrapper .userInfo .userName{
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: var(--matterColorDark);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.enquireCardRoot .otherUserinfoWrapper .userInfo .userMessageTime{
    font-weight: 600;
    font-size: 10px;
    line-height: 13px;
    color: #9E9E9E;
}
.enquireCardRoot .listingName{
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: var(--matterColorDark);
    margin: 5px 0 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    @media (--viewportTabletDown){
        margin:9px 0 6px; 
    }
}
.enquireCardRoot .messageContent{
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: var(--matterColorDark);
    margin-bottom: 55px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    @media (--viewportTabletDown){
        margin-bottom:20px;
    }
}
.enquireCardRoot .messageButton{
    background: #001F3F;
    border-radius: 3px;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: var(--matterColorLight);
    padding: 8px 25px;
    width: 100%;
    min-height: 36px;
}

.rowNotificationDot {
    width: 6px;
    height: 6px;
    margin-top: 10px;
    margin-right: 8px;

    @media (--viewportLarge) {
        margin-right: 13px;
        width: 9px;
        height: 9px;
    }
}
  
.notificationDot {
    color: var(--matterColorLight);

    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: var(--failColor);
}

.authorInfo{
  width: 100%;
}