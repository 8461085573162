.container {
  display: flex;
  justify-content: center;
  padding: 65px;

  @media (--viewportMobile) {
    padding: 50px 0;
  }
}

.contentWrapper {
  max-width: 1501px;
  margin: 0 120px;

  @media (--viewportLaptopDown) {
    margin: 0 50px;
  }

  @media (--viewportMobile) {
    margin: 0 30px;
  }
}

.section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin: 40px 20px;

  @media (--viewportLaptopDown) {
    display: flex;
    flex-direction: column;
  }
}

.imageContainer {
  display: flex;
  width: 100%;
  & img {
    object-fit: contain;
  }
}

.processDescription {
  display: flex;
  flex-direction: column;
  align-items: center;

  & h2 {
    text-transform: uppercase;
    min-width: 320px;
  }

  & ul {
    list-style-type: disc;
    min-width: 260px;
  }
}
