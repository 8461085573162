@import '../../marketplace.css';

.listingCards {
  /* flex-direction: row;
  flex-wrap: wrap; */
  padding-bottom: 25px;
  margin: 35px 0 75px 0;
  @media (--viewportMobile){
    flex-wrap: nowrap;
    margin: 35px 0 0 0;
  }
}
.subHeading{
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: var(--matterColorDimmedText) !important;
  margin: 0 !important;
  @media (--viewportMobile){
    font-size: 16px !important;
    line-height: 20px !important;
  }
}

.ourProsHeading{
  font-weight: 600;
  font-size: 25px;
  line-height: 44px;
  text-transform: capitalize;
  color: var(--matterColor);
  position: relative;
  margin: 0;
  
  & span{
    background: #fff;
    z-index: 1;
    width: fit-content;
    padding: 0 20px 0 0;
  }
  @media (--viewportMobile){
    font-size: 25px;
    line-height: 31px;
    margin: 44px 0 0 0 !important;
  }
}
.ourProsHeading::after{
  content: '';
  height: 1.5px;
  width: 100%;
  background: var(--matterColorNegative);
  top: 50%;
  left: 0;
  position: absolute;
  z-index: -1;
  /* @media (--viewportMobile){
    display: none;
  } */
}

.promotedPersonSessionsContainer {
  flex-direction: column;
  & .firstHalfWrapper{
    display: flex;
  }
  & .secondHalfWrapper{
    display: flex;
  }
}