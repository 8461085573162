@import '../../marketplace.css';

.itemWrapper {
  padding: 15px 40px;
  width: 100%;
  font-size: 18px;
  border: 1px solid var(--marketplaceOffWhiteColor);
  border-radius: 10px;
  box-shadow: 5px 5px 5px var(--marketplaceOffWhiteColor);

  @media (--viewportTabletDown) {
    padding: 15px 20px;
  }
}

.actionsWrapper {
  display: flex;
  justify-content: space-between;
  
  @media (--viewportTabletDown) {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.titleWrapper {
  display: flex;
  flex-direction: column;
  margin: 5px;
}

.buttonsWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 0;
  gap: 10px;

  @media (--viewportTabletDown) {
    width: 100%;
  }
}

.actionBtnClass {
  border-radius: 15px;
  padding: 0 50px;
  margin: 10px;
  min-width: 120px;
  cursor: pointer;
}

.declineBtn:enabled {
  color: var(--marketplaceRedDarkColor);
  border: 1px solid var(--marketplaceRedDarkColor);
  
  &:hover {
    color: var(--marketplaceRedDarkColor);
    border: 1px solid var(--marketplaceRedDarkColor);
  }
}

.acceptBtn:enabled {
  color: var(--successColor);
  border: 1px solid var(--successColor);
  
  &:hover {
    color: var(--successColor);
    border: 1px solid var(--successColor);
  }
}

.nameWrapper {
  display: flex;
  gap: 10px;
  font-weight: 600;
  margin: 3px;
}

.linkIconClass {
  position: relative;
  bottom: 2px;
}

.idWrapper {
  color: var(--matterColorDimmedText);
  margin: 5px;
}

.detailsWrapper {
  font-size: 20px;
  margin: 5px;
  display: flex;
  justify-content: space-between;

  @media (--viewportTabletDown) {
    justify-content: center;
  }
}

.statusWrapper {
  display: flex;
  flex-direction: row;

  & > div {
    margin: 5px;
    font-size: 18px;
  }
}

.reqStatusWrapper {
  margin: 5px;
  color: var(--attentionColor);
}

.approvedStatus {
  color: var(--successColor);
}

.declinedStatus {
  color: var(--marketplaceRedDarkColor);
}

.dateWrapper {
  color: var(--matterColorDimmedText);
}