@import '../../marketplace.css';

.pageTitle {
  text-align: center;
}

.pageWrapper {
  display: flex;
  justify-content: center;
}

.staticPageWrapper {
  max-width: 1501px;
  margin: 20px 120px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;

  @media (max-width: 991px) and (--viewportSmall) {
    margin: 20px 50px;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
  }

  @media (--viewportMobile) {
    flex-direction: column;
    justify-content: center;
    gap: 30px;
    margin: 20px 30px
  }
}

.sectionInfo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.checkIcon {
  margin-right: 10px;
}

.topSpan {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: #272727;
  margin-bottom: 1rem;

  @media (max-width: 991px) {
    font-size: 16px;
    line-height: 30px;
  }
}

.sectionImagesContainer {
  /* width: 80%; */
  width: 100%;
  align-self: center;
}

.sectionImagesContainer img {
  width: 100%;
  height: 20rem;
  object-fit: contain;

  @media (--viewportMobile) {
    display: none;
  }
}

.laptopImage {
  width: 568px;
  height: 426px;

  @media (max-width: 991px) {
    width: 100%;
    height: 233px;
    object-fit: contain;
  }
}


.sectionForm {
  display: flex;
  /* flex-direction: column; */
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 0.1rem 6px rgb(193, 193, 193);
  padding: 15px;

  @media (--viewportTabletDown) and (--viewportSmall) {
    padding: 15px;
  }

  @media (--viewportMobile) {
    width: 100%;
    padding: 10px;
  }
}


.tripExpertTitle {
  border-bottom: 1px solid rgb(210, 210, 210);
  width: 100%;
  margin-bottom: 20px;
}

.travelBusinessTitle {
  width: 100%;
}

.form {
  width: 100%;

  &>p {
    max-width: 600px;
  }
}

.submit {
  border-radius: 20px;
  margin-top: 20px;
}

.nextActionWrapper {
  border: 2px solid var(--marketplaceRedDarkColor);
  padding: 0 15px;

  & i, p span {
    line-height: 32px;
    font-size: 20px;
  }
}