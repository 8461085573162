@import '../../marketplace.css';

.title {
  color: #0d1624;
  @apply --marketplaceH1FontStyles;
  font-weight: 700;
  font-size: 36px;
  line-height: 49px;

  @media (max-width: 991px) {
    margin-bottom: 5px;
    text-align: center;
  }

  @media (--viewportMobile) {
    font-size: 28px;
    text-align: center;
  }
}

.description {
  @apply --marketplaceH1FontStyles;
  font-weight: 600;
  font-size: 32px;
  line-height: 44px;
  text-transform: capitalize;
  color: #6e6f71;
  text-align: left;
  margin-bottom: 50px;

  @media (max-width: 991px) {
    margin-bottom: 23px;
    text-align: center;
  }

  @media (--viewportMobile) {
    font-size: 20px;
    text-align: center;
  }

}

.ListWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0 10px 0
}

.DetailsContainer {
  display: flex;
  flex-direction: column;
  padding: 0 50px;
  flex: 1;
  align-self: center;
}

.DetailsContainer span {
  font-weight: 400;
  font-size: 24px;
  line-height: 48px;
  color: #6e6f71;

  @media (--viewportMobile) {
    font-size: 18px;
    line-height: 30px;
  }
}

.DetailsOptionalContainer h6 span {
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  text-transform: capitalize;
  color: var(--marketplaceRedDarkColor);

  @media (--viewportMobile) {
    font-size: 20px;
  }
}

.DetailsOptionalContainer span {
  font-weight: 600;
  font-size: 32px;
  line-height: 44px;
  text-transform: capitalize;
  color: #6e6f71;

  @media (--viewportMobile) {
    font-size: 20px;
  }
}

.sectionContentWrapper {
  display: flex;
  gap: 20px;

  @media (--viewportLaptopDown) {
    flex-direction: column;
  }
}

.sectionClass {
  padding: 20px;
  border-radius: 10px;
  background: rgba(235, 235, 235, 0.5);

  & h3 {
    text-align: center;
    font-size: 31px;
    line-height: 33px;

    @media (--viewportMobile) {
      font-size: 26px;
      line-height: 28px;
    }
  }

  & .normalTextClass {
    font-weight: normal;
    font-size: 20px;

    @media (--viewportMobile) {
      font-size: 16px;
    }
  }

  &:first-child {
    flex: 1.5;
  }
  &:nth-child(2) {
    flex: 1
  }
}

.titleContainer {
  height: 60px;
}

dl {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0;
}

dt {
  margin: 10px 0;
}

dt span {
  font-weight: 700;
  font-size: 21px;
  line-height: 30px;
  color: #6e6f71;
}


.threeColumnsContainer {
  grid-template-columns: auto;
  gap: 0;
  
  @media (--viewportLaptop) {
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (min-width: 1700px) {
    grid-template-columns: 0.7fr 0.6fr 1.2fr;
  }

  & dd {
    margin: 0 0 30px 0;
  }
}
.twoColumnsContainer {
  grid-template-columns: auto;
  gap: 0;

  @media (--viewportLaptop) {
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 1fr;
  }

  & dd {
    margin: 0 0 20px 0;
  }
}

.startFigureClass {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 0 5px 0 -15px;

  & img {
    object-fit: contain;
  }
}

.endFigureClass {
  display: none;
  
  @media (--viewportLaptop) {
    display: flex;
    justify-content: end;
  }
  
  & img {
    object-fit: contain;
  }
}

.optionalImageContainerClass {
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 1700px) {
    grid-template-columns: auto;
  }
}

.mobileFigureClass {
  display: flex;
  justify-content: center;
  align-items: center;

  @media (--viewportLaptop) {
    display: none;
  }
}

.optionalFigureClass {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: 0;

  & img {
    object-fit: contain;
  }

  @media (max-width: 1700px) {
    display: none;
  }
}