@import '../../marketplace.css';

.pageTitle {
  text-align: center;
}

.staticPageWrapper {
  width: calc(100% - 48px);
  max-width: 1256px;
  margin: 24px auto;

  @media (--viewportMedium) {
    width: calc(100% - 72px);
    margin: 72px auto;
    margin-top: 20px;
  }
}

.contentContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
  margin-top: 20px;

  & > * {
    margin: 5px;
  }
}

.row{
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgb(203, 203, 203);
}

.actionButton{
  border-radius: 8px;
  max-width: 80px;
  min-height: 50px;
}

.infoIcon{
  cursor: pointer;
  &:hover{
    transform: scale(1.06);
    filter: invert(65%) sepia(97%) saturate(1662%) hue-rotate(174deg) brightness(100%) contrast(102%);
  }
}

.txState{
  text-transform: capitalize;
}

.modalContent{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}

.checkIcon{
  color: var(--successColor);
}

.clearIcon{
  color: var(--failColor);
}