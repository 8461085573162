@import '../../marketplace.css';

.root {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 1501px;
  margin: 0 120px;

  @media (--viewportLarge) {
    padding: 0;
    border-top: none;
  }

  @media (--viewportMobile) {
    padding: 0 31px 100px;
  }
}

/* Wrapper to limit the top border in desktop
 * view from the outermost paddings*/
.topBorderWrapper {
  align-self: center;
  width: 100%;

  @media (--viewportMedium) {
    padding-top: 41px;
  }

  @media (--viewportLarge) {
    padding-top: 73px;
  }
  @media (max-width: 900px) {
    padding: 0;
  }
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (--viewportMedium) {
    margin: 0 auto;
  }

  @media (--viewportLarge) {
    max-width: 1501px;
    padding: 0;
  }

  @media (max-width: 1440px) {
    max-width: 100%;
  }
}

/* Mobile only social media links */
.someLiksMobile {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    display: none;
  }
}

.icon {
  text-align: center;
  box-sizing: border-box;
  min-width: 36px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: rgba(230, 230, 230, 0.16);
  vertical-align: middle;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: 0.1s ease-in-out;
  transition-property: color, background-color, border-color, box-shadow;

  @media (--viewportMobile) {
    transform: scale(1.2);

    &:first-child {
      /* margin-right: 50px; */
    }
  }
}

/* Footer links */
.links {
  /* Layout */
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 18px;

  @media (--viewportLaptopDown) {
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

/* Desktop: logo and organization stuff */
.organization {
  flex-shrink: 0;
  /* flex-basis: 220px; */
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    display: block;
    /* order: 1; */
    margin-right: 12px;
  }

  @media (--viewportMobile) {
    flex: 1;
    text-align: center;
    margin: 0;
  }

  @media (--viewportLarge) {
    /* flex-basis: 320px; */
    margin-right: 12px;
  }

  /* @media (--viewportLargeWithPaddings) {
    flex-basis: 270px;
    margin-right: 157px;
  } */

  @media (max-width: 900px) {
    margin: 0;
    /* padding: 0; */
    padding: 25px 0;
  }
}

.logoLink {
  display: block;
  margin-bottom: 13px;

  @media (--viewportMobile) {
    margin-top: 34px;
    margin-bottom: 23px;
    display: flex;
    justify-content: center;
  }
}

.logo {
  object-fit: contain;
  object-position: left center;
  width: 100%;
  height: 48px;

  @media (--viewportMobile) {
    object-position: center;
    width: 255px;
    height: 60px;
  }
}

.organizationInfo {
  @media (--viewportTabletDown) {
    display: none;
  }
}

.organizationDescription {
  /* @apply --marketplaceTinyFontStyles; */
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 240% */
  color: var(--matterColorLight);

  @media (--viewportMobile) {
    font-weight: normal;
    font-size: 20px;
    line-height: 25px;

    text-align: center;
  }
}

.someSocialMediaLinks {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 20px;
}

.organizationCopyright {
  /* @apply --marketplaceTinyFontStyles;*/
  margin-top: 39px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  /* or 240% */
  color: var(--matterColorLight);

  @media (--viewportMobile) {
    margin-top: 30px;
    font-weight: normal;
    font-size: 20px;
    line-height: 25px;

    text-align: center;
  }
}

.copyrightLink {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: right;
  color: #ffffff;
  transition: var(--transitionStyleButton);

  @media (--viewportMobile) {
    text-align: center;
  }
}

.copyrightLink:hover {
  color: var(--marketplaceColor);
  text-decoration: none;
}

.copyrightLink > * {
  display: block;
}

/* Desktop and Mobile: links to more information */
.firstSectionList {
  padding-left: 40px;

  @media (max-width: 900px) {
    width: 100%;
    text-align: center;
    padding-left: 0 !important;
  }
}
.infoLinks {
  order: 2;
  flex-basis: 180px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }

  @media (--viewportLaptopDown) {
    /* flex-basis: 50%;
    margin: 73px 0 0 0; */
    display: none;
  }

  /* @media (max-width: 900px) {
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  } */
}

.list {
  margin-top: 0;
  margin-bottom: 0;
  @media (max-width: 900px) {
    width: 100%;
    text-align: center;
  }
}

.listItem {
  min-height: 24px;
  margin-bottom: 6px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
  @media (max-width: 900px) {
    width: 100%;
    text-align: center;
  }
}

.collapseItem {
  color: white;
  font-size: 20px;
  font-weight: 600;
  line-height: 50px;
}

.link,
.firstLink {
  /* Font
  @apply --marketplaceH4FontStyles;
  line-height: 20px;
  color: var(--matterColorLight);
  transition: var(--transitionStyleButton);
  margin-top: 0;
  margin-bottom: 12px; */
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 42px;
  /* or 240% */
  color: var(--matterColorLight);

  &:first-child {
    margin-bottom: 20px;
  }

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (--viewportMobile) {
    font-weight: 600;
    font-size: 20px;
    line-height: 50px;
  }
}
.subLink a{
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 30px;
  /* or 240% */
  /* color: var(--matterColorLight); */
  color: #ffffff;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

.marketPlaceLink {
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 29px;
  /* or 240% */
  color: var(--matterColorLight);

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (--viewportMobile) {
    font-weight: 600;
    font-size: 20px;
    line-height: 50px;
  }
}

.firstLink {
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 29px;
}

/* Desktop and Mobile: searches - first column */
.searches {
  order: 3;
  flex-basis: 165px;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding-top: 2px;
    margin-right: 12px;
  }

  @media (--viewportMobile) {
    flex-basis: 40%;
    margin: 73px 0 0 0;
  }
}

/* Desktop and Mobile: searches - second column */
/* This is not always visible */
.searchesExtra {
  order: 4;
  flex-basis: 165px;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding-top: 2px;
    margin-right: 12px;
  }

  @media (--viewportMobile) {
    flex-basis: 50%;
    margin: 89px 0 0 0;
  }
}

/* Desktop: extra links (social media links and legal links) */
.extraLinks {
  /* flex-shrink: 1; */
  order: 5;
  /* flex-basis: 200px; */
  padding-top: 2px;
  min-width: 250px;

  & svg {
    width: 28px;
    height: 23px;

    @media (--viewportMobile) {
      width: 40px;
      height: 40px;
    }
  }

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    margin-right: 12px;
  }

  @media (--viewportLarge) {
    margin-right: 0;
  }

  @media (--viewportMobile) {
    flex-basis: auto;
    /* margin: 101px 0 0 0; */
    margin: 0;
  }
}

.legalMatters {
  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.tosAndPrivacy {
  margin-top: 25px;
  margin-bottom: 0;
  display: grid;
  flex-direction: column;
  justify-content: center;
}
.tosAndPrivacy img {
  margin-bottom: 30px;
  height: 40px;
}
.legalLink,
.privacy,
.terms,
.contact {
  /* Font */
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 42px;
  color: #ffffff;
  transition: var(--transitionStyleButton);
  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }

  @media (--viewportMobile) {
    font-weight: 600;
    font-size: 20px;
    line-height: 50px;
    /* or 250% */
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

/* Mobile: copyright mark and legal links for mobile */
.copyrightAndTermsMobile {
  /* Layout */
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  @media (--viewportMobile) {
    display: none;
  }
}

.organizationCopyrightMobile {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  transition: var(--transitionStyleButton);

  /* Tell parent component how much space this one prefers */
  flex-basis: 172px;

  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }

  & > * {
    display: block;
  }
}

.tosAndPrivacyMobile {
  flex-basis: 172px;
}

.privacy {
  margin-right: 24px;
}

.dropdownContent {
  width: 100%;
  padding: 0;
  margin: 0 auto 100px;
  z-index: -1;
}

.centerplease {
  margin: 0 auto;
  max-width: 270px;
  font-size: 40px;
}

/*Question*/
.question {
  position: relative;
  background: var(--matterColorBright);
  margin: 0;
  padding: 10px 10px 10px 50px;
  display: block;
  width: 100%;
  cursor: pointer;
}

/*Answer*/
.answers {
  width: 100% !important;
  height: 0;
  overflow: hidden;
  z-index: -1;
  position: relative;
  opacity: 0;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
}

.questions:checked ~ .answers {
  height: auto;
  opacity: 1;
}

/*FAQ Toggle*/
.plus {
  position: absolute;
  margin-left: 10px;
  margin-top: 4px;
  z-index: 5;
  font-size: 2em;
  line-height: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
}

.questions:checked ~ .plus {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.questions {
  display: none;
}

.middleBorderWrapper {
  opacity: 0.2;
  margin: 0;
}

.bottomBorderWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  max-width: 1501px;
  padding: 35px 80px;
  @media (--viewportLaptopDown) {
    padding: 35px 20px;
    text-align: center;
    justify-content: center;
  }
}

.appWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.isoAppIcon {
  margin-right: 20px;
}

.footerLocation {
  text-align: center;
  margin-top: 20px;
  display: flex;
  justify-content: center;

  @media (--viewportLarge) {
    display: none;
  }
}

.footerLink {
  text-align: center;
  @media (--viewportLarge) {
    display: none;
  }
}
