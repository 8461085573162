@import '../../marketplace.css';

.robotImageContainer {
    max-width: 550px;

    & img {
        height: 62px;
        width: 62px;
        margin: 0 12px 12px 0;

        @media (--viewportMobile) {
            margin: 0 10px 10px 0;
        }
    }
}

.addAvatarSection {
    display: flex;
    justify-content: space-between;
    column-gap: 40px;

    @media (--viewportMobile) {
        display: block;
    }
}

.mainContainer {
    display: flex;
    margin: 35px 0 140px;

    @media (--viewportMobile) {
        display: block;
        margin-top: 0;
    }
}

.leftContainer {
    width: 40%;

    @media (--viewportMobile) {
        width: 100%;
    }
}

.leftContainer img {
    width: 100%;
}

.rightContainer {
    max-width: 730px;
    width: 100%;
    margin-left: 70px;

    @media (--viewportMobile) {
        margin-left: 0;
        padding: 22px;
    }
}

.heading h2 span {
    font-weight: bold;
    font-size: 50px;
    line-height: 63px;
    text-transform: capitalize;
    color: var(--marketplaceColorDarkest);
    margin: 0;

    @media (--viewportMobile) {
        font-size: 28px;
        line-height: 35px;
    }
}

.heading span {
    font-weight: normal;
    font-size: 23px;
    line-height: 29px;
    color: #6E6F71;
    margin: 16px 0 49px;

    @media (--viewportMobile) {
        font-size: 15px;
        line-height: 19px;
        margin: 8px 0 44px;
    }
}

.addAvatarTitle {
    font-weight: bold;
    font-size: 22px;
    line-height: 28px;
    color: var(--marketplaceColorDarkest);
    margin-bottom: 19px;

    @media (--viewportMobile) {
        font-size: 12px;
        line-height: 15px;
        margin-bottom: 24px;
    }
}

.chooseSection {
    min-height: 620px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (--viewportMobile) {
        min-height: 540px;
    }
}

.chooseSection span {
    padding: 20px 0;
    font-weight: normal;
    font-size: 23px;
    line-height: 29px;
    color: #6E6F71;

    @media (--viewportMobile) {
        font-size: 20px;
        line-height: 20px;
        padding: 8px 0;
    }
}

.chooseSection [type="file"] {
    height: 0;
    overflow: hidden;
    width: 0;
}

.chooseSection [type="file"]+label {
    width: 255px;
    height: 69px;
    background: var(--matterColorLight);
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
    border-radius: 9px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    position: relative;
    transition: all 0.3s;
    vertical-align: middle;

    & span {
        font-weight: 600;
        font-size: 20px;
        line-height: 25px;
        text-align: center;
        text-transform: capitalize;
        color: var(--marketplaceColorDarkest);
    }

    @media (--viewportMobile) {
        width: 100%;
        margin-bottom: 0;
    }

}

.chooseLeftSection [type="file"] {
    height: 0;
    overflow: hidden;
    width: 0;
}

.chooseLeftSection [type="file"]+label {
    width: 243px;
    height: 243px;
    border-radius: 50%;
    border: 2px dashed var(--marketplaceColor);
    background: #F3FDFF;
    transition: ease-in-out 0.1s;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (--viewportMobile) {
        margin: 0 auto;
    }

}

.spinner {
    width: 100%;
    margin-top: 20%;
    height: 28px;
    stroke: var(--marketplaceColor);
    stroke-width: 3px;
}

.error {
    color: var(--failColor);
    margin: 28px 0 0;
    font-size: 20px;
}

.avatar {
    width: 100%;
    height: 100%;

    & img {
        border-radius: 50%;
    }
}

.removedBorder {
    border: none !important;
}

.nextButton {
    width: 255px;
    height: 69px;
    background: var(--marketplaceColor);
    border: 1px solid var(--marketplaceColor);
    box-sizing: border-box;
    border-radius: 9px;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    text-transform: capitalize;
    color: white;
    margin: 0 0 6px;

    & span {
        color: white;
    }

    @media (--viewportMobile) {
        width: 100%;
        margin: 40px 0 8px;
    }
}

.nextButton:hover {
    background: var(--marketplaceColor);
}

.nextButton:disabled {
    background: rgba(0, 149, 179, 0.36);
    border: 1px solid rgba(0, 149, 179, 0.6);

    @media (--viewportMobile) {
        background: rgba(0, 149, 179, 0.35);
        border: 1px solid #DDDDDD;
    }
}

.phoneNumberContainer {
    padding: 0 22px;

    @media (--viewportMedium) {
        padding: 24px 0 0 66px;
    }

    & label {
        font-weight: bold;
        font-size: 30px;
        line-height: 38px;
        text-transform: capitalize;
        color: #6E6F71;

        margin-top: 0;
        margin-bottom: 0;
        padding-top: 6px;
        letter-spacing: 0;
        padding-bottom: 2px;
    }

    & .contactNumberInfo {
        font-size: 14px;
        color: grey;
        line-height: 18px;
    }

    & .PhoneInputWrapper {
        display: flex;
        column-gap: 20px;
        align-items: flex-start;

        @media (--viewportMobile) {
            width: 100%;
        }

        & .leftInput {
            width: 30%;
        }

        & .rightInput {
            width: 70%;
        }
    }
}

.countryCode {
    display: block;
    margin: 0;
    padding: 5px 0;
    border: none;
    border-bottom: 2px solid var(--marketplaceColor);
    border-radius: 0;
    font-size: 16px;
    line-height: 24px;

    @media (--viewportMedium) {
        font-size: 20px;
    }
}

.inputSuccess {
    border-bottom-color: var(--successColor) !important;
}

.inputError {
    border-bottom-color: var(--failColor) !important;
}

.phoneNumber {
    display: block;
    margin: 0;
    padding: 5px 0;
    border: none;
    border-bottom: 2px solid var(--marketplaceColor);
    border-radius: 0;
    font-size: 16px;
    line-height: 24px;

    @media (--viewportMedium) {
        font-size: 20px;
    }
}

.error {
    color: var(--failColor) !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}