@import '../../marketplace.css';

.staticPageWrapper {
  width: 100%;
  padding: 24px 80px;
  background: #eee;

  @media (--viewportMobile) {
    padding: 24px 15px;
  }
}
