@import '../../../../../marketplace.css';

.wrapper {
  position: relative;
  height: 45px;
}

.badgeWrapperClass {
  height: 100%;
}

.filterLabel {
  border: 1px solid rgb(159, 159, 159, 0.5);
  border-radius: 0.5rem;
  font-size: 16px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 20px;
  cursor: pointer;

  &:hover {
    background-color: #ebeef1;
  }
}

.filterLabelSelected {
  border: 1px solid #1e335a;
  border-radius: 0.5rem;
  font-size: 16px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 20px;
  cursor: pointer;

  &:hover {
    background-color: #ebeef1;
  }
}

.popupWrapper {
  min-width: 100%;
  width: 450px;
  min-height: 100px;
  background: #ffffff;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  box-shadow: 0 0 64px rgba(0, 0, 0, .08), 0 8px 16px rgba(0, 0, 0, .16);
  border: 1px solid #dcdfe4;
  border-radius: 16px;
  padding: 15px 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;
  top: 56px;
  z-index: 99;
}

.sliderWrapper {
  width: 100%;
}

.popupLabel {
  font-size: 16px;
  line-height: 20px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
}

.rangeValue {
  color: var(--marketplaceColor);
}

.selectedLabel {
  color: black;
}

.disableSelect {
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard syntax */
}