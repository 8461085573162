@import '../../../marketplace.css';

.stateBtnsWtap {
  display: flex;
  & div{
    height: 100%;
  }
  @media (--viewportMobile) {
    flex-wrap: wrap;
  }
}

.loader {
  display: block;
  margin: 24px auto;
}
.bookingsContent {
  margin: 12px 0;
}
.bookingsList {
  margin-top: 0;
  list-style: none;
  & > li {
    display: flex;
    align-items: center;
    padding: 15px 0;
    border-bottom: 1px solid var(--matterColorNegative);
  }
}
