.ListingDetailsMainTabConatiner {
  max-width: 1206px;
  margin: 0 auto;
  padding: 5rem;

  @media (--viewportMobile) {
    padding: 1rem;
  }
}

.listingTravelAgentServiceWrapper h2 {
  font-weight: 700;
  font-size: 28px;
  line-height: 38px;
  color: #233045;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}

.Listingtext {
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 24px;
    color: #6e6f71;
  }
