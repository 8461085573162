@import '../../marketplace.css';

.root {
  width: 100%;
  aspect-ratio: 19 / 15;
  background-color: var(--matterColorNegative);
}

.mapRoot {
  width: 100%;
  height: 100%;
}
