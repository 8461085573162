@import '../../marketplace.css';

.root {
}

.input {
  border-bottom-color: var(--attentionColor);
}

.inputSuccess {
  border-bottom-color: var(--marketplaceColor);
}

.inputError {
  border-bottom-color: var(--failColor);
}

.textarea {
  border: 2px solid var(--attentionColor) !important;
}
.textareaError{
  border: 2px solid var(--failColor) !important;
}

.validTextarea{
  border: 2px solid #60b57d !important;
}

.extraLabel {
  font-size: 14px;
  color: gray;
  line-height: 18px;
}

.headingWrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
  & svg{
    fill: transparent;
  }
}

.support{
  position: relative;
  display: flex;
  justify-content: flex-end;
  min-width: 70%;
}

.support .supportText {
  font-size: 16px;
  line-height: 24px;
  font-size: 16px;
  line-height: 24px;
  background-color: var(--matterColorDark);
  color: var(--matterColorLight);
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  margin: 0 21px;

  /* Position the tooltip */
  position: absolute;
  top: 0px;
  right: 9px;
  z-index: 1;
}

.hideSupportText{
  visibility:hidden;
}