@import '../../marketplace.css';


.itemLink {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

.enquireCardRoot{
    width: 675px;
    border-left:20px solid #B3CEEA;
    border-radius: 5px 0px 0px 5px;
    padding: 5px 50px 12px;
    @media (--viewportTabletDown) {
        width:100% ;
        padding: 11px 24px 12px;
        border-left:10px solid #B3CEEA;
    }
}

.otherUserinfoWrapper{
    display: flex;
    column-gap: 9px;
    align-items: center;
    @media (--viewportMedium) {
        column-gap: 35px;
    }
}

.avatarUser{
    height: 26px;
    width: 26px;
    background: #C4C4C4 ;
    & span{
      font-size: 24px;
    }
  
    @media (--viewportMedium) {
      height:38px;
      width:38px;
    }
}

/* .otherUserinfoWrapper .userAvatar{

} */
.enquireCardRoot .otherUserinfoWrapper .userInfo{
    display: flex;
    flex-direction: column;
}
.enquireCardRoot .otherUserinfoWrapper .userInfo .userName{
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: var(--matterColorDark);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.enquireCardRoot .otherUserinfoWrapper .userInfo .userMessageTime{
    font-weight: 600;
    font-size: 10px;
    line-height: 13px;
    color: #9E9E9E;
}
.enquireCardRoot .listingName{
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: var(--matterColorDark);
    margin: 5px 0 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    @media (--viewportTabletDown){
        margin:9px 0 6px; 
    }
}
.enquireCardRoot .messageContent{
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: var(--matterColorDark);
    margin-bottom: 55px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    @media (--viewportTabletDown){
        margin-bottom:20px;
    }
}
.enquireCardRoot .messageButton{
    background: #001F3F;
    border-radius: 3px;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: var(--matterColorLight);
    padding: 8px 25px;
    width: 100%;
    min-height: 36px;
}

.rowNotificationDot {
    width: 6px;
    height: 6px;
    margin-top: 10px;
    margin-right: 8px;

    @media (--viewportLarge) {
        margin-right: 13px;
        width: 9px;
        height: 9px;
    }
}
  
.notificationDot {
    color: var(--matterColorLight);

    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: var(--failColor);
}