@import '../../marketplace.css';

.error {
  margin-top: 5px;

  @media (--viewportMedium) {
    margin-top: 4px;
  }

  @media (--viewportLarge) {
    margin-top: 3px;
  }
}

.error {
  color: var(--failColor);
}

.contentContainer {
  margin: -6px;
  width: 100%;

  @media (--viewportLarge) {
    padding: 0 24px;
    display: flex;
    position: relative;
    max-width: 1002px;
    margin: 24px auto 117px;
  }

  @media (--viewportXLarge) {
    max-width: 1002px;
    padding: 0;
  }
}

.main {
  flex-basis: 100%;
  margin-bottom: 23px;
  padding: 0 24px;

  @media (--viewportMedium) {
    margin-bottom: 51px;
    flex-shrink: 0;
  }

  @media (--viewportLarge) {
    margin-bottom: 0px;
    /* contentContainer's full width - (bookingColumn + two paddings + margin + border) */
    max-width: calc(100% - 144px);
    flex-basis: calc(100% - 144px);
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.aside {
  width: 100%;
  padding: 0 24px;
  box-shadow: none;

  @media (--viewportLarge) {
    width: 192px;
    border-right: 1px solid var(--matterColorNegative);
  }
}

.asideContent {
  width: 100%;
  display: flex;
  margin-bottom: 30px;
  flex-direction: row;
  border-bottom: 1px solid var(--matterColorNegative);

  @media (--viewportLarge) {
    margin-bottom: 0;
    flex-direction: column;
    border-bottom: none;
  }
}

.avatar {
  margin: 30px 26px 29px 0;
  flex-shrink: 0;

  @media (--viewportLarge) {
    margin: 0 96px 44px 0;
  }
}

.mobileHeading {
  flex-shrink: 0;
  margin: 47px 0 0 0;

  @media (--viewportMedium) {
    margin: 49px 0 0 0;
  }

  @media (--viewportMobile) {
    flex-shrink: 2;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.editLinkMobile {
  margin-top: 17px;

  /* Pull the link to the end of the container */
  margin-left: auto;

  @media (--viewportMedium) {
    margin-top: 20px;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.editLinkDesktop {
  display: none;

  @media (--viewportLarge) {
    display: inline;
  }
}

.desktopHeading {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin: 2px 0 24px 0;
  }
}

.bio {
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
  margin: 5px 0 24px 0;

  @media (--viewportMedium) {
    margin: 4px 0 51px 0;
  }

  @media (--viewportLarge) {
    margin: 0 0 56px 0;
    max-width: 600px;
  }
}

.listingsContainer {
  @apply --clearfix;
  border-top: 1px solid var(--matterColorNegative);
  border-bottom: 1px solid var(--matterColorNegative);
  padding-bottom: 23px;

  @media (--viewportMedium) {
    padding-bottom: 22px;
  }
}

.listingsTitle {
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 48px;
    margin-bottom: 8px;
  }

  @media (--viewportLarge) {
    margin-top: 58px;
    margin-bottom: 8px;
  }
}

.withBioMissingAbove {
  /* Avoid extra borders in mobile when bio is missing */
  border-top: none;

  @media (--viewportLarge) {
    border-top: 1px solid var(--matterColorNegative);
  }
}

.listings {
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

.listing {
  width: 100%;

  /* Single column in mobile */
  margin-top: 34px;

  &:first-of-type {
    margin-top: 18px;
  }

  @media (--viewportMedium) {
    /* Two columns in desktop */
    width: calc(50% - 12px);
    margin-bottom: 36px;

    /* Horizontal space */
    &:nth-of-type(odd) {
      margin-right: 12px;
    }

    &:nth-of-type(even) {
      margin-left: 12px;
    }

    /* Vertical space */
    margin-top: 0;

    &:nth-of-type(1),
    &:nth-of-type(2) {
      margin-top: 20px;
    }
  }
}

.mobileReviewsTitle {
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);
  margin: 24px 0 26px 0;
}

.mobileReviews {
  margin-bottom: 36px;
}

.desktopReviews {
  margin-top: 28px;

  @media (--viewportMedium) {
    margin-bottom: 48px;
  }

  @media (--viewportLarge) {
    max-width: 600px;
    margin-bottom: 0;
  }
}

.desktopReviewsTitle {
  @apply --marketplaceH3FontStyles;
  margin-bottom: 0;

  @media (--viewportMobile) {
    font-size: 16px;
  }
}

.desktopReviewsTabNav {
  margin-bottom: 32px;
}

.listingCategories {
  padding: 0 0px 96px 0px;

  @media (--viewportLarge) {
    padding: 0 0px 96px 0px;
  }
}

.promoVideo {
  margin-bottom: 24px;
  padding: 0 11px;
  width: 100%;
}

.singleImageContainer {
  margin: 0 auto;
  padding: 24px 12px;
  height: 100vw;
  max-height: 800px;
  object-fit: cover;

  @media (--viewportMobile) {
    padding: 24px 5px;
  }
}

.image {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: var(--matterColorLight) url('../../assets/loader.gif') no-repeat center;

  &:after {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--matterColorLight) url('../../assets/loader.gif') no-repeat center;
    font-family: 'Helvetica';
    font-weight: 300;
    line-height: 2;
    text-align: center;
    content: attr(alt);
  }
}

.share {
  margin-bottom: 24px;
  margin-right: 24px;
  width: 180px;

  @media (--viewportMobile) {
    margin-right: 0;
  }
}

.enquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.enquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.enquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.contactLink {
  display: inline;
  width: 180px;
  min-width: 180px;
  min-height: 38px;
  height: 38px;
  margin: 0 12px 12px 0;
  background-color: var(--matterColorLight);
  color: var(--marketplaceColorDark);
  border-color: var(--marketplaceColorDark);
  font-weight: normal;
  font-size: 14px;

  @media (--viewportMobile) {
    margin-right: 0;
  }
}

.btnsWrap {
  display: flex;

  @media (--viewportMobile) {
    /* flex-direction: column;
    align-items: center; */
  }
}

.whatsapp {
  flex: 1.5;
  margin-left: 20px;
  font-size: 10px;

  & .whatsappButton {
    & svg {
      height: 35px;
      width: 35px;
    }
  }
}

.noReviewsWrapper {
  text-align: center;
  padding: 100px 0;
}