@import '../../marketplace.css';

.root {
  display: flex;
  flex-direction: column;

  /* Layout: size and positioning */
  width: 100%;
  height: auto;
  margin-top: 20px;
}

.error {
  color: var(--failColor);
}

.errorPlaceholder {
  @media (--viewportMedium) {
    width: 100%;
    height: 32px;
  }
}

.reviewRating {
  margin-bottom: 18px;

  @media (--viewportMedium) {
    margin-bottom: 16px;
  }
}

.reviewContent {
  flex-shrink: 0;
  /* margin-top: 24px; */
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-top: 0px;
  }
}

.buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 100px;
}

.submitButton {
  margin-bottom: 96px;
  width: unset;
  padding-left: 15px;
  padding-right: 15px;

  @media (--viewportMedium) {
    display: inline;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longSubmitButton {
    display: inline;
    margin-top: 0;
    width: 100%;
    margin-bottom: 0;
}
