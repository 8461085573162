@import '../../marketplace.css';

/* Booking form inside modalContainer needs special handling */
/* since "inMobile" breakpoint is actually --viewportLarge */
.modalContainer {
  @apply --marketplaceModalInMobileBaseStyles;

  height: 100vh;

  @media (--viewportMedium) {
    flex-basis: 576px;
    height: 100%;
    padding: var(--modalPaddingMedium);
    background-color: var(--matterColorLight);
    margin-top: 12.5vh;
    margin-bottom: 12.5vh;
  }

  @media (--viewportLarge) {
    padding: 0;
    background-color: var(--matterColorLight);
    margin-top: 0;
    margin-bottom: 0;
  }
  & label {
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: #707D83;
  }
}
.modalContainer :global(.FieldDateAndTimeInput_fieldSelect__1ksd9) {
  margin: 0;
}

.modalContainer :global(.FieldDateAndTimeInput_fieldSelect__1ksd9::after) {
  left: 0;
}

.modalContainer :global(.FieldDateAndTimeInput_field__1hzWK) {
  width: calc(50% - 12px);
}
.modalContainer :global(.FieldDateAndTimeInput_startDate__S29pM) {
  width: 100%;
}

.modalContainer :global(.FieldDateInput_mobileMargins__3WhP8) {
  @media (--viewportMobile) {
    margin: 0;
  }
}

.modalHeading {
  margin-top: 30px;
  margin-bottom: 36px;
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.title {
  /* Font */
  @apply --marketplaceH1FontStyles;
  color: var(--matterColor);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.author {
  width: 100%;
  @apply --marketplaceH4FontStyles;
  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--matterColor);
  }
}

.bookingHeading {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin-top: -2px;
    /* margin-bottom: 33px; */
  }
}
.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
  }
}

.desktopPriceValue {
  font-weight: 700;
  font-size: 30px;
  line-height: 41px;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.desktopPerUnit {
  /* Font */
  /* @apply --marketplaceH5FontStyles; */
  font-weight: 400;
  font-size: 23px;
  line-height: 31px;

  color: #B7B7B7;

  @media (--viewportMedium) {
    margin-left: 6px;
  }
}

.bookingTitle {
  font-weight: 700;
  font-size: 22.06px;
  line-height: 34px;
  color: var(--marketplaceColorDarkerI);
  margin-top: 33px;
  margin-bottom: 0px;
}

.bookingHelp {
  display: none;
  @apply --marketplaceH5FontStyles;

  @media (--viewportMedium) {
    color: var(--matterColor);
    display: block;
    margin-top: 0;
  }
}

.bookingForm {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  /* Mobile phones introduce bottom-bar, which takes 102px vertical space.
     In addition to that we have fixed button that takes 84px vertical space.
  */
  margin: 0 0 94px 0;

  @media (--viewportMedium) {
    margin: 0;
    min-height: 400px;
  }

  @media (--viewportMobile) {
    margin: 0 24px 154px;
  }

  @media (--viewportLarge) {
    min-width: 312px;
    min-height: auto;
  }
}

.submitButtonWrapper {
  flex-shrink: 0;
  padding: 0 24px 24px 24px;
  width: 100%;
  background-color: var(--matterColorLight);
  /* border-top: 1px solid var(--matterColorNegative); */
  & p {
    & [type='radio']:checked,
    & [type='radio']:not(:checked) {
      position: absolute;
      left: -9999px;
    }
    & [type='radio']:checked + label,
    & [type='radio']:not(:checked) + label {
      position: relative;
      cursor: pointer;
      display: inline-block;
      color: var(--matterColorLightGrey);
      padding: 0 40px !important;
    }
    & [type='radio']:checked + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0px;
      width: 22px;
      height: 22px;
      border: 3px solid var(--marketplaceColorBackground);
      border-radius: 100%;
      background: var(--matterColorLight);
    }

    & [type='radio']:not(:checked) + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0px;
      width: 22px;
      height: 22px;
      border: 3px solid var(--matterColorAnti);
      border-radius: 100%;
      background: var(--matterColorLight);
    }
    & [type='radio']:checked + label:after,
    & [type='radio']:not(:checked) + label:after {
      content: '';
      width: 22px;
      height: 22px;
      transform: scale(0.54) !important;
      background: var(--marketplaceColorBackground);
      position: absolute;
      top: 0;
      left: 0px;
      border-radius: 100%;
      transition: all 0.2s ease;
    }
    & [type='radio']:not(:checked) + label:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    & [type='radio']:checked + label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  @media (--viewportMedium) {
    padding: 0;
    width: 100%;
    position: static;
    bottom: unset;
    background-color: transparent;
  }
  @media (--viewportMobile) {
    padding: 0;
  }

  & label {
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
    letter-spacing: -0.1px;
    color: var(--matterColorGrey) !important;
  }
  & p {
    margin: 0;
    margin-top: 16px;
  }
}

.openBookingForm {
  /* Ensure that mobile button is over Footer too */
  z-index: 9;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 0 22px 14px;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowTop);
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  @media (--viewportMedium) {
    padding: 18px 60px 18px 60px;
  }
  & button {
    height: 59px;
    min-height: 59px;
    width: 200px;
    margin-top: 12px;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    text-transform: capitalize;
    color: #ffffff;
    background: var(--marketplaceColorBackground) !important;
  }
  @media (--viewportLarge) {
    display: none;
  }
}

.priceContainer {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  margin-right: 22px;
  padding: 5px 12px;
  @media (--viewportMobile) {
    padding: 0;
    margin-right: 0;
  }
}

.priceValue {
  /* Font */
  @apply --marketplaceH2FontStyles;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 0px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
  @media (--viewportMobile) {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
  }
}

.perUnit {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 0px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.bookButton {
  @apply --marketplaceButtonStylesPrimary;

  /* Clear padding that is set for link elements looking like buttons */
  padding: 0;
  @media (--viewportMobile) {
    min-width: 66px;
  }
}

.closedListingButton {
  border-left: 1px solid var(--matterColorNegative);
  width: 100%;
  padding: 15px 24px 15px 24px;
  text-align: center;
}

.stickyContainer {
  position: sticky;
  top: 120px;
  /* display: none; */
  @media (--viewportMobile) {
    position: relative;
    top: 0;
  }
  @media (--viewportLarge) {
    display: block;
  }
}

.openBookingFormLiftUp {
  padding: 0 22px 80px;
}
