@import '../../../../marketplace.css';

.buttons {
  display: flex;
  padding: 0 30px;
  justify-content: space-evenly;
  @media (--viewportMobile) {
    flex-direction: column;
    align-items: center;
    margin-top: 45px;
  }
}

.btn {
  margin-right: 16px;
  width: 190px;
  min-height: 45px;
  &:last-child {
    margin-right: 0;
  }
  @media (--viewportMobile) {
    margin-right: 0;
    margin-bottom: 12px;
  }
}
