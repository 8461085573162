.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 100px auto;
}

.subContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 300px;
}

.descriptionWrapper {
  font-size: 16px;
  text-align: center;
}

.cardWrapper {
  display: flex;
  justify-content: center;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}