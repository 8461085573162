@import '../../marketplace.css';

.root {}

.icon {
  display: none;

  @media (--viewportMedium) {
    display: block;
    margin-bottom: 36px;
  }
}

.heading {
  @apply --marketplaceModalTitleStyles;
  margin-bottom: 25px;
}

.field {
  margin: 0 0 24px 0;

  @media (--viewportMedium) {
    margin: 0 0 24px 0;
  }
}

.error {
  color: var(--failColor);
  margin-top: 0;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.breakdownWrapper {
  margin: 24px 0px;
}

.infoWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 16px 0;
}

.totalPrice {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 16px 0 0 0;
  padding: 20px 0 0 0;
  border-top: 1px solid black;
}

.referenceTotalPrice {
  text-align: right;
  color: var(--marketplaceRefCurrencyColor);
  font-style: italic;
}

.orderBreakdownTitle {
  /* Font */
  color: var(--matterColor);
  margin: 48px 0 0px;
  
  @media (--viewportLarge) {
    padding: 4px 0 4px 0;
  }
  
  & span {
    font-size: 20px;
  }
}

.infoText {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.packageHeading {
  font-size: 18px;
  margin-bottom: 20px;
  font-weight: 700;
  text-transform: uppercase;
}

.referenceText {
  font-weight: normal;
  font-size: 14px;
}