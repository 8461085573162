@import '../../../../../marketplace.css';

.wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 10px;
}

.label{
  margin-right: 15px;
  font-size: 16px;
}

.keywordsInputNotFilled{
  border: 1px solid rgb(159, 159, 159, 0.5);
  border-radius: 111px;
  font-size: 14px;
  padding: 0 0 0 10px;
  height: 45px;
  width: 180px;
  &:hover{
      border-bottom: 1px solid rgb(159, 159, 159, 0.5);
  }
}

.keywordsInputFilled{
    border: 1px solid rgb(159, 159, 159, 0.5);
    border-radius: 111px;
    font-size: 14px;
    padding: 0 10px 0 20px;
    height: 45px;
    width: 80%;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    &:hover{
        border-bottom: 1px solid rgb(159, 159, 159, 0.5);
    }
}

.applyButton{
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--marketplaceColor);
    border-bottom-right-radius: 111px;
    border-top-right-radius: 111px;
    padding: 0 5px;
    cursor: pointer;
    width: 50px;
    &:hover{
        background-color: var(--marketplaceColorLight);
    }
}

.searchIcon{
    color: white !important;
    & svg{
        color: white !important;
    }
}
