@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
    */
    /* Dimensions */
    width: 100%;
    height: auto;

    /* Layout */
    display: flex;
    flex: 1;
    flex-direction: column;

    padding-top: 1px;

    @media (--viewportMedium) {
      padding-top: 2px;
    }

  --EditListingDescriptionForm_formMargins: {
    margin-bottom: 24px;

    @media (--viewportMedium) {
      margin-bottom: 32px;
    }
  }
}

.error {
  color: var(--failColor);
}

.title,
.companyName {
  @apply --EditListingDescriptionForm_formMargins;
}

.description {
  @apply --EditListingDescriptionForm_formMargins;
  flex-shrink: 0;
}

.customField {
  @apply --EditListingDescriptionForm_formMargins;
}

.category {
  @apply --EditListingDescriptionForm_formMargins;
}

.subCategory {
  @apply --EditListingDescriptionForm_formMargins;
}

.providerName {
  @apply --EditListingDescriptionForm_formMargins;
}

.addCompanyNameNote {
  @apply --EditListingDescriptionForm_formMargins;
  color: var(--matterColorAnti);
}

.companyListing {
  @apply --EditListingDescriptionForm_formMargins;
}

.bankDetails {
  @apply --EditListingDescriptionForm_formMargins;
}

.submitButton {
  background-color: var(--marketplaceColor);
  color: white;
  border-radius: 8px;
  border: none;
  padding: 5px 15px;
  cursor: pointer;
  width: 200px;
  /* margin-right: 20px; */
  margin: 0 10px;
  cursor: pointer;
  &:hover{
    background-color: var(--marketplaceColorLight);
  }

  @media (--viewportMobile) {
    font-size: 14px;
    width: 100px;
  }
}

.submitButton:disabled {
  background-color: #C4C4C4;
  color: var(--marketplaceColorDarkerI);
}

.locationAutocompleteInput {
  flex-grow: 1;
  height: 60px;
  line-height: unset;
  padding-left: 0;
  margin: 0;
  margin-bottom: 0.5rem;
  border-bottom: 2px solid var(--attentionColor);

  &:hover,
  &:focus {
    border-bottom-color: var(--matterColorDark);
    outline: none;
  }

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  @media (--viewportMedium) {
    height: 40px;
  }
}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 36px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    top: 40px;
  }
}

.desktopPredictions {
  position: absolute;
  width: 100%;
  top: 54px;
  left: 0;
  box-shadow: var(--boxShadowPopup);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  background: var(--matterColorLight);
  border-radius: 16.8519px;
  z-index: 111;
  padding: 6px;
  max-width: 434px;

  & li div {
    color: var(--matterColorDark);
  }
}

.desktopPredictions :after {
  content: '';
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 11px solid var(--matterColorLight);
  position: absolute;
  top: -10px;
  left: 8%;
  transform: translateX(-50%);
}

.validLocation {
  border-bottom-color: var(--successColor);
}

.locationAddress,
.building {
  flex-shrink: 0;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.checkbox {
  margin: 1rem 0;
  align-items: flex-start !important;
}

.crossIcon {
  width: 12px;
  height: 12px;
}

.formButton {
  border-radius: 30px;
  min-height: 60px;
}

.checkboxLabel {
  font-size: 18px;
  line-height: 28px;
}

.paymentsInfo {
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-size: 15px;
  line-height: 30px;
  color: var(--marketplaceColorDarkerI);
}

.boldText {
  font-weight: 700;
}

.sectionTitle {
  margin: 1.5rem 0;
}

.paymentsButton {
  max-width: 30rem;
  padding: 1rem 2rem;
  color: #EE3E22;
  border: 2px solid #EE3E22;
  border-radius: 10px;
}


.pageRoot {
  display: flex;
  padding-bottom: 90px;
  @media (--viewportLarge) {
    padding-bottom: 0;
  }
  @media (--viewportMobile) {
    padding-bottom: 163px;
  }
}

.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--failColor);
  margin: 24px;
}

.sectionImages {
  width: 583px;

  @media (--viewportTabletDown) {
    margin: 0 auto;
  }
  @media (--viewportMobile) {
    width: 100%;
  }
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  position: relative; /* allow positioning own listing action bar */
  padding-bottom: 0; /* 3:2 Aspect Ratio */

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;
  background: url('../../assets/slider_bg.png');
  background-size: cover;
  background-position: center center;

  @media (--viewportMedium) {
    padding-bottom: 0; /* No fixed aspect on desktop layouts */
  }

  @media (--viewportMobile) {
    background: url('../../assets/slider_bg_mobile.png');
  }
}

.actionBar {
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 13px;
  left: 13px;
  width: calc(100% - 26px);
  color: var(--matterColorNegative);
  background-color: var(--marketplaceColorBackground);
  z-index: 5; /* bring on top of mobile image */

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;

  border-radius: var(--borderRadius);
}

.ownListingText {
  @apply --marketplaceH4FontStyles;
  margin: 14px 12px 11px 24px;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.ownListingTextPendingApproval {
  color: var(--attentionColor);
}

.closedListingText {
  @apply --marketplaceH4FontStyles;
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.editListingLink {
  @apply --marketplaceH4FontStyles;
  flex-shrink: 0;
  margin: 0;
  padding: 14px 24px 11px 12px;
  color: var(--matterColorNegative);

  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--matterColorLight);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin: 0;
    padding: 25px 24px 22px 12px;
  }
}

.editIcon {
  margin: -6px 7px 0 0;
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    position: static;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    height: calc(0.41 * 100vw);
    max-height: 50vh;
    object-fit: cover;
    border-radius: var(--borderRadius);

    &:hover {
      transform: scale(1.005);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.viewPhotos {
  @apply --marketplaceTinyFontStyles;
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 24px;
  margin: 0;
  padding: 6px 13px 8px 13px;

  /* Colors */
  background-color: var(--matterColorLight);

  /* Borders */
  border: none;
  z-index: 1;
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.contentContainer {
  margin: 0;
  /* max-width: 1154px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  @media (--viewportMedium) {
    position: relative;
    padding: 144px 0 0 0;
  }

  @media (--viewportLaptop) {
    padding: 205px 0 0 0;
  }

}

.mainContent {
  flex-basis: 100%;
  margin-bottom: 0;
  @media (--viewportMedium) {
  }
  @media (min-width: 1440px) {
    /* contentContainer's full width - (bookingColumn + two paddings + margin + border) */
    /* max-width: calc(100% - 589px); */
    /* flex-basis: calc(100% - 589px); */
    flex-shrink: 0;
    flex-grow: 1;
  }
  @media (--viewportLaptopDown) {
    /* max-width: 100%; */
    flex-shrink: 1;
    flex-grow: 1;
  }
}
.companyMainContent {
  max-width: 100%;
  flex-basis: 100%;
}

.bookingPanel {
  padding: 46px 43px 41px 43px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  box-shadow: 0 0 8px var(--matterColorAnti);
  display: block;

  @media (min-width: 1440px) {
    justify-content: flex-end;

    /* The calendar needs 312px */
    flex-basis: 312px;
    flex-shrink: 0;
    width: 589px;
  }
  @media (--viewportLaptopDown) {
    border: 0px;
    box-shadow: none;
    padding: 20px;
    width: 100%;
  }
  @media (max-width: 768px) {
    padding: 0;
  }
}

.sectionAvatar {
  /* Position (over the listing image)*/
  margin-left: 24px;
  /* margin-top: -72px; */

  /* Rendering context to the same lavel as listing image */
  /* position: relative; */

  /* Flex would give too much width by default. */
  /* width: 60px; */

  @media (--viewportMedium) {
    /* position: absolute;
    top: -112px;
    margin-left: 0;
    margin-top: 0; */
  }
}

.avatarMobile {
  display: flex;
  width: 96px;
  height: 96px;

  @media (--viewportMedium) {
    display: none; /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  display: none; /* Hide the large avatar from the smaller screens */
  width: 152px;
  height: 152px;

  @media (--viewportMedium) {
    display: flex;
  }
}

.initialsDesktop {
  font-size: 48px;
  font-weight: var(--fontWeightBold);
  padding-bottom: 8px;
}

.sectionHeading {
  /* margin-top: 22px;
  margin-bottom: 34px; */
  @media (--viewportMedium) {
    display: flex;
  }

  @media (--viewportLarge) {
    padding: 15px;
  }
}
.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
  }
}

.desktopPriceValue {
  /* Font */
  @apply --marketplaceH1FontStyles;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 6px;
  }
}

.desktopPerUnit {
  /* Font */
  @apply --marketplaceH5FontStyles;
  color: var(--matterColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.heading {
  /* margin-bottom: 33px; */
  margin: 19px 100px 19px 0;
  @media (--viewportMobile) {
    margin: 0;
  }
}

.title {
  /* Font */
  /* @apply --marketplaceH1FontStyles; */

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  color: var(--marketplaceColorDarker);
  text-transform: capitalize;
  @media (--viewportMedium) {
    margin-top: 0;
    font-weight: bold;
    font-size: 45px;
    line-height: 75px;
    color: var(--matterColor);
  }
  @media (--viewportMobile) {
    font-size: 40px;
    line-height: 45px;
    margin: 91px 0 0 0;
  }
}

.author {
  width: 100%;
  @apply --marketplaceH4FontStyles;
  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--matterColor);
  }
}

.authorNameLink {
  color: var(--matterColor);
}

.contactWrapper {
  @media (--viewportMedium) {
    display: inline;
  }
}

.separator {
  margin: 0 6px;
}

.contactLink {
  border: 1px solid var(--marketplaceColorDarker);
  color: var(--marketplaceColorDarker);
  width: 137px;
  min-height: 42px;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: var(--matterColorLight);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  & span {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.3px;
    text-align: center;
    text-transform: capitalize;
    color: var(--marketplaceColorDarker);
  }
}

.bookFromDescription {
  display: inline;
  width: auto;
  min-width: 160px;
  min-height: 40px;
  margin: 0 12px 12px 0;
  background-color: var(--matterColorLight);
  color: var(--marketplaceColor);
  border-color: var(--marketplaceColor);
  box-shadow: 0px 10px 24px 3px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

.bookFromDescription {
  margin-right: 0;
}

.descriptionTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 13px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 20px;
  }
}

.sectionFeatures {
  padding: 0 24px;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 52px;
  }
}

.featuresTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 16px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 20px;
  }
}

.description {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 17px;
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.more {
  color: var(--marketplaceColorSecond);
}

.more:hover {
  cursor: pointer;
}

.sectionMap {
  padding: 0 24px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
  }
}

.locationTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 20px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 28px;
  }
}

.sectionReviews {
  padding: 0 24px;
  margin-bottom: 5px;

  @media (--viewportMedium) {
    padding: 0;
  }
  @media (--viewportLarge) {
    margin-bottom: 3px;
  }
}

.reviewsHeading {
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);
  display: none;
  margin: 52px 0 20px 0;

  @media (--viewportMedium) {
    margin: 53px 0 27px 0;
  }
  @media (--viewportLarge) {
    margin: 50px 0 26px 0;
  }
}

.sectionHost {
  position: relative;
  padding: 0 24px;
  margin-bottom: 5px;

  @media (--viewportMedium) {
    padding: 0;
  }
  @media (--viewportLarge) {
    margin-bottom: 3px;
  }
}

.yourHostHeading {
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin: 54px 0 23px 0;

  @media (--viewportMedium) {
    margin: 53px 0 27px 0;
  }
  @media (--viewportLarge) {
    margin: 53px 0 27px 0;
  }
}

.enquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.enquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.enquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: calc(100vh - 193px);
  width: 100%;

  /* Static map: dimensions are 640px */
  max-width: 300px;
  max-height: 300px;
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    height: 75vh;
  }

  @media (--viewportLarge) {
    height: 417px;
    max-width: unset !important;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.publicData {
  /* background: #223046; */
  /* padding: 20px 0; */
}

.avatarContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
}
.count {
  position: absolute;
  color: white;
  z-index: 99;
  background-color: black;
  padding: 5px 10px;
  border-radius: 15px;
  left: 2%;
  top: 520px;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 1px;
  @media (--viewportLarge) {
    top: 450px;
    margin-left: 15px;
  }
}

.adventure {
  padding-left: 25px;
  font-size: 20px;
  font-weight: 400;
}

.ratingContent {
  font-size: 15px;
  display: flex;
  align-items: baseline;

  & > span {
    padding-right: 12px;
    margin-right: 12px;
    border-right: 1px solid #cbd2e4;
  }
  @media (--viewportMobile) {
    padding-bottom: 16px;
    font-size: 13px;
    flex-wrap: wrap-reverse;
    border-bottom: 1px solid var(--matterColorNegative);
  }
}

.ratingRating {
  display: flex;
  align-items: center;
  font-weight: bold;
  height: 24px;
}
.review {
  margin-top: 2px;
  margin-left: 6px;
}

.rating {
  margin-right: 5px;
  margin-top: 4px;
}

.ratingBookings {
  border-right: none !important;
  color: #9097a3;
  @media (--viewportMobile) {
    font-size: 13px;
    font-weight: bold;
  }
}

.feature {
  /* margin: 5px 10px 10px 22px; */
}

.feature_item {
  font-size: 11px;
  background-color: #757575;
  padding: 2px 10px;
  color: white;
  margin: 3px;
  border-radius: 4px;
  display: inline-block;
}

.property {
  display: block;
  flex-wrap: wrap;
  list-style: none;
  border-top: 1.5px solid var(--matterColorNegative);
  padding: 20px 0 4px;
  /* padding-left: 25px; */
}

.propertyTitle {
  letter-spacing: 0.5px;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: var(--marketplaceColorDarker);

  /* color: var(--matterColorLight); */

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    /* margin-bottom: 5px; */
  }
  @media (--viewportMobile) {
    font-size: 20px;
    line-height: 28px;
  }
}

.property .desc {
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 28px;
  color: var(--matterColorDimmedText);
  /* color: var(--matterColorLight); */
  @media (--viewportMobile) {
    font-size: 18px;
    line-height: 23px;
  }
}

.propertyItem {
  flex: 0 50%;
  margin-bottom: 44px;
  display: flex;
  /* align-items: center; */
  /* color: var(--matterColorLight); */
}

.itemIcon {
  margin-bottom: 3px;
  margin-right: 3px;
  /* color: var(--matterColorLight); */
}

.itinerary {
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.itineraryContent {
  min-width: 0px;
  width: 100%;
  padding: 0px 20px;
  margin-top: -8px;
}

.itineraryBtn {
  border: none;
  width: 100%;
  padding: 0px;
  background: transparent;
  text-align: inherit;
  cursor: pointer;
  display: block;
  margin-top: 0px;
  margin-bottom: 0px;
}

.itineraryBtnContent {
  display: flex;
  margin-left: -4px;
  padding-left: -4px;
}

.itineraryLeftLine {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  position: relative !important;
  display: -webkit-box !important;
  display: -moz-box !important;
  display: -ms-flexbox !important;
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-justify-content: center !important;
  justify-content: center !important;
  /* margin-left: 20px; */
  margin-right: 20px;
}

.itineraryRightContent {
  -webkit-flex: 1 !important;
  -ms-flex: 1 1 0% !important;
  flex: 1 !important;
  padding-bottom: 24px !important;
}

.itineraryItemtitle {
  margin-bottom: 12px;
}

.itineraryItemtitle span {
  font-size: 16px;
  word-wrap: break-word;
}

.itineraryRightContent .content .background {
  position: relative !important;
  background-position: 50% 50% !important;
  background-repeat: no-repeat !important;
  padding-top: 66.6667%;
}

.itineraryRightContent .content .background .backContent {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  position: absolute !important;
  top: 0px !important;
  bottom: 0px !important;
  left: 0px !important;
  right: 0px !important;
  display: -webkit-box !important;
  display: -moz-box !important;
  display: -ms-flexbox !important;
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-align-items: center !important;
  align-items: center !important;
  -webkit-justify-content: center !important;
  justify-content: center !important;
}

.itineraryRightContent .content .background .backContent img {
  height: 100% !important;
  width: 100% !important;
  position: static !important;
  top: 0px !important;
  bottom: 0px !important;
  left: 0px !important;
  right: 0px !important;
  object-fit: cover;
}

.line {
  background-color: var(--matterColorGrey) !important;
  width: 1px !important;
  position: absolute !important;
  top: 7px !important;
  bottom: 0px !important;
}

.dot {
  position: absolute !important;
  top: 7px !important;
  background: var(--marketplaceColorLightWhite) !important;
  border: 1px solid var(--matterColorGrey) !important;
  border-radius: 100% !important;
  width: 7px !important;
  height: 7px !important;
}

.backDescription {
  margin-top: 15px;
}

.backDescriptionTitle {
  font-size: 16px !important;
  font-weight: 800 !important;
  line-height: 1.375em !important;
  color: var(--matterColorGrey) !important;
}

.backDescriptionDesc {
  margin-top: 8px;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 1.375em !important;
  color: var(--matterColorGrey) !important;
}

.backDescriptionShowMore {
  color: var(--marketplaceColorSecond) !important;
  margin-top: 24px;
  font-weight: 700;
  font-size: 14px;
}

.itineraryTitle h2 {
  padding: 0px 25px;
}

.slider img {
  height: 500px;
  object-fit: cover;
  /* -webkit-animation: myMove 6s infinite ease-in-out alternate;
  -moz-animation: myMove 6s infinite ease-in-out alternate;
  -o-animation: myMove 6s infinite ease-in-out alternate;
  -ms-animation: myMove 6s infinite ease-in-out alternate;
  animation: myMove 6s infinite ease-out alternate; */
  @media (--viewportLarge) {
    width: initial;
    height: 400px;
    margin: auto;
  }
}

.slider {
  margin-bottom: 50px;
  @media (--viewportLarge) {
    padding: 30px;
  }
}

@keyframes myMove {
  from {
    transform: scale(1, 1);
  }
  to {
    transform: scale(1.1, 1.1);
  }
}
@-webkit-keyframes myMove {
  from {
    -webkit-transform: scale(1, 1);
    -webkit-transform-origin: 50% 50%;
  }
  to {
    -webkit-transform: scale(1.1, 1.1);
    -webkit-transform-origin: 50% 0%;
  }
}
@-o-keyframes myMove {
  from {
    -o-transform: scale(1, 1);
    -o-transform-origin: 50% 50%;
  }
  to {
    -o-transform: scale(1.1, 1.1);
    -o-transform-origin: 50% 0%;
  }
}
@-moz-keyframes myMove {
  from {
    -moz-transform: scale(1, 1);
    -moz-transform-origin: 50% 50%;
  }
  to {
    -moz-transform: scale(1.1, 1.1);
    -moz-transform-origin: 50% 0%;
  }
}
@-ms-keyframes myMove {
  from {
    -ms-transform: scale(1, 1);
    -ms-transform-origin: 50% 50%;
  }
  to {
    -ms-transform: scale(1.1, 1.1);
    -ms-transform-origin: 50% 0%;
  }
}

.reviewTitle {
  padding-left: 20px;
  font-weight: bold;
}

.totalReviewContainer {
  padding-left: 20px;
  display: flex;
  align-items: center;
}

.video {
  width: 100%;
}

.buttonWrap {
  margin-top: 10px;
  padding-left: 24px;
  padding-right: 24px;
}

.showAll {
  text-align: center;
  font-size: 17px;
  border: 1px solid var(--marketplaceColorBackground);
  padding: 14px 28px;
  color: var(--matterColor);
  font-weight: bold;
  border-radius: 5px;
}
.slider {
  margin-left: 35px;
  margin-right: 35px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.slider button:before {
  color: black;
  clear: both;
}

.sliderImageContainer img {
  width: 100%;
  @media (--viewportMobile) {
    height: 300px;
  }
}
.sliderImageContainer {
  margin: 0px 5px;
  width: 100%;
}

.listedBy {
  @media (--viewportMobile) {
    width: 100%;
    border-right: none !important;
  }
}

.dotSeparator {
  font-size: 24px;
  margin: 0px 10px;
}

.actionBarWrapper {
  position: relative;
}

.sectionWrapper {
  /* margin: 0 24px 18px; */
  margin: 43px 0 0 0;
  & h2 {
    margin: 0;
  }
  & h3 {
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    line-height: 31px;

    color: #6e6f71;
  }
  @media (--viewportMobile) {
    margin: 10px 0 0 0;
  }
}

.sectionWrapper :global(.styles_whatYouCanExpectHead__3SrHk) {
  margin: 0;
  & h2 {
    font-size: 25px;
  }
}

.sectionWhatYouCanExpectWrapper {
  background: var(--marketplaceColorBackground);
}

.sectionWrapperDesc {
  margin: 28px 0 0 0;
  @media (--viewportMobile) {
    margin: 10px 0 0 0;
  }
}

.sectionWrapperWithFlex {
  display: flex;
  margin-top: 5px;
  justify-content: flex-start;
  & h2 {
    margin: 0;
  }
  @media (--viewportMobile) {
    margin: 27px 0 0 0;
    flex-wrap: wrap;
    width: 100%;
  }
}

.packagesModal {
  height: 100vh;

  @apply --marketplaceModalBaseStyles;

  padding: var(--modalPaddingMedium);
  background-color: var(--matterColorLight);
  margin-top: 5vh;
  margin-bottom: 5vh;
  @media (--viewportMedium) {
    flex-basis: 80%;
  }

  @media (--viewportMobile) {
    padding: 0;
  }
}

/* Inquire styles */
.inquireMainContent {
  margin-top: 105px;
  flex-basis: 50%;
}
.enquirySidePanel {
  display: block;
  margin-top: 95px;
  margin-left: 60px;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: var(--matterColorNegative);

  padding-top: 40px;
  padding-left: 60px;

  /* The calendar needs 312px */
  flex-basis: 312px;
  flex-shrink: 0;

  margin-left: 30px;
  margin-top: 80px;
  width: max-content;

  @media (--viewportMobile) {
    margin: 0;
    padding-left: 20px;
  }
}

.category {
  & span {
    font-weight: normal;
    font-size: 24px;
    line-height: 30px;
    color: var(--matterColorAnti);
    margin: 0 24px 0 0;
    @media (--viewportMobile) {
      font-size: 15px;
      line-height: 19px;
      margin: 0 7px 0 0;
    }
  }
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  color: var(--marketplaceColor);
  margin: 5px 142px 0 0;
  text-align: left;
  @media (--viewportMobile) {
    display: flex;
    font-weight: 600;
    font-size: 15px;
    margin: 0 49px 0 0;
    line-height: 19px;
  }
}

.subCategory {
  & span {
    font-weight: normal;
    font-size: 24px;
    line-height: 30px;
    color: var(--matterColorAnti);
    /* padding: 0 0 0 10px; */
    margin: 0 22px 0 0;
    @media (--viewportMobile) {
      font-size: 15px;
      line-height: 19px;
      margin: 0 7px 0 0;
    }
  }
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  color: var(--marketplaceColor);
  margin-top: 5px;
  margin-bottom: 0px;
  text-align: left;
  @media (--viewportMobile) {
    display: flex;
    font-weight: 600;
    font-size: 15px;
    line-height: 21px;
  }
}

.name {
  font-size: 26px;
  font-weight: var(--fontWeightMedium);
}

.singleImageContainer {
  margin: 0 auto;
  padding: 44px 14px 23px;
  height: 727px;
  max-height: 800px;
  object-fit: cover;
  @media (--viewportMobile) {
    padding: 24px 5px;
  }
  @media (max-width: 767px) {
    padding: 24px 0;
    height: 602px;
  }
}

.mapImage {
  border-radius: 12px;
  overflow: hidden;
}
.image,
.mapImage img {
  width: 380px;
  height: 300px;
  object-fit: cover;
  border-radius: 12px;
  border: 5px solid #ffffff;
}

.cancelPolicy {
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 45px;
  margin-top: 7px;
  color: var(--matterColorDimmedText);
  & h2 {
    font-weight: 600;
    font-size: 35px;
    line-height: 50px;
    color: #272727;
    @media (--viewportMobile) {
      font-size: 25px;
      line-height: 31px;
    }
  }

  @media (--viewportMobile) {
    font-size: 18px;
    line-height: 30px;
    padding: 0 16px;
  }
}

.cancelPolicySection {
  max-width: 1154px;
  margin: 0 auto 64px;
  @media (max-width: 768px) {
    padding: 0 24px;
  }
}

.promoListings {
  margin: 0 auto 153px;
  max-width: 1154px;
  @media (max-width: 768px) {
    padding: 0 24px;
  }
  @media (--viewportMobile) {
    padding: 0 16px;
  }
}

.promoListings :global(.LandingPagePromotedListings_title__2lSmp) {
  & span {
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 50px;

    color: #272727;
    @media (--viewportMobile) {
      font-size: 35px;
      line-height: 44px;
    }
  }
}

.promoListings :first-child {
  @media (--viewportMedium) {
    margin: 0 !important;
  }
  @media (--viewportLarge) {
    margin: 0 !important;
  }
}

.packagesDisplay {
  & h2 {
    & span {
      font-weight: 600;
      font-size: 35px;
      line-height: 50px;
      color: #272727;
      @media (--viewportMobile) {
        font-weight: 600;
        font-size: 30px;
        line-height: 38px;
      }
    }
  }
}

.sectionShare {
  display: flex;
}

.shareButton {
  width: 220px;
  justify-content: flex-end;
  cursor: pointer;
  @media (--viewportMobile) {
    width: 94px;
  }
}

.whatsapp {
  margin-left: 20px;
  & svg {
    width: 38px;
    height: 38px;
  }
}
.contactWrapper {
  display: flex;
}

.paragraphButton {
  color: var(--marketplaceColor);
  cursor: pointer;
}

.SectionHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 0 12px; */
  /* margin-top: 20px; */
  max-width: 1154px;
  margin: 16px auto 20px auto;
  @media (--viewportMobile) {
    padding: 10px 12px;
  }
  @media (--viewportTabletDown) {
    margin: 12px 18px;
  }
  @media (--viewportMobile) {
    margin: 39px auto 0;
    padding: 0 16px;
  }
}

.detailSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
  & h2 {
    margin: 0 !important;
    font-weight: 600;
    font-size: 35px;
    line-height: 50px;
    color: var(--marketplaceColorDarker);
    @media (--viewportMobile) {
      font-size: 20px;
      line-height: 25px;
    }
  }

  @media (--viewportMobile) {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
}

.detailSectionInfo {
  /* padding: 15px 0; */
  @media (--viewportMobile) {
    width: 76%;
    padding-left: 12px;
  }
}

.detailInfo {
  font-weight: normal;
  font-size: 22px;
  line-height: 28px;
  color: var(--marketplaceColor);
  @media (--viewportMobile) {
    font-size: 16px;
    line-height: 20px;
  }
}

.reviewSpan {
  display: flex;
  align-items: center;

  color: var(--marketplaceColorDarker);
  &:not(:last-child) {
    padding: 0 90px 0 0;
  }
  & span {
    margin-left: 6px;
    display: block;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    color: var(--matterColorDimmedText);
  }
  @media (--viewportMobile) {
    &:not(:last-child) {
      padding: 0 20px 0 0;
    }
    & span {
      font-size: 20px;
      line-height: 25px;
    }
  }
}

.whatsappSection {
  display: flex;
  justify-content: space-between;
  width: fit-content;
  margin: 38px 0 0;
  @media (--viewportMobile) {
    width: 100%;
    justify-content: space-evenly;
    margin: 64px 0 35px 0;
  }
}

.whatsappSection :global(.SectionLevel_sectionLevel__3Jy-6) {
  margin-left: 16px;
  display: flex;
  align-items: center;
}

.listPublicInfo {
  line-height: 8px;
  padding: 0 0 0 21px;
  display: flex;
  flex-direction: column;
}

.authorDetailsContainer {
  display: flex;
  align-items: center;
  margin-right: 44px;
  @media (--viewportMobile) {
    margin-right: 0;
  }
}

.authorDetails {
  margin: 0 0 0 16px;
  display: flex;
  flex-direction: column;
  @media (--viewportMobile) {
    margin: 0 0 0 7px;
  }
}

.authorDetailPromoHead {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 43px;
  color: var(--marketplaceColorDarker);
  @media (--viewportMobile) {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    color: var(--marketplaceColorDarker);
  }
}

.authorDetailPromo {
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  color: var(--matterColorDimmedText);
  @media (--viewportMobile) {
    font-size: 14px;
    line-height: 18px;
  }
}

.authorReviewInfo {
  padding: 5px 0 0 0;
  display: flex;
  align-items: center;
  @media (--viewportMobile) {
    justify-content: center;
  }
}

.authorReviewInfoDetail {
  padding: 0 0 41px;
  display: flex;
  align-items: center;
}

.descriptionFontStyle {
  color: var(--matterColorDimmedText);
  font-weight: 400;
  font-size: 20px;
}

.authorDetailsLargeImageRelative {
  position: relative;
  height: 96px;
  width: 96px;
  border-radius: 50%;
  border: 3px solid var(--matterColorLightBlue);
  padding: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (--viewportMobile) {
    height: 67px;
    width: 67px;
    & div {
      height: 54px;
      width: 54px;
      min-width: 54px;
    }
  }
}

.TopHead {
  display: flex;
  @media (--viewportMobile) {
    display: flex;
    flex-direction: column;
  }
}

.authorDescriptions {
  display: flex;
  column-count: 3;
  column-gap: 30px;
  margin-top: 93px;
  & span {
    font-size: 18px;
    color: var(--matterColorDimmedText);
    font-weight: 400;
  }

  @media (--viewportMobile) {
    display: block;
    column-count: 1;
    margin-top: 23px;
  }
}

.authorDescriptionsContainer {
  max-width: 1154px;
  margin: 0 auto;
  padding: 0 12px 25px 12px;
  @media (max-width: 768px) {
    padding: 0 24px;
  }
  @media (--viewportMobile) {
    padding: 0 16px;
  }

  & h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 50px;
    margin: 0 0 23px 0;
    color: #272727;
    @media (--viewportMobile) {
      font-size: 35px;
      line-height: 44px;
    }
  }
}

.greyReviewSection {
  padding: 65px 0 53px 0;
  background-color: var(--matterColorLightGrey);
}

.btnWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
}

.seeMoreButton {
  background-color: var(--marketplaceColorLightWhite);
  border: 1px solid var(--marketplaceColor);
  box-sizing: border-box;
  border-radius: 8px;
  font-weight: 600;
  font-size: 22px;
  padding: 0 24px;
  line-height: 55px;
  text-align: center;
  text-transform: capitalize;
  color: var(--marketplaceColor);
  width: auto;
}

.mainReviewContainer {
  max-width: 1154px;
  margin: 0 auto;
  & h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 50px;
    color: #272727;
    @media (--viewportMobile) {
      font-weight: 700;
      font-size: 30px;
      text-align: center;
      padding: 15px 0;
    }
  }
  @media (max-width: 768px) {
    padding: 0 24px;
  }
  @media (--viewportMobile) {
    padding: 0 16px;
  }
}

.headStar {
  width: 26px;
  height: 26px;
  margin: 0 7px 0 0;
}

.reviewStar {
  height: 26px;
  width: 26px;
  margin: 0 11px 0 0;
  @media (--viewportMobile) {
    height: 25px;
    width: 25px;
  }
}

.reviewHeadSpan {
  display: flex;
  align-items: center;
  padding: 0 5px 0 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: var(--marketplaceColorDarker);
  @media (--viewportMobile) {
    /* width: 245px; */
  }
}

.HeadSpan {
  font-style: normal;
  margin-left: 15px;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: var(--marketplaceColorDarker);
  @media (--viewportMobile) {
    font-size: 15px;
    margin-left: 0;
    line-height: 19px;
  }
}

.HeadSpan2 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  margin-left: 25px;
  color: var(--marketplaceColorDarker);

  @media (--viewportMobile) {
    font-size: 15px;
    margin-left: 13px;
    line-height: 19px;
  }
}

.actionBarWrapperContainer {
  @media (--viewportMobile) {
    max-width: 100%;
    margin: 0;
  }
}

.bookingPanelContainer {
  /* width: 100%; */
  display: flex;
  justify-content: flex-end;
}

.imageSlider {
  max-width: 1154px;
  margin: 0 auto;
  @media (max-width: 768px) {
    padding: 0 12px;
  }
  @media (--viewportMobile) {
    padding: 0;
  }
}

.iconMasterVerified {
  width: 152px;
  height: 45px;
}

.avatarLargeImage {
  width: 78px;
  min-width: 78px;
  height: 78px;
}

.listingFooter :global(.Footer_content__3LKvi) {
  max-width: 1154px;
  padding: 0;
  @media (max-width: 768px) {
    padding: 0;
  }
}

.mobileData {
  padding: 0 1px;
  margin-top: 28px;
  margin-left: 12px;
  @media (min-width: 1024px) {
    display: none;
  }
}

.desktopData {
  @media (max-width: 1024px) {
    display: none;
  }
}

.topHeaderImages {
  position: relative;
  @media (--viewportMobile) {
    margin: 19px 0 0 0;
  }
}

.bannerImage {
  /*max-width: 1154px;*/
  max-width: 100%;
  /* height: 100%; */
  height: 599px;
  margin: 0 auto;
  position: relative;
  object-fit: cover;
  @media (--viewportMobile) {
    height: 544px;
    & img {
      border-radius: 0;
    }
  }
}
.titleContainer {
  position: absolute;
  width: 100%;
  height: 131px;
  left: 0px;
  top: 50px;
  background: rgba(35, 48, 69, 0.5);
  font-style: normal;
  font-weight: 700;
  font-size: 72px;
  line-height: 98px;
  text-align: right;
  color: #ffffff;
  padding: 16px 80px;
}

.logoContainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: -120px auto 0;
  max-width: 1105px;
  height: 304px;
  position: relative;
  @media (--viewportMobile) {
    height: 124px;
    width: 118px;
    left: 18px;
    bottom: -60px;
    & img {
      border-radius: 27px;
      border: 5px solid var(--matterColorDarkBlue);
    }
  }
}
.logoContainer div {
  max-width: 380px !important;
  max-height: 300px !important;
}
.logoContainer div img {
  object-fit: cover;
  border-radius: 12px;
  border: 5px solid #ffffff;
}
.companyMapImage {
  max-width: 300px;
}
.companyImageContainer {
  margin: 0 auto;
  /*height: 300px;*/
  /*max-height: 300px;*/
  object-fit: cover;
  border: 5px solid #ffffff;
}

.hostDetail {
  display: flex;
}

.hostSection {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 57px 0 14px 0;
  @media (--viewportMobile) {
    display: block;
    padding: 27px 0 20px 0;
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.collectionImages {
  display: flex;
  justify-content: space-between;
  @media (--viewportMobile) {
    display: block;
  }
}

.collectionImagesSmall {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  @media (--viewportMobile) {
    margin-top: 11px;
  }
}

.collectionWrapOne {
  height: 184px;
  width: 286px;
  & img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 10px 0 0 0;
  }
  @media (--viewportMobile) {
    height: 236px;
    width: 100%;
    & img {
      border-radius: 0;
    }
  }
}
.collectionWrapTwo {
  height: 184px;
  width: 286px;
  & img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 0 10px 0 0;
  }
  @media (--viewportMobile) {
    height: 236px;
    width: 100%;
    margin: 8px 0 0 0;
    & img {
      border-radius: 0;
    }
  }
}

.collectionSmallWrapOne {
  height: 183px;
  width: 186px;
  & img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 0 0 0 10px;
  }
  @media (--viewportMobile) {
    height: 116px;
    width: 118px;
    & img {
      border-radius: 0px 0px 0px 5px;
    }
  }
}
.collectionSmallWrapTwo {
  height: 183px;
  width: 186px;
  & img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  @media (--viewportMobile) {
    height: 116px;
    width: 118px;
  }
}
.collectionSmallWrapThree {
  height: 183px;
  width: 186px;
  & img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 0 0 10px 0;
  }
  @media (--viewportMobile) {
    height: 116px;
    width: 118px;
    & img {
      border-radius: 0px 0px 5px 0px;
    }
  }
}

.ourProsHeading {
  font-weight: 600;
  font-size: 25px;
  line-height: 44px;
  text-transform: capitalize;
  color: var(--matterColor);
  position: relative;
  margin: 0;

  & span {
    background: #fff;
    z-index: 1;
    width: fit-content;
    padding: 0 20px 0 0;
  }
  @media (--viewportMobile) {
    font-size: 25px;
    line-height: 31px;
    margin: 32px 0 0 0 !important;
  }
}
/*
.ourProsHeading::after {
  content: '';
  height: 1.5px;
  width: 100%;
  background: var(--matterColorNegative);
  top: 50%;
  left: 0;
  position: absolute;
  z-index: -1;
  !* @media (--viewportMobile){
    display: none;
  } *!
}
*/

.ourProSectionWrapper {
  margin: 32px 0 0 0;
}

.infoDetail {
  max-width: 1154px;
  margin: 0 auto;
}

.specializationWrapper li .textWrap {
  font-size: 16px;
  margin: 0 0 0 5px;
  line-height: 30px;
}
.specializationWrapper li .ml5 {
  margin-left: 24px;
}
.specializationWrapper li p {
  font-size: 16px;
  margin: 0 0 0 5px;
  line-height: 30px;
  white-space: nowrap;
}
.specializationWrapper li h5 {
  font-weight: 700;
  line-height: 30px;
  white-space: nowrap;
  margin: 0 0 0 24px;
}
.specializationWrapper li img {
  margin-right: 8px;
  position: absolute;
  left: 0;
  top: 9px;
}
.specializationWrapper li {
  list-style: none;
  color: #272727;
  display: flex;
  position: relative;
  align-items: flex-start;
}
.specializationWrapper h2 {
  font-weight: 700;
  font-size: 28px;
  line-height: 38px;
  color: #233045;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  margin-top: 40px;
}
.specializationWrapper {
  padding-bottom: 24px;
}
.profileIdentityWrapper {
  width: 104px;
  height: 41px;
  margin-left: 24px;
  background: #f5f5f5;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 11px;
}

.profileIdentityWrapper span {
  font-size: 10px;
  line-height: 15px;
  color: #6e6f71;
  margin-left: 12px;
  font-weight: 600;
  text-align: right;
}

.publishListingWrapper {
  width: 100%;
  background-color: rgb(0, 0, 0, 0.7);
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding: 10px 20px 10px 35px;
  margin: 0;

  @media (--viewportMobile) {
    justify-content: flex-end;
  }
}

.btnGroupWrapper {
  display: flex;
}

.travelAgentWrapper{
  width: 100%;
}

.travelAgentWrapper > div{
  max-width: 1100px;
  margin: 0 auto;
}


.tripExpertsWrapper{
  margin-left: 10%;
}





.infoIcon{
  cursor: pointer;
  /* margin-bottom: 12px; */
  margin-left: 10px;
  color: white;
  margin-right: 20px;
  height: 100%;
  position: relative;
}

.extraInfoWrapper{
  display: none;
}

.infoIcon:hover + .extraInfoWrapper{
  display:flex;
  flex-direction: column;
  position: absolute;
  background-color: rgb(241, 241, 241);
  border-radius: 5px;
  z-index: 9999;
  padding: 20px;
  width: 20pc;
  box-shadow: 0 0.1rem .2rem rgb(170, 170, 170);
  border-bottom: 4px solid var(--matterColor);

  font-size: 16px;
  line-height: 22px;
  color: var(--matterColor);
}

.extraInfoWrapper:hover{
  display:flex;
  flex-direction: column;
  position: absolute;
  background-color: rgb(241, 241, 241);
  border-radius: 5px;
  z-index: 9999;
  padding: 20px;
  width: 20pc;
  box-shadow: 0 0.1rem .2rem rgb(170, 170, 170);
  border-bottom: 4px solid var(--matterColor);

  font-size: 16px;
  line-height: 22px;
  color: var(--matterColor);
}


.bannerImageListing {
  width: 100%;
  height: auto;
  object-fit: contain;
  /* max-height: 300px; */
}

.listingDraft_sectionHeadWrapper{
  display: flex;
  justify-content: space-between;
  padding: 0 22px;
  margin-top: 40px;
  /* margin-top: 20px; */
  width: 100%;
}


.listingDraft_image {

    width: 300px;
    height: auto;
    object-fit: contain;
    border-radius: 12px;
    border: 5px solid #ffffff;


}

.listingDraft_companyMapImage{

    width: 300px;
    height: 220px;
    object-fit: cover;


}

.listingDraft_bannerImage{
   /*max-width: 1154px;*/
   max-width: 100%;
   margin: 0 auto;
   position: relative;
   @media (--viewportMobile) {
     & img {
       border-radius: 0;
     }
   }
}

.detailsSectionWrapper{
  padding: 0 20px;
}


.listingDraft_submitButton{
  border-radius: 8px;
  min-height: 66px;
  padding: 20px 54px;
  white-space: nowrap;
  width: 257px;
  background-color: var(--marketplaceColor);
  border: 0;
  color: var(--matterColorLight);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;


  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;
  align-self: center;

  @media (--viewportLarge) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 257px;
    margin: auto;
    /* margin-top: 100px; */
  }
}

.listingDraft_travelAgentWrapper{
  padding: 0 20px;
}




.listingDraft_specializationWrapper li .textWrap {
  font-size: 16px;
  margin: 0 0 0 5px;
  line-height: 30px;
}
.listingDraft_specializationWrapper li .ml5 {
  margin-left: 24px;
}
.listingDraft_specializationWrapper li p {
  font-size: 16px;
  margin: 0 0 0 5px;
  line-height: 30px;
  white-space: nowrap;
}
.listingDraft_specializationWrapper li h5 {
  font-weight: 700;
  line-height: 30px;
  white-space: nowrap;
  margin: 0 0 0 24px;
}
.listingDraft_specializationWrapper li img {
  margin-right: 8px;
  position: absolute;
  left: 0;
  top: 9px;
}
.listingDraft_specializationWrapper li {
  list-style: none;
  color: #272727;
  display: flex;
  position: relative;
  align-items: flex-start;
}
.listingDraft_specializationWrapper h2 {
  font-weight: 700;
  font-size: 28px;
  line-height: 38px;
  color: #233045;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  margin-top: 40px;
}
.listingDraft_specializationWrapper {
  padding: 0 20px;
}

.headingTitle {
  font-size: 35px;
  color: white;
  text-transform: capitalize;
  margin: 0;

  @media (--viewportMobile) {
    font-size: 20px;
  }
}

.optionalTitle {
  @media (--viewportMobile) {
    display: none;
  }
 }

.publishNotificationText {
  text-align: center;
  margin: 30px;
}

.modalClass {
  @apply --marketplaceModalBaseStyles;
  min-height: 100vh;
  height: 100%;
  justify-content: center;

  @media (--viewportMedium) {
    flex-basis: 576px;
    min-height: auto;
    height: auto;
  }
}