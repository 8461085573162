.footerImg {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}

.player {
  margin: auto;
}

video {
  scroll-margin-top: 100px;
}