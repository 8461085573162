@import '../../marketplace.css';

.root {
  & label {
    width: 60%;
  }
}

.input {
  border-bottom-color: var(--attentionColor);
}

.inputSuccess {
  border-bottom-color: var(--successColor);
}

.inputError {
  border-bottom-color: var(--failColor);
}

.extraLabel {
  font-size: 14px;
  color: gray;
  line-height: 18px;
}

.headingWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & svg {
    fill: transparent;
  }

  & span {
    font-size: 16px;
    line-height: 18px;
  }

  & img {
    display: none;
    @media(--viewportLaptop) {
      display: inline-block;
      margin-right: 100px;
    }
  }
}

.support {
  position: relative;
  display: flex;
  justify-content: flex-end;
  width: 70%;
}

.support .supportText {
  font-size: 16px;
  line-height: 24px;
  font-size: 16px;
  line-height: 24px;
  background-color: var(--matterColorDark);
  color: var(--matterColorLight);
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  margin: 0 21px;

  /* Position the tooltip */
  position: absolute;
  top: 0px;
  right: 9px;
  z-index: 1;
}

.hideSupportText {
  visibility: hidden;
}

.buttonWrapper {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  column-gap: 20px;

  & button {
    width: 34px;
    min-height: 34px;
    background: var(--matterColorLight);
    border: 2px solid var(--marketplaceColor);
    color: var(--marketplaceColor);
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover,
    &:focus {
      background: var(--successColorLight);
    }

    &:disabled {
      background: var(--matterColorLight);
      border: 2px solid var(--matterColorDimmedText);
      color: var(--matterColorDimmedText);
      border-radius: 24px;
    }
  }
}

.counterValueWrapper {
  border-bottom: 2px solid var(--marketplaceColor);
}

.labelWrapper {
  display: flex;
}