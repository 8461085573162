@import '../../marketplace.css';
.pageTitle {
  text-align: center;
}

.staticPageWrapper {
  /* width: calc(100% - 48px); */
  max-width: 1056px;
  margin: 24px auto;

  @media (--viewportMedium) {
    /* width: calc(100% - 72px); */
    margin: 72px auto;
  }

  @media only screen and (max-width: 900px) {
    margin-top: 5pc;
  }
}

.coverImage {
  width: 100%;
  height: 528px;
  border-radius: 4px;
  object-fit: cover;
  margin: 32px 0 40px;
}

.contentWrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  /* flex-wrap: wrap;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
  } */
}

.noListings {
  padding-top: 4pc;
  margin: 0 auto;
}

.contentSide {
  font-style: italic;

  @media (--viewportMedium) {
    width: 193px;
    margin-right: 103px;
    margin-top: 8px;
  }
}

.contentMain {
  width: 90%;

  @media (--viewportMedium) {
    max-width: 650px;
  }
}

.subtitle {
  @apply --marketplaceH3FontStyles;
  margin-top: 32px;
  margin-bottom: 16px;
}

.listingCard {
  margin-bottom: 36px;

  @media (--viewportLarge) {
    /**
      * resultPanelWidthRatio = 0.625 aka 62.5%

      resultPanel: 62.5vw from 1024px = 640px
      panelPaddings: - 2*36px = 72px

      columnCount: 2
      guttersBetweenColumns: 24px

      (resultPanel - (panelPaddings + guttersBetweenColumns)) / columnCount = listingCardWidth
      ergo => listingCardWidth: 272px

    * flex-basis: calc((100%/columnCount) - (guttersBetweenColumns / columnCount))
    */

    margin-right: 24px;
  }

  @media (--viewportXLarge) {
    /**
     * resultPanelWidthRatio = 0.625 aka 62.5%

      resultPanel: 62.5vw from 1920px = 1200px
      panelPaddings: - 2*36px = 72px

      columnCount: 3
      guttersBetweenColumns: 2*24px = 48px

      (resultPanel - (panelPaddings + guttersBetweenColumns)) / columnCount = listingCardWidth
      ergo => listingCardWidth: 360px

     * flex-basis: calc((100%/columnCount) - (guttersBetweenColumns / columnCount))
     */

    margin-right: 24px;
  }
}

.listingsWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
