@import '../../marketplace.css';

.buttons {
  display: flex;
  justify-content: space-between;
  & button {
    display: inline;
    width: auto;
    padding: 0 15px;
  }
}
.banner {
  margin-bottom: 48px;
  width: 100%;
  height: 240px;
  background-image: url('../../assets/progress-report.jpg');
  background-size: cover;
}
.progressReportWrapper{
  margin-top: 64px;
}
