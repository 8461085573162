@import '../../marketplace.css';

.upcomingCalendarContainer {
  background: var(--matterColorLight);
  margin: 0 auto;
  width: 88%;
  padding-left: 20px;
  padding-bottom: 36px;
  padding-top: 16px;
  @media (--viewportLarge) {
    width: 74%
  }
  @media (--viewportLaptop) {
    width: 78%
  }

  & .subHeader {
    margin: 12px 0;
    text-align: center;
    overflow: hidden;
    & span {
      position: relative;
      font-size: 14px;
      color: var(--matterColorGrey);
      &:after,
      &:before {
        content: '';
        position: absolute;
        height: 1px;
        background-color: var(--matterColorNegative);
        top: 50%;
        width: 100vh;
      }
      &:before {
        right: 100%;
        margin-right: 15px;
      }
      &:after {
        left: 100%;
        margin-left: 15px;
      }
    }
  }
  @media (--viewportMobile){
    margin: 0;
    padding: 0;
    width: 100%;
  }
}

.calendarContainer{
  max-width: 668px;
  margin: 0 auto;
  width: 100%;
}

.upcomingCalendar {
  margin: 0 32px;
  @media (--viewportMobile) {
    margin: 0 50px 0 32px;
  }
  & .date {
    padding: 17px 23px;
    margin: 0 9px;
    @media (--viewportMobile) {
      padding: 14px;
      margin: 0;
    }
    & .weekDate{
      font-weight: 600;
      font-size: 20px;
      line-height: 25px;
      color: rgba(148, 148, 148, 0.52);
      margin-bottom: 28px;
      @media (--viewportMobile) {
        font-size: 16px;
        line-height: 20px;
      }
    }
    & .numberDate{
      font-weight: 600;
      font-size: 20px;
      line-height: 25px;
      color: rgba(148, 148, 148, 0.52);
      @media (--viewportMobile) {
        font-size: 16px;
        line-height: 20px;
      }
    }
    &.selected {
      background: #EFFBFE;
      border-radius: 5.53202px;
      @media (--viewportMobile) {
        background: var(--marketplaceColor);
      }
      & .weekDate{
        font-weight: 600;
        font-size: 20px;
        line-height: 25px;
        color: var(--marketplaceColor);
        margin-bottom: 28px;
        @media (--viewportMobile) {
          font-size: 16px;
          line-height: 20px;
          color: var(--matterColorLight);
        }
      }
      & .numberDate{
        font-weight: 600;
        font-size: 20px;
        line-height: 25px;
        color: var(--marketplaceColor);
        @media (--viewportMobile) {
          font-size: 16px;
          line-height: 20px;
          color: var(--matterColorLight);
        }
      }
    }
  }
}

.scrollArrow {
  right: 0;
  display: block;
  & > div {
    background-image: url('../../assets/blue_right-arrow.png') !important;
    /* width: 28px !important;
    height: 28px !important; */
    left: 0;
    right: 0;
    margin: 0 auto;
    @media (--viewportMobile){
      height: 30px !important;
      width: 30px !important;
      background-image: url('../../assets/mobile-blue-right-arrow.png') !important;
    }
  }
}

.dateLabelContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.scheduleText{
  margin: 0 0 16px;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  text-transform: capitalize;
  color: var(--marketplaceColorDarkest);
  position: relative;
  & span{
    background: #fff;
    padding: 0 13px 0 0;
    z-index: 1;
    width: fit-content;
  }
  @media (--viewportMobile) {
    font-size: 30px;
  }
}

.scheduleText::after{
  content: '';
  height: 1.5px;
  width: 98%;
  background: var(--matterColorNegative);
  /* top: 50%; */
  bottom: 10px;
  left: 0;
  position: absolute;
  z-index: -1;
}

.textSeaction{
  padding-left: 75px;
  @media (--viewportTabletDown) {
    padding-left: 22px;
  }
}

.desktopData {
  @media (--viewportMobile) {
    display: none;
  }
}

.classFoundTextSeaction{
  padding-left: 75px;
  @media (--viewportTabletDown) {
    padding-left: 22px;
  }
}

.mobileTextSeaction{
  display: none;
  @media (--viewportMobile) {
    display: block;
    max-width: 86%;
    margin: 0 auto 27px;
  }
}

.selectedDateText{
  margin: 0 0 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  text-transform: capitalize;
  color: var(--marketplaceColorDarkest);
  position: relative;
  & span{
    background: #fff;
    padding: 0 14px 0 24px;
    z-index: 1;
    width: fit-content;
    @media (--viewportMobile) {
      padding: 0 10px 0 13px;
    }
  }
  & .selectedDate{
    font-weight: bold;
    font-size: 22px;
    line-height: 28px;
    color: var(--marketplaceColorDarkest);
    @media (--viewportMobile) {
      font-weight: bold;
      font-size: 18px;
      line-height: 23px;
    }
  }
  & .classesFound {
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    color: #6E6F71;
    @media (--viewportMobile) {
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      color: #C4C4C4;
    }
  }
  @media (--viewportMobile) {
    font-size: 30px;
  }
}

.selectedDateText::after{
  content: '';
  height: 1.5px;
  width: 98%;
  background: var(--marketplaceColorDarkest);
  top: 50%;
  left: 0;
  position: absolute;
  z-index: -1;
  @media (--viewportMobile) {
    top: 75%;
    background: #C4C4C4;
  }
}