@import '../../../../marketplace.css';

.wrapper {
  margin-bottom: 100px;
  padding: 20px;
  overflow: auto;
  margin-top: 33px;
  border: 1.02098px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 12.2517px;
  @media (--viewportMobile) {
    margin-top: 5px;
    padding: 10px;
  }
}
.weekdayNameRow {
  background-color: var(--marketplaceColorLight);
  color: var(--matterColorLight);
  font-weight: bold;
  & td {
    padding: 0 10px;
  }
}
.table {
  font-size: 16px;
  padding-right: 20px;
  @media (--viewportLaptop) {
    padding-right: 0;
  }

  & th {
    padding: 0 10px;
  }

  & .head {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin-bottom: 32px;
    border-bottom: 1px solid #F1F1F1;
    & > .cell {
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      color: #6E6F71;
      @media (--viewportMobile) {
        font-size: 13px;
        line-height: 16px;
      }
    }
  }

  & .nameCol {
    width: 15%;
    /* margin: 0 0 18px 1.8%; */
    text-align: left;
    @media (--viewportMobile) {
      width: 30%;
      /* margin: 0 0 10px 1.8%; */
    }
  }
  & .phoneCol {
    width: 15%;
    /* margin: 0 0 18px 1.8%; */
    text-align: left;
    @media (--viewportMobile) {
      width: 30%;
      /* margin: 0 0 10px 1.8%; */
    }
  }
  & .orderNumberCol {
    width: 30%;
    /* margin: 0 0 18px 1.8%; */
    text-align: center;
    @media (--viewportMobile) {
      width: 36%;
      /* margin: 0 0 10px 1.8%; */
    }
  }
  & .totalSpendCol {
    width: 25%;
    /* margin: 0 0 18px 1.8%; */
    text-align: right;
    @media (--viewportMobile) {
      width: 36%;
      /* margin: 0 0 10px 1.8%; */
    }
  }
}

.row {
  display: flex;
  &:not(:last-child) {
    border-bottom: 1px solid #ededed;
  }
  &:nth-child(even) {
    background: #f7f9fa;
  }
}
.cell {
  margin: 18px;
  font-size: 14px;
}
.whatsappIconSection {
  display: flex;
}

.nameContent,
.listingNameContent {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.name {
  width: 15%;
  @media (--viewportMobile) {
    width: 30%;
  }
}
.phone {
  width: 15%;
  @media (--viewportMobile) {
    width: 30%;
  }
}
.emptyPhone{
  padding-left:  1.5rem;
}
.listingName {
  width: 30%;
  @media (--viewportMobile) {
    width: 36%;
  }
}

.pagination {
  display: flex;
  justify-content: center;
}
.paginationLink {
  padding: 10px;
  cursor: pointer;
  &.current {
    border-bottom-style: solid;
    border-bottom-width: 3px;
    border-bottom-color: var(--matterColorDark);
    color: var(--matterColorDark);
  }
}
.iconWhatsapp {
  margin-left: 10px;
  & svg  {
    height: 25px;
    width: 25px;
  }
}

.checkboxInput {
  width: auto;
  @media (--viewportMedium) {
    width: 50px;
  }
}

.checkboxField {
  position: relative;
  display: flex;
  align-items: center;
  & > span {
    font-size: 16px;
    padding-left: 5px
  }

}

.checkboxField label {
  background-color: var(--matterColorLight);
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 22px;
  position: absolute;
  top: 2px;
  width: 22px;
}

.checkboxField label:after {
  border: 3px solid var(--matterColorLight);
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 50%;
  transform: translate(-50%, -75%) rotate(-45deg);
  opacity: 0;
  position: absolute;
  top: 50%;
  width: 12px;
  @media (--viewportMobile) {
    border-width: 2px;
  }
}

.checkboxField input[type="checkbox"] {
  visibility: hidden;
}

.checkboxField input[type="checkbox"]:checked + label {
  background-color: var(--matterColorLightBlue);
  border-color: var(--matterColorLightBlue);
}

.checkboxField input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

.checkboxFieldCol{
  @media (--viewportMobile) {
    width: 5%;
  }
}

.checkboxCol{
  @media (--viewportMobile) {
    width: 5%;
  }
}

.checkboxCol label{
  top: -12px;
  @media (--viewportMobile) {
    top: -8px
  }
}
