@import '../../marketplace.css';

.definitionsSection {
  display: flex;
  justify-content: center;
  padding: 65px 0;

  @media (--viewportMobile) {
    padding: 50px 0;
  }
}

.containerWrapper {
  max-width: 1501px;
  margin: 0 120px;

  @media (--viewportLaptopDown) {
    margin: 0 50px;
  }

  @media (--viewportMobile) {
    margin: 0 30px;
  }

  & p>span {
    font-size: 16px;
  }
}

.expandableBar {
  border-bottom: 0.5px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;

  @media (--viewportMobile) {
    padding: 10px 0;
  }
}

.expandedBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  font-weight: bold;

  @media (--viewportMobile) {
    padding: 10px 0;
  }
}

.accordionArrow {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  justify-items: center;
  margin-left: 0.5em;
  padding: 0 0.5em;
  transform: rotateX(0);
  transition: transform .3s ease;

  &:before {
    border-color: #1a2b49;
    border-style: solid;
    border-width: 0 0 0.15em 0.15em;
    content: "";
    height: 0.6em;
    transform: rotate(-135deg);
    transition: transform .2s ease;
    width: 0.6em;
  }
}

.accordionArrowOpen {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  justify-items: center;
  margin-left: 0.5em;
  padding: 0 0.5em;
  transform: rotateX(90);
  transition: transform .3s ease;

  &:before {
    border-color: #1a2b49;
    border-style: solid;
    border-width: 0 0 0.15em 0.15em;
    content: "";
    height: 0.6em;
    transform: rotate(-45deg);
    transition: transform .2s ease;
    width: 0.6em;
  }
}