@import '../../marketplace.css';

:root {
  --cardSizeDesktop: 232px;
  --cardSizeMobile: 175px;
}

.root {
  /* Layout */
  display: flex;
  flex-direction: row;
  -webkit-box-shadow: 0px 1px 8px -1px #00000090;
  box-shadow: 0px 1px 8px -1px #00000090;

  &:hover {
    text-decoration: none;
  }
}

.listingLink {
  margin: 0 auto;
  text-decoration: none !important;
  font-size: 16px !important;
}

.popular {
  background-color: var(--matterColorLight);
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  text-align: center;
  padding-bottom: 0;
  height: fit-content;
  width: var(--cardSizeDesktop);
  position: relative;
  border-radius: 10px;
  margin: 10px;
  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.047);
  font-family: 'HelveticaNeue', 'Source Sans Pro', sans-serif;

  @media (--viewportMobile) {
    width: var(--cardSizeMobile);
    margin: 10px auto;
  }
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
  transition: var(--transitionStyleButton);
  border-radius: 10px;
  height: 480px;

  &:hover {
    box-shadow: var(--boxShadowListingCard);
  }
}

.info {
  padding: 0.2rem;
  flex: 1 1 auto;
  margin: 10px;

  @media (--viewportLarge) {
    padding: 0.6rem;
    height: unset;
  }
}

.linkText {
  width: 100%;
}

.avatarWrapper {
  display: flex;
  margin-bottom: 18px;
  align-items: center;
  font-size: 10px;

  @media (--viewportMobile) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.avatarImg {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  margin-right: 10px;

  @media (--viewportLarge) {
    height: 40px;
    width: 40px;
  }

  @media (--viewportMobile) {}

  display: none;
}

.name {
  color: var(--matterColor);
  font-weight: bold;
  font-size: 14px !important;

  @media (--viewportLarge) {
    font-size: 14px !important;
  }
}

.headline {
  color: var(--matterColorDarkBlue);
  margin-top: 5px;
  margin-bottom: 0px;
  font-size: 16px;
  overflow: hidden;
  width: 125px;
  text-align: left;
  line-height: 18px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: 600;

  @media (--viewportLarge) {
    margin-top: 5px;
    width: unset;
  }
}

.section {
  margin: 18px 0;
}

.category,
.subCategory {
  & span {
    color: var(--matterColorDarkBlue);
    margin-top: 5px;
    margin-bottom: 0px;
    font-size: 14px;
    text-align: left;
    line-height: 14px;
    font-weight: 400;
  }

  color: var(--matterColorDarkBlue);
  margin-top: 5px;
  margin-bottom: 0px;
  font-size: 16px;
  text-align: left;
  line-height: 18px;
  font-weight: 600;

  @media (--viewportLarge) {
    margin-top: 5px;
    width: unset;
  }
}

.description {
  margin-top: 10px;
  margin-bottom: 0px;
  font-size: 16px;
  overflow: hidden;
  width: 100%;
  min-height: 42px;
  text-align: left;
  line-height: 1.2;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 15;
  -webkit-box-orient: vertical;
  color: #727279;
}