@import '../../../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --TabNav_linkWidth: 240px;
  --TabNav_linkBorderWidth: 4px;
}

.root {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

.tab {
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }

  @media (--viewportLarge) {
    margin-left: 24px;
  }
}

.link {
  padding-right: 20px;
  display: inline-block;
  white-space: nowrap;

  /* Font */
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  color: #6B7B95;
  /* @apply --marketplaceTabNavFontStyles;

  color: var(--matterColor); */
  padding-bottom: 10px;
  padding-top: 10px;

  /* push tabs against bottom of tab bar */
  margin-top: auto;

  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: transparent;

  transition: var(--transitionStyleButton);
  /* background-image: url("data:image/svg+xml;utf8,<svg width='4' height='32' viewBox='0 0 4 32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h4v32H0z' fill='var(--marketplaceColorEncoded)' fill-rule='evenodd'/></svg>"); */
  background-position: calc(var(--TabNav_linkWidth) + var(--TabNav_linkBorderWidth)) center; /* SelectedLink's width (200px) + border thickness (4px) = 204px */

  /* &:hover {
    text-decoration: none;
    color: var(--matterColorDark);
  } */
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
  & svg{
    fill: transparent;
    stroke: "#6B7B95";
    margin-right: 16px;
  }

  @media (max-width: 768px) {
    padding-left: 30px;
    display: flex;
    align-items: center;
    height: 57px;
    font-size: 16px;
    line-height: 20px;
  }

  @media (--viewportLarge) {
    width: 279px;
    height: 57px;
    border-bottom-width: 0px;
    margin-top: 0;
    margin-bottom: 16px;

    padding-top: 6px; /* Drop the text to adjust it to correct baseline */
    padding-bottom: 2px;
    display: flex;
    align-items: center;
    padding-left: 71px;
    /* &:hover {
      background-image: url("data:image/svg+xml;utf8,<svg width='4' height='32' viewBox='0 0 4 32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h4v32H0z' fill='var(--marketplaceColorEncoded)' fill-rule='evenodd'/></svg>");
      background-position: right center;
    } */
  }
}

.selectedLink {
  /* border-bottom-color: var(--matterColorDark); */
  /* color: var(--matterColorDark); */
  background: rgba(255, 255, 255, 0.1);
  border-right: 6px solid var(--marketplaceColor);
  /* font-weight: 600;
  font-size: 18px;
  line-height: 23px; */
  color: var(--matterColorLight);
  width: 100%;

  & svg{
    & path{
      stroke: var(--matterColorLight);
    }
  }

  @media (--viewportLarge) {
    width: 279px;
    /* background-image: url("data:image/svg+xml;utf8,<svg width='4' height='32' viewBox='0 0 4 32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h4v32H0z' fill='%23000' fill-rule='evenodd'/></svg>"); */
    background-position: right center;

    /* &:hover {
      background-image: url("data:image/svg+xml;utf8,<svg width='4' height='32' viewBox='0 0 4 32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h4v32H0z' fill='%23000' fill-rule='evenodd'/></svg>");
      background-position: right center;
    } */
  }
}

.fillSvg {
  & svg{
    & path{
      fill: var(--matterColorLight);
      stroke: none;
    }
  }
}

.disabled {
  pointer-events: none;
  color: var(--matterColorAnti);
  text-decoration: none;
}
