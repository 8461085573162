@import '../../../../marketplace.css';

.CompanyCards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 20px;
  flex-basis: 100%;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 7px 5px;
  }

  @media (--viewportMobile) {
    justify-content: center;
  }


  @media (--viewportLarge) {
    padding: 0;
    flex-basis: calc(50% - 12px);
    margin-right: 24px;
  }

  @media (--viewportXLarge) {
    flex-basis: calc(33.33% - 16px);
    margin-right: 24px;
  }
}

.CompanyCards:nth-of-type(2n) {
  @media screen and (min-width: 1024px) and (max-width: 1920px) {
    margin-right: 0;
  }
}

.CompanyCards:nth-of-type(3n) {
  @media (--viewportXLarge) {
    margin-right: 0;
  }
}
