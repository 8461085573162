@import '../../marketplace.css';

.packageItem {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 10px;
  padding: 10px;
  box-shadow: var(--boxShadowBreakdown);
  border-radius: var(--borderRadius);
  & p {
    margin: 0;
  }
}

.session {
  font-size: 16px;
  line-height: 18px;
  font-weight: bold;
}

.desc {
  font-size: 12px;
  line-height: 14px;
}

.price {
  font-size: 16px;
  line-height: 18px;
  font-weight: bold;
  /* color: var(--marketplaceColor); */
}

.section {
  display: flex;
  flex-direction: column;
  &:first-child {
    padding-right: 15px;
  }
}
