@import '../../marketplace.css';

.pagination {
  display: flex;
  justify-content: center;
}
.paginationLink {
  padding: 10px;
  cursor: pointer;
  &.current {
    border-bottom-style: solid;
    border-bottom-width: 3px;
    border-bottom-color: var(--matterColorDark);
    color: var(--matterColorDark);
  }
}
