@import '../../marketplace.css';

.reviews {
  margin-bottom: 24px;
  /* column-count: 2; */
  padding: 0;

  /* display: flex; */
  /* flex-wrap: wrap; */
  @media (--viewportMobile) {
    column-count: 1;
  }
}

.reviews :global(.slick-track) {
  /* width: 2400px !important; */

  & :global(.slick-cloned) {
    width: 200px !important;
  }
}

.reviews :global(.slick-dots li.slick-active button:before) {
  color: var(--marketplaceRedDarkColor) !important;
  font-size: 18px !important;
}

.reviews :global(.slick-dots li button:before) {
  color: var(--matterColorAnti) !important;
  font-size: 18px !important;
}

.reviews :global(.slick-dots li) {
  margin: 0 9px !important;
}

.reviews :global(.slick-dots) {
  bottom: -20px !important;
  position: relative !important;
}

.reviews :global(.slick-initialized .slick-slide) {
  width: 350px !important;

  @media (--viewportMobile) {
    width: 341px !important;
  }
}

.reviews :global(.slick-dots ul) {
  margin: 0px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 95%;
  width: 100%;
}

.link {
  color: var(--marketplaceColor);
  font-size: 16px;
  font-weight: bold;
}

.showAllButtonContainer {
  @media (--viewportMobile) {
    display: flex;
    justify-content: center;
    display: none;
  }
}

.seeAllButton {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 31px;
  text-align: center;
  text-transform: capitalize;
  color: var(--marketplaceColor);
  /* margin: 20px 0; */
  display: inline;
  padding: 0 30px;
  background-color: var(--matterColorLight);
  box-sizing: border-box;
  border-radius: 8px;

  @media (--viewportMobile) {
    line-height: 28px;
  }
}

.seeAllDownButton {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 31px;
  text-align: center;
  text-transform: capitalize;
  color: var(--marketplaceColor);
  display: inline;
  padding: 0 30px;

  @media (--viewportMobile) {
    display: block;
    margin: 67px auto 0 auto;
    border-radius: 8px;
    box-sizing: border-box;
  }
}

.LatestReviewHeading {
  display: flex;
  justify-content: flex-start;
  margin: 0 0 25px 0;
}

.reviewLength {
  font-weight: 600;

  @media (--viewportMobile) {
    font-weight: 400;
  }
}

.starContent {
  display: flex;
  align-items: center;
}

.ratingWrapper svg {
  margin-top: -4px;
}

.ratingWrapper svg path {
  fill: #fff;
  stroke: #346bf6;
  stroke-width: 4px;
  height: 24px;
}

.filledRating svg path {
  fill: #346bf6 !important;
}

.ratingWrapper {
  font-size: 16px;
  padding: 8px;
  font-weight: 500;
  display: flex;
  align-items: center;
  color: #346bf6;
  min-width: 100px;
  min-height: 41px;

  @media (--viewportLarge) {
    font-size: 14px;
  }
}

.reviewCount {
  font-weight: 600;
  font-size: 24px;
  line-height: 16px;
  color: #346bf6;
  margin-left: 5px;
}

.reviewCountWrapper {
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  color: rgba(52, 107, 246, 0.5);
  margin-left: 10px;
}