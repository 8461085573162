@import '../../marketplace.css';

.root {
  position: relative;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (--viewportLarge) {
    flex-direction: row;
    max-width: 1128px;
    margin: 0 auto 57px auto;
    padding: 0 36px;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

.txInfo {
  margin-bottom: 10px;

  @media (--viewportLarge) {
    flex-basis: 538px;
    flex-grow: 0;
    flex-shrink: 1;
    margin-right: 56px;
    margin-bottom: 0;
  }
}

.genericError {
  color: var(--failColor);
  margin: 24px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 32px 24px 0 24px;
  }

  @media (--viewportLarge) {
    margin: 32px 0 0 0;
  }
}

/* DetailCardImage subcomponent */
.detailCardImageWrapper {
  /* Layout */
  display: none;
  width: 100%;
  position: relative;
  border-radius: 12px 12px 0 0;

  @media (--viewportLarge) {
    display: block;
    max-height: 268px;
    overflow-y: hidden;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  padding-bottom: 66.6667%;
  /* 3:2 Aspect Ratio */
  background-color: var(--matterColorNegative);
  /* Loading BG color */
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  @media (--viewportLarge) {
    border-radius: 2px 2px 0 0;
  }
}

.avatarWrapper {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -30px;

  /* Rendering context to the same lavel as listing image */
  position: relative;
  /* Flex would give too much width by default. */
  width: 60px;

  @media (--viewportMedium) {
    margin-top: -32px;
    padding: 2px 0;
  }

  @media (--viewportLarge) {
    margin-left: 48px;
    width: unset;
    padding: 2px 0;
  }
}

/* Passed-in props for DetailCardImage subcomponent */
.imageWrapperMobile {
  position: relative;

  @media (--viewportLarge) {
    display: none;
  }
}

.avatarWrapperMobile {
  composes: avatarWrapper;

  @media (--viewportLarge) {
    display: none;
  }
}

.avatarWrapperDesktop {
  composes: avatarWrapper;
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

.avatarWrapperProviderDesktop {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin-top: 119px;
    width: 200px;
  }
}

.avatarDesktop {
  aspect-ratio: 1;
  @media (--viewportLarge) {
    display: flex;
  }
}

/* PanelHeadings subcomponent */
.headingOrder {
  margin: 24px;

  @media (--viewportMedium) {
    max-width: 80%;
    padding: 1px 0 7px 0;
    margin: 48px;
  }

  @media (--viewportLarge) {
    max-width: 100%;
    margin: 63px 0 30px 0;
    padding: 0;
  }
}

.headingSale {
  margin: 18px 24px 0 24px;
  padding: 5px 0 1px 0;

  @media (--viewportMedium) {
    max-width: 80%;
    margin: 48px 48px 0 48px;
    padding: 1px 0 7px 0;
  }

  @media (--viewportLarge) {
    max-width: 100%;
    margin: 63px 0 0 0;
    padding: 0;
  }
}

.mainTitle {
  display: block;
  line-height: 32px;
  font-weight: 500;
  font-size: 20px;
}

.transactionInfoMessage {
  margin: 18px 24px 0 24px;
  
  @media (--viewportMedium) {
    margin: 23px 24px 0 24px;
  }
  
  @media (--viewportLarge) {
    margin: 23px 0 0 0;
  }
  & > span {
    line-height: 32px;
    font-weight: 500;
    font-size: 20px;
  }
}

.noteInfoMessage {
  margin: 18px 24px 0 24px;
  font-size: 16px;
  line-height: 20px;

  @media (--viewportMedium) {
    margin: 23px 24px 0 24px;
  }

  @media (--viewportLarge) {
    margin: 50px 0 0 0;
  }
}

/* Container for booking details in mobile layout */
.bookingDetailsMobile {
  margin-top: 47px;

  @media (--viewportMedium) {
    margin-top: 40px;
    padding: 4px 0 0px 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

/* "aside" section in desktop layout */
.asideDesktop {
  margin: 1px 0 0 0;

  /**
   * Aside is visible on mobile layout too, since it includes BookingPanel component.
   * It might get rendered as a Modal in mobile layout.
   */

  @media (--viewportLarge) {
    margin: 63px 0 0 0;
  }
}

.detailCard {
  @media (--viewportLarge) {
    position: sticky;
    top: -200px;
    /* This is a hack to showcase how the component would look when the image isn't sticky */
    width: 369px;
    background-color: var(--matterColorLight);
    border: 1px solid var(--matterColorNegative);
    border-radius: 12px;
    z-index: 1;
  }
}

/* DetailCardHeadingsMaybe subcomponent */
.detailCardHeadings {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin: 0 48px 33px 48px;
  }
}

.detailsCardHeadingsMobile {
  margin: 0 24px 33px 24px;

  @media (--viewportSmall) {
    margin: 0 48px 33px 48px;
  }

  @media(--viewportLarge) {
    display: none;
  }
}

.detailCardTitle {
  margin-bottom: 10px;

  @media (--viewportLarge) {
    margin-top: 14px;
    margin-bottom: 0;
  }
}

.detailCardSubtitle {
  @apply --marketplaceH5FontStyles;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 9px;
    margin-bottom: 0;
  }
}

/* AddressLinkMaybe subcomponent */
.address {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorAnti);
  margin: 0;
}

/* Passed-in props for AddressLinkMaybe subcomponent */
.addressMobile {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorAnti);
  margin: 0 24px 24px 24px;
}

/* BreakdownMaybe subcomponent */
.breakdownMaybe {

  /* default "root" class for breakdown container */
  @media (--viewportMobile) {
    margin-bottom: 60px;
  }
}

.orderBreakdownTitle {
  /* Font */
  color: var(--matterColor);

  margin: 0 24px 0 24px;

  @media (--viewportLarge) {
    margin: 32px 48px 0px 48px;
    padding: 4px 0 4px 0;
  }
}

.breakdown {
  margin: 14px 24px;

  @media (--viewportLarge) {
    margin: 24px 24px 47px 24px;
  }

  @media (--viewportLaptopDown) and (--viewportMedium) {
    margin: 24px 48px 47px 48px;
  }
}

.breakdownContainer {
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

/* FeedSection subcomponent */
.feedHeading {
  color: var(--matterColorAnti);
  margin: 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.feed {
  margin-top: 20px;
}

.messageError {
  color: var(--failColor);
  margin: 13px 0 22px 0;

  @media (--viewportMedium) {
    margin: 13px 0 23px 0;
  }

  @media (--viewportLarge) {
    margin: 12px 0 23px 0;
  }
}

/* Passed-in props for FeedSection subcomponent */
.feedContainer {
  margin: 46px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 40px 48px 0 48px;
  }

  @media (--viewportLarge) {
    margin: 43px 0 0 0;
  }
}

/* Prop to be passed to SendMessageForm component */
.sendMessageForm {
  position: relative;
  margin: 46px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 48px 48px 0 48px;
    border-top: 0;
  }

  @media (--viewportLarge) {
    margin: 47px 0 0 0;
  }
}

.sendingMessageNotAllowed {
  color: var(--matterColorAnti);

  position: relative;
  margin: 46px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 49px 24px 0 24px;
    border-top: 0;
  }

  @media (--viewportLarge) {
    margin: 47px 0 0 0;
  }
}

.actionButton {
  display: flex;
  flex-direction: column;
  padding: 5px;
  margin: 20px 0;
}

/* SaleActionButtonsMaybe subcomponent */
.actionButtons {
  /* Position action button row above the footer */
  z-index: 9;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 0 24px;

  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  box-shadow: var(--boxShadowTop);
  background-color: white;

  @media (--viewportLarge) {
    z-index: unset;
    position: static;
    box-shadow: none;
    width: auto;
    margin: 10px 48px 0 48px;
    padding: 0;
  }
}

.actionButtonWrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin: 24px;

  @media (--viewportSmall) {
    margin: 48px;
  }

  @media (--viewportLarge) {
    flex-direction: column;
    margin: 0;
    /* flex-flow: column-reverse; */
  }

  & button {
    border-radius: 5px;
  }

  & button:first-child {
    margin: 20px 0;

    @media (--viewportLarge) {
      margin: 35px 0 0 0;
      display: flex;
      flex-direction: column;

      /* Switch order in desktop layout with accept button being on the top */
      order: 1;
    }
  }
}

.actionButtonOnlyDesktopWrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;

  & button {
    border-radius: 5px;
  }

  & button:first-child {
    margin: 20px 0;
  }
}

.actionError {
  @apply --marketplaceH5FontStyles;
  color: var(--failColor);
  margin: 0 0 11px 0;

  @media (--viewportMedium) {
    margin: 0 0 10px 0;
  }

  @media (--viewportLarge) {
    margin: 0;
  }
}

.actionErrors {
  width: 100%;
  text-align: center;

  @media (--viewportLarge) {
    position: absolute;
    top: 151px;
  }
}

/* Container for SaleActionButtonsMaybe subcomponent */
.mobileActionButtons {
  display: block;

  @media (--viewportLarge) {
    display: none;
  }
}

.desktopActionButtons {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin-bottom: 48px;
  }
}

/* BookingPanel subcompnent */
.bookingPanel {
  margin: 16px 48px 48px 48px;
}

.bookingTitle {
  /* Font */
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 1px;
}

.note {
  color: red;
  margin: 43px 0 24px 0;

  @media (--viewportMobile) {
    margin: 18px 24px 0 24px;
  }
}

.hitpay {
  font-weight: bold;
  margin: 43px 0 24px 0;

  @media (--viewportMobile) {
    margin: 18px 24px 0 24px;
  }
}

.paymentStatusSuccess {
  color: var(--successColor);
}

.paymentStatusFail {
  color: var(--failColor);
}

.membership,
.withCash {
  margin: 24px 0;

  @media (--viewportMobile) {
    margin: 24px 24px 24px 24px;
  }
}

.paymentProofSsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (--viewportMobile) {
    margin: 29px 24px 0 24px;
  }
}

.paymentProofSs img {
  width: 300px;
  padding: 15px;
}

.paymentScreenshots {
  @media (--viewportMobile) {
    margin: 29px 24px 0 24px;
  }
}

.remainingSessions {
  color: var(--matterColorPink);
  @apply --marketplaceH5FontStyles;
  margin: 0 48px 33px 48px;
}

.BookAgainButton {
  padding-top: 39px;
  position: sticky;
  top: 200px;

  @media (--viewportMobile) {
    padding-bottom: 74px;
    padding-top: 0px;
    width: 80%;
    margin: 0 auto;
  }
}

.whatsappButtonWrapper {
  margin-top: 15px;
  width: 155px;

  @media (--viewportMobile) {
    margin: 15px 0 0 24px;
  }
}

.TripSummaryWrapper {
  margin: 24px 24px 0 24px;

  @media (--viewportLaptopDown) and (--viewportMedium) {
    margin: 24px 48px 0 48px;
  }
}

/* Heading Lyfshort UserName */

.headerUserNameWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 5px 0 25px 0;

  @media (--viewportMobile) {
    justify-content: center;
    margin-bottom: 20px;
  }
}

.imgWrap {
  margin-right: 18px;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
}

.imgWrap img {
  max-width: 100%;
  height: auto;
}

.userNameWrapper {
  display: flex;
  width: 20px;
  align-items: center;
  object-fit: cover;

  & img {
    max-width: 100%;
    height: auto;
  }

  & p {
    @apply --marketplaceH5FontStyles;
    margin: 10px;
  }
}

.topSubHeading1 {
  font-weight: 200;
  font-size: 16px;
  line-height: 22px;
  margin: 10px 0;
  /* padding-left: 50px; */
  /* color: #6e6f71; */

  @media (--viewportMobile) {
    font-size: 20px;
  }
}

.msgBtn {
  width: 200px;
  float: right;
  margin: 30px 0;
}

.submitButton {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightMedium);

  float: right;
  padding: 0 16px;
  min-height: auto;
  min-width: 150px;
  height: 41px;

  display: flex;
  margin: 17px 15px;
  width: auto;
}

.acceptButton {
  margin-top: 20px;
  margin-bottom: 20px;
}

.warningText {
  color: var(--attentionColor);

  @media (--viewportLaptopDown) {
    margin: 48px;
  }

  @media (--viewportMobile) {
    margin: 24px;
  }
}

.timeLeftLabel {
  margin: 0;
  color: rgb(193, 55, 80);
  font-size: 20px;
  font-weight: 900;
}

.buttonsWrapper {
  margin-top: 24px;

  @media (--viewportLaptopDown) {
    margin: 48px;
  }

  @media(--viewportMobile) {
    margin: 24px;
  }
}

.linkWrapperClass a {
  color: white;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

.qrCodeContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px 0;

  & img {
    max-height: 200px;
  }
}

.continuePaymentButtonClass {
  @media (--viewportLaptopDown) {
    width: calc(100% - 96px);
    margin: 24px 48px;
  }
  @media (--viewportTabletDown) {
    width: calc(100% - 48px);
    margin: 12px 24px;
  }
}


.offerButtonFormWrapper {
  display: flex;
  justify-content: space-between;
  border: 1px solid #e7e7e7;
  border-radius: 12px;
  box-shadow: 0px 2.71962px 2.71962px rgb(0 0 0 / 25%);
  margin: 20px 0;
  padding: 10px 20px;
  
  @media (--viewportLaptopDown) {
    margin: 20px 48px;
  }

  @media (--viewportTabletDown) {
    flex-direction: column;
  }

  @media (--viewportMobile) {
    margin: 20px 24px;
  }
}

.descriptionWrapper {
  & p {
    font-weight: bold;
  }
  & ul {
    padding-inline-start: 20px;
  }
  & li {
    list-style-type: disc;
    font-size: 20px;
  }
}

.offerButtonWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 16px;
  height: 168px;

  & label {
    font-size: 20px;
    width: 200px;

    @media (--viewportTabletDown) {
      font-size: 18px;
      width: 100%;
    }
  }
}

.offerButtonClass {
  width: 160px;
  min-height: 40px;
  align-self: flex-end;
}

.validationMessage {
  color: var(--failColor);
  font-size: 16px;
  line-height: 24px;
  width: 200px;
  text-align: end;

  @media (--viewportTabletDown) {
    width: 100%;
  }
}

.modalContainerClass {
  @apply --marketplaceModalBaseStyles;
  display: flex;
  flex-direction: column;
  justify-content: center;
}