@import '../../marketplace.css';

.menuContent {
  margin-top: 7px;
  padding: 13px 10px 5px;
  min-width: 300px !important;
  border-radius: 4px;
  background-color: white;

  @media (--viewportMobile) {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    margin: auto;
  }
}

.statusCirc {
  background: green;
  height: 8;
  width: 8;
  border-radius: 4;
}

.menuItem {
  @apply --marketplaceListingAttributeFontStyles;
  color: var(--matterColor);

  /* Layout */
  position: relative;
  min-width: 300px;
  margin: 0 0 10px 0;
  padding: 4px 15px;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  white-space: nowrap;

  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--matterColorDark);
  }

  &:hover .menuItemBorder {
    width: 6px;
  }
}

.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  background-color: var(--marketplaceColor);
  transition: width var(--transitionStyleButton);
}

.label {
  @apply --marketplaceButtonStylesSecondary;
  padding: 5px 0;
  min-width: 142px;
  min-height: 30px;
}
