@import '../../marketplace.css';


.root {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 77px;
  /* padding: 0 calc((100% - 1056px) / 2) 82px 59px; */
  padding: 10px 50px;

  @media (--viewportMobile) {
    gap: 0 30px;
  }
}

.arrowIcon {
  fill: transparent;
  cursor: pointer;
}

.disableArrow{
  cursor: not-allowed;
  & path{
    stroke: grey;
  }
}

.dotWrapper {
  display: flex;
  align-items: center;
  gap: 0 66px;

  @media(--viewportMobile) {
    gap: 0 30px;
  }
}

.dot {
  width: 15px;
  height: 15px;
  border-radius: 28px;
  background: #D9D9D9;
}

.selectedDot {
  background: #EE3E22;
}

.arrowSize {
  width: 38px;
  height: 38px;
}

.arrowBtnGroup {
  display: flex;
  justify-content: center;
  gap: 0 55px;
  margin-bottom: 50px;
  margin-top: 20px;

}
