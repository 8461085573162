@import '../../marketplace.css';

.checkboxWrap {
  width: 75%;
  display: flex;
  justify-content: left;
  @media (--viewportMobile) {
    width: 100%;
    /* justify-content: center; */
    flex-wrap: wrap;
    /* padding: 0 14px; */
    column-gap: 16px;
  }
}

.checkboxWrapWithImage {
  width: 100%;
}

.checkboxInput {
  display: none;
  &:checked {
    & ~ label {
      & .checkboxLabelWithImage {
        font-weight: 700;
        font-size: 19px;
        line-height: 15px;
        text-transform: uppercase;
        background: linear-gradient(95.99deg, #ffaa00 3.98%, #ff7a00 96.72%);
        background-repeat: no-repeat;
        border: none;
        padding: 0 12px !important;
        grid-gap: 0 10px;
        gap: 0 10px;
        justify-content: start;
        & img {
          display: block;
        }
        @media (--viewportMobile) {
          padding: 0 12px 0 10px !important;
          height: 29px;
          min-width: 83px;
          font-size: 10px;
          line-height: 13px;
          & img {
            width: 15px;
            height: 15px;
          }
        }
      }
      & span {
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        text-transform: capitalize;
        color: var(--matterColorLight);
        background: var(--marketplaceColorBackground);
        background-image: url('../../assets/roundcheck1.png');
        background-size: 15px;
        background-repeat: no-repeat;
        background-position-y: 13px;
        background-position-x: 10px;
        padding: 0 0 0 12px;
        box-shadow: 0px 10px 20px rgb(0 0 0 / 10%);

        /* border: 0; */
        @media (--viewportMobile) {
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          background-position-x: 8px;
          padding: 0 0 0 20px;
        }
      }
    }
  }
}

.checkboxLabel {
  padding: 0 0 0 12px;
  background-color: var(--matterColorLight);
  color: var(--marketplaceColorDarker);
  border: 1px solid var(--matterColorNegative);
  font-weight: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  text-transform: capitalize;
  color: var(--matterColorDark);

  cursor: pointer;
  height: 41px;
  min-width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background-image: url('../../assets/roundcheck2.png');
  background-position-y: 13px;
  background-position-x: 10px;
  background-size: 15px;

  &:first-child {
    border-radius: 5px 0 0 5px;
  }
  & img {
    display: none;
  }
  @media (--viewportMobile) {
    padding: 10px 25px;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    background-position-x: 5px;
  }
}

.checkboxLabelWithImage {
  padding: 0 20px 0 44px !important;
  max-width: fit-content;
  border: none;
  margin: -20px auto 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 15px;
  position: absolute;
  bottom: 14px;
  left: 19px;
  height: 45px;
  min-width: 140px;
  text-transform: uppercase;
  background-position-y: 10px;
  background-position-x: 14px;
  background-size: 24px;
  & img {
    display: none;
  }
  @media (--viewportMobile) {
    padding: 0 12px 0 31px !important;
    height: 29px;
    background-position-y: 8px;
    background-position-x: 10px;
    background-size: 15px;
    min-width: 83px;
    font-size: 10px;
    line-height: 13px;
  }
}

.checkboxItem {
  margin: 18px 18px 0 0;
  position: relative;
  @media (--viewportMobile) {
    /* margin: 0 14px 27px 0; */
    margin: 0 0 27px 0;
    max-width: 50%;
  }
  &:first-child {
    & .checkboxLabel {
      /* padding: 0; */
    }
  }
  &:last-child {
    & .checkboxLabel {
      /* border-radius: 0 25px 25px 0; */
      padding: 0 0 0 20px;
    }
  }
}

.sliderCheckboxItem {
  margin: 18px 18px 0 0;
  position: relative;
  @media (--viewportMobile) {
    /* margin: 0 14px 27px 0; */
    margin: 0 0 27px 0;
    max-width: 50%;
  }
  &:first-child {
    & .checkboxLabel {
      /* padding: 0; */
    }
  }
  &:last-child {
    & .checkboxLabel {
      /* border-radius: 0 25px 25px 0; */
      padding: 0 0 0 20px;
    }
  }
}

.imageWrap {
  width: 336px;
  height: 236px;
  cursor: pointer;
  @media (--viewportMobile) {
    width: 170px;
    height: 120px;
  }
  & img {
    width: 100%;
    height: 100%;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 14px;
    object-fit: cover;
    @media (--viewportMobile) {
      border-radius: 9px;
    }
  }
}

.imageDisabled {
  display: none;
}

.ArrowLeft {
  width: 35px;
  height: 35px;
  position: absolute;
  top: -47px;
  right: 114px;
  @media (--viewportMobile) {
    top: auto;
    right: auto;
    bottom: -20px;
    left: 0px;
    z-index: 0 !important;
  }
}

.ArrowRight {
  width: 35px;
  height: 35px;
  position: absolute;
  top: -47px;
  right: 0;
  @media (--viewportMobile) {
    top: auto;
    bottom: -20px;
    right: 5px;
    z-index: 0 !important;
  }
}

.dotsStyle {
  position: relative;
}
.sliderCheckboxWrap :global(.slick-dots li.slick-active button:before) {
  color: var(--marketplaceColorLight);
  font-size: 10px;
}
.sliderCheckboxWrap :global(.slick-dots li button:before) {
  color: var(--matterColorDarkBlue);
  font-size: 10px;
  opacity: 1;
}
.sliderCheckboxWrap :global(.slick-dots li) {
  margin: 0 4px !important;
}
.sliderCheckboxWrap :global(.slick-dots) {
  bottom: -20px !important;
  position: relative !important;
}

.sliderCheckboxWrap :global(.slick-slide) {
  @media (--viewportMobile) {
    margin: 0 22px 0 0;
    width: 166px !important;
  }
}

.sliderCheckboxWrap :global(.slick-track) {
  @media (--viewportMobile) {
    width: 600px !important;
  }
}

.sliderCheckboxWrap :global(.slick-dots) ul {
  width: 15%;
  position: absolute;
  top: -335px;
  right: -14px;
  @media (--viewportMobile) {
    top: auto !important;
    width: auto;
    right: 45% !important;
    bottom: 7px;
  }
  @media (--viewportTabletDown) {
    top: -601px;
    right: 3%;
  }
}
