@import '../../marketplace.css';

.root {}

.pageRoot {
  display: flex;
  /* padding-bottom: 90px; */

  @media (--viewportLarge) {
    padding-bottom: 0;
  }

  @media (--viewportMobile) {
    /* padding-bottom: 163px; */
  }
}

.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--failColor);
  margin: 24px;
}

.sectionImages {}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  position: relative;
  /* allow positioning own listing action bar */
  padding-bottom: 0;
  /* 3:2 Aspect Ratio */

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;
  background: url('../../assets/slider_bg.png');
  background-size: cover;
  background-position: center center;

  @media (--viewportMedium) {
    padding-bottom: 0;
    /* No fixed aspect on desktop layouts */
  }

  @media (--viewportMobile) {
    background: url('../../assets/slider_bg_mobile.png');
  }
}

.actionBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px;

  width: calc(100% - 10px);
  max-width: 1660px;
  color: var(--matterColorNegative);
  background-color: var(--marketplaceColorBackground);
  z-index: 5;
  /* bring on top of mobile image */

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;

  border-radius: var(--borderRadius);
}

.ownListingText {
  @apply --marketplaceH4FontStyles;
  margin: 14px 12px 11px 24px;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }

  & span {
    font-size: 22px;
  }

  @media (--viewportMobile) {
    & span {
      font-size: 15px;
    }
  }
}

.ownListingTextPendingApproval {
  color: var(--attentionColor);
}

.closedListingText {
  @apply --marketplaceH4FontStyles;
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.editListingLink {
  @apply --marketplaceButtonStyles;
  width: 200px;
  padding: 0 15px;
  margin: auto 20px auto 0;

  @media (--viewportMobile) {
    width: 150px;
  }

  & span {
    font-size: 18px;

    @media (--viewportMobile) {
      font-size: 13px;
    }
  }
}

.editIcon {
  margin: -6px 7px 0 0;
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    position: static;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    height: calc(0.41 * 100vw);
    max-height: 50vh;
    object-fit: cover;
    border-radius: var(--borderRadius);

    &:hover {
      transform: scale(1.005);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.viewPhotos {
  @apply --marketplaceTinyFontStyles;
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 24px;
  margin: 0;
  padding: 6px 13px 8px 13px;

  /* Colors */
  background-color: var(--matterColorLight);

  /* Borders */
  border: none;
  z-index: 1;
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.contentContainer {
  margin-top: -6px;
  justify-content: space-between;
  border-bottom: 1.5px solid var(--matterColorNegative);

  @media (--viewportMedium) {
    max-width: 1154px;
    margin: 0 auto;
    padding: 0 24px 81px;
    display: flex;
    position: relative;
  }

  @media (--viewportLarge) {
    padding: 0 24px 81px;
  }

  @media (--viewportLaptop) {
    /* padding: 0 12px 50px; */
    padding: 0 0 81px;
    margin: 20px auto 61px;
  }

  @media (max-width: 768px) {
    margin: 0 24px;
  }

  @media (--viewportMobile) {
    margin: 0 19px;
  }
}

.mainContent {
  flex-basis: 100%;
  padding-right: 80px;

  @media (--viewportMobile) {
    padding-right: 0;
  }

  @media (min-width: 1440px) {
    /* contentContainer's full width - (bookingColumn + two paddings + margin + border) */
    /* max-width: calc(100% - 589px);
    flex-basis: calc(100% - 589px);
    flex-shrink: 0;
    flex-grow: 1; */
    padding-right: 42px;
  }

  @media (--viewportLaptopDown) {
    max-width: 100%;
    flex-shrink: 1;
    flex-grow: 1;
  }
}

.companyMainContent {
  max-width: 100%;
  flex-basis: 100%;
}

.bookingPanel {
  padding: 17px 25px 21px 25px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  display: block;
  width: 404px;
  justify-content: flex-end;

  /* @media (--viewportLaptopDown) {
    border: 0px;
    box-shadow: none;
    padding: 20px;
    width: 100%;
  } */
  @media (--viewportLarge) {
    box-shadow: 0 0 8px var(--matterColorAnti);
  }

  @media (max-width: 768px) {
    padding: 0;
  }
}

.sectionAvatar {
  /* Position (over the listing image)*/
  margin-left: 24px;
  /* margin-top: -72px; */

  /* Rendering context to the same lavel as listing image */
  /* position: relative; */

  /* Flex would give too much width by default. */
  /* width: 60px; */

  @media (--viewportMedium) {
    /* position: absolute;
    top: -112px;
    margin-left: 0;
    margin-top: 0; */
  }
}

.avatarMobile {
  display: flex;
  width: 96px;
  height: 96px;

  @media (--viewportMedium) {
    display: none;
    /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  display: none;
  /* Hide the large avatar from the smaller screens */
  width: 152px;
  height: 152px;

  @media (--viewportMedium) {
    display: flex;
  }
}

.initialsDesktop {
  font-size: 48px;
  font-weight: var(--fontWeightBold);
  padding-bottom: 8px;
}

.sectionHeading {
  width: calc(100vw - 240px);

  /* margin-top: 22px;
  margin-bottom: 34px; */
  @media (--viewportMedium) {
    display: flex;
  }

  @media (--viewportLarge) {
    padding: 15px;
  }
}

.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
  }
}

.desktopPriceValue {
  /* Font */
  @apply --marketplaceH1FontStyles;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 6px;
  }
}

.desktopPerUnit {
  /* Font */
  @apply --marketplaceH5FontStyles;
  color: var(--matterColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.heading {

  /* margin-bottom: 33px; */
  /* margin: 19px 100px 19px 0; */
  @media (--viewportMobile) {
    margin: 0;
  }
}

.title {
  /* Font */
  /* @apply --marketplaceH1FontStyles; */

  /* Layout */
  font-weight: bold;
  font-size: 45px;
  line-height: 75px;
  text-transform: capitalize;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  color: var(--matterColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 41px;
  }

  @media (--viewportMobile) {
    font-size: 30px;
    line-height: 45px;
    margin-bottom: 34px;
  }
}

.accoladesTitle {
  font-weight: bold;
  font-size: 30px;
  line-height: 38px;
  color: var(--marketplaceColorDarkest);
  margin-top: 54px;
  margin-bottom: 21px;

  @media (--viewportMobile) {
    margin-top: 0;
    margin-bottom: 32px;
  }
}

.author {
  width: 100%;
  @apply --marketplaceH4FontStyles;
  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--matterColor);
  }
}

.authorNameLink {
  color: var(--matterColor);
}

.contactWrapper {
  @media (--viewportMedium) {
    display: inline;
  }
}

.separator {
  margin: 0 6px;
}

.contactLink {
  border: 1px solid var(--marketplaceColorDarker);
  color: var(--marketplaceColorDarker);
  margin: 55px 0 0;
  width: 137px;
  min-height: 42px;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: var(--matterColorLight);
  display: flex;
  align-items: center;
  justify-content: center;

  & span {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.3px;
    text-align: center;
    text-transform: capitalize;
    color: var(--marketplaceColorDarker);

    @media (--viewportMobile) {
      font-size: 22px;
      line-height: 31px;
    }
  }

  @media (--viewportMobile) {
    margin: 0 0 59px 0;
    width: 220px;
    height: 65px;
  }
}

.bookFromDescription {
  display: inline;
  width: auto;
  min-width: 160px;
  min-height: 40px;
  margin: 0 12px 12px 0;
  background-color: var(--matterColorLight);
  color: var(--marketplaceColor);
  border-color: var(--marketplaceColor);
  box-shadow: 0px 10px 24px 3px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

.bookFromDescription {
  margin-right: 0;
}

.descriptionTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 13px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 20px;
  }
}

.sectionFeatures {
  padding: 0 24px;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 52px;
  }
}

.featuresTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 16px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 20px;
  }
}

.description {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 17px;
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.more {
  color: var(--marketplaceColorSecond);
}

.more:hover {
  cursor: pointer;
}

.sectionMap {
  padding: 0 24px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
  }
}

.locationTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 20px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 28px;
  }
}

.sectionReviews {
  padding: 0 24px;
  margin-bottom: 5px;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    margin-bottom: 3px;
  }
}

.reviewsHeading {
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);
  display: none;
  margin: 52px 0 20px 0;

  @media (--viewportMedium) {
    margin: 53px 0 27px 0;
  }

  @media (--viewportLarge) {
    margin: 50px 0 26px 0;
  }
}

.sectionHost {
  position: relative;
  padding: 0 24px;
  margin-bottom: 5px;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    margin-bottom: 3px;
  }
}

.yourHostHeading {
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin: 54px 0 23px 0;

  @media (--viewportMedium) {
    margin: 53px 0 27px 0;
  }

  @media (--viewportLarge) {
    margin: 53px 0 27px 0;
  }
}

.enquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.enquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.enquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.publicData {
  /* background: #223046; */
  /* padding: 20px 0; */
}

.avatarContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
}

.count {
  position: absolute;
  color: white;
  z-index: 99;
  background-color: black;
  padding: 5px 10px;
  border-radius: 15px;
  left: 2%;
  top: 520px;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 1px;

  @media (--viewportLarge) {
    top: 450px;
    margin-left: 15px;
  }
}

.adventure {
  padding-left: 25px;
  font-size: 20px;
  font-weight: 400;
}

.ratingContent {
  font-size: 15px;
  display: flex;
  align-items: baseline;

  &>span {
    padding-right: 12px;
    margin-right: 12px;
    border-right: 1px solid #cbd2e4;
  }

  @media (--viewportMobile) {
    padding-bottom: 16px;
    font-size: 13px;
    flex-wrap: wrap-reverse;
    border-bottom: 1px solid var(--matterColorNegative);
  }
}

.ratingRating {
  display: flex;
  align-items: center;
  font-weight: bold;
  height: 24px;
}

.review {
  margin-top: 2px;
  margin-left: 6px;
}

.rating {
  margin-right: 5px;
  margin-top: 4px;
}

.ratingBookings {
  border-right: none !important;
  color: #9097a3;

  @media (--viewportMobile) {
    font-size: 13px;
    font-weight: bold;
  }
}

.feature {
  /* margin: 5px 10px 10px 22px; */
}

.feature_item {
  font-size: 11px;
  background-color: #757575;
  padding: 2px 10px;
  color: white;
  margin: 3px;
  border-radius: 4px;
  display: inline-block;
}

.property {
  display: block;
  flex-wrap: wrap;
  list-style: none;
  border-top: 1.5px solid var(--matterColorNegative);
  border-bottom: 1.5px solid var(--matterColorNegative);
  padding: 26px 0 0;
  margin: 0 0 28px 0;

  /* padding: 20px 0 4px; */
  @media (--viewportMobile) {
    padding: 47px 0 35px 0;
    border-bottom: 0;
  }
}

.propertyTitle {
  letter-spacing: 0.5px;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  color: var(--marketplaceColorDarker);

  /* color: var(--matterColorLight); */

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    /* margin-bottom: 5px; */
  }

  @media (--viewportMobile) {
    font-size: 20px;
    line-height: 28px;
  }
}

.property .desc {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  margin-top: 4px;
  color: var(--matterColorDimmedText);

  /* color: var(--matterColorLight); */
  @media (--viewportMobile) {
    font-size: 18px;
    line-height: 23px;
    margin-top: 4px;
  }
}

.propertyItem {
  flex: 0 50%;
  margin-bottom: 34px;
  display: flex;

  /* align-items: center; */
  /* color: var(--matterColorLight); */
  & svg {
    width: 44px;
    height: 44px;
    fill: var(--attentionColor);
  }
}

.itemIcon {
  margin-bottom: 3px;
  margin-right: 3px;
  /* color: var(--matterColorLight); */
}

.itinerary {
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.itineraryContent {
  min-width: 0px;
  width: 100%;
  padding: 0px 20px;
  margin-top: -8px;
}

.itineraryBtn {
  border: none;
  width: 100%;
  padding: 0px;
  background: transparent;
  text-align: inherit;
  cursor: pointer;
  display: block;
  margin-top: 0px;
  margin-bottom: 0px;
}

.itineraryBtnContent {
  display: flex;
  margin-left: -4px;
  padding-left: -4px;
}

.itineraryLeftLine {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  position: relative !important;
  display: -webkit-box !important;
  display: -moz-box !important;
  display: -ms-flexbox !important;
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-justify-content: center !important;
  justify-content: center !important;
  /* margin-left: 20px; */
  margin-right: 20px;
}

.itineraryRightContent {
  -webkit-flex: 1 !important;
  -ms-flex: 1 1 0% !important;
  flex: 1 !important;
  padding-bottom: 24px !important;
}

.itineraryItemtitle {
  margin-bottom: 12px;
}

.itineraryItemtitle span {
  font-size: 16px;
  word-wrap: break-word;
}

.itineraryRightContent .content .background {
  position: relative !important;
  background-position: 50% 50% !important;
  background-repeat: no-repeat !important;
  padding-top: 66.6667%;
}

.itineraryRightContent .content .background .backContent {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  position: absolute !important;
  top: 0px !important;
  bottom: 0px !important;
  left: 0px !important;
  right: 0px !important;
  display: -webkit-box !important;
  display: -moz-box !important;
  display: -ms-flexbox !important;
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-align-items: center !important;
  align-items: center !important;
  -webkit-justify-content: center !important;
  justify-content: center !important;
}

.itineraryRightContent .content .background .backContent img {
  height: 100% !important;
  width: 100% !important;
  position: static !important;
  top: 0px !important;
  bottom: 0px !important;
  left: 0px !important;
  right: 0px !important;
  object-fit: cover;
}

.line {
  background-color: var(--matterColorGrey) !important;
  width: 1px !important;
  position: absolute !important;
  top: 7px !important;
  bottom: 0px !important;
}

.dot {
  position: absolute !important;
  top: 7px !important;
  background: var(--marketplaceColorLightWhite) !important;
  border: 1px solid var(--matterColorGrey) !important;
  border-radius: 100% !important;
  width: 7px !important;
  height: 7px !important;
}

.backDescription {
  margin-top: 15px;
}

.backDescriptionTitle {
  font-size: 16px !important;
  font-weight: 800 !important;
  line-height: 1.375em !important;
  color: var(--matterColorGrey) !important;
}

.backDescriptionDesc {
  margin-top: 8px;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 1.375em !important;
  color: var(--matterColorGrey) !important;
}

.backDescriptionShowMore {
  color: var(--marketplaceColorSecond) !important;
  margin-top: 24px;
  font-weight: 700;
  font-size: 14px;
}

.itineraryTitle h2 {
  padding: 0px 25px;
}

.slider img {
  height: 500px;
  object-fit: cover;

  /* -webkit-animation: myMove 6s infinite ease-in-out alternate;
  -moz-animation: myMove 6s infinite ease-in-out alternate;
  -o-animation: myMove 6s infinite ease-in-out alternate;
  -ms-animation: myMove 6s infinite ease-in-out alternate;
  animation: myMove 6s infinite ease-out alternate; */
  @media (--viewportLarge) {
    width: initial;
    height: 400px;
    margin: auto;
  }
}

.slider {
  margin-bottom: 50px;

  @media (--viewportLarge) {
    padding: 30px;
  }
}

@keyframes myMove {
  from {
    transform: scale(1, 1);
  }

  to {
    transform: scale(1.1, 1.1);
  }
}

@-webkit-keyframes myMove {
  from {
    -webkit-transform: scale(1, 1);
    -webkit-transform-origin: 50% 50%;
  }

  to {
    -webkit-transform: scale(1.1, 1.1);
    -webkit-transform-origin: 50% 0%;
  }
}

@-o-keyframes myMove {
  from {
    -o-transform: scale(1, 1);
    -o-transform-origin: 50% 50%;
  }

  to {
    -o-transform: scale(1.1, 1.1);
    -o-transform-origin: 50% 0%;
  }
}

@-moz-keyframes myMove {
  from {
    -moz-transform: scale(1, 1);
    -moz-transform-origin: 50% 50%;
  }

  to {
    -moz-transform: scale(1.1, 1.1);
    -moz-transform-origin: 50% 0%;
  }
}

@-ms-keyframes myMove {
  from {
    -ms-transform: scale(1, 1);
    -ms-transform-origin: 50% 50%;
  }

  to {
    -ms-transform: scale(1.1, 1.1);
    -ms-transform-origin: 50% 0%;
  }
}

.reviewTitle {
  padding-left: 20px;
  font-weight: bold;
}

.totalReviewContainer {
  padding-left: 20px;
  display: flex;
  align-items: center;
}

.video {
  width: 100%;
}

.buttonWrap {
  margin-top: 10px;
  padding-left: 24px;
  padding-right: 24px;
}

.showAll {
  text-align: center;
  font-size: 17px;
  border: 1px solid var(--marketplaceColorBackground);
  padding: 14px 28px;
  color: var(--matterColor);
  font-weight: bold;
  border-radius: 5px;
}

.slider {
  margin-left: 35px;
  margin-right: 35px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.slider button:before {
  color: black;
  clear: both;
}

.sliderImageContainer img {
  width: 100%;

  @media (--viewportMobile) {
    height: 300px;
  }
}

.sliderImageContainer {
  margin: 0px 5px;
  width: 100%;
}

.listedBy {
  @media (--viewportMobile) {
    width: 100%;
    border-right: none !important;
  }
}

.dotSeparator {
  font-size: 24px;
  margin: 0px 10px;
}

.actionBarWrapper {
  display: flex;
  justify-content: center;
}

.enquiryActionBarWrapper {
  position: relative;
  display: flex;
  justify-content: center;

  & div {
    @media (--viewportMobile) {
      top: 13px;
    }
  }
}

.sectionWrapper {

  /* margin: 0 24px 18px; */
  /* margin-left: 10px; */

  & h2 {
    margin: 0;
  }
}

.sectionWhatYouCanExpect {
  /* margin: 0 24px 18px; */
  background: var(--marketplaceColorBackground);

  & h2 {
    margin: 0;
  }
}

.sectionWrapperWithFlex {
  display: flex;

  & h2 {
    margin: 0;
  }
}

.sectionDescription {
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 45px;
  color: var(--matterColorDimmedText);
  margin-bottom: 43px;

  @media (--viewportMobile) {
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 36px;
  }
}

.packagesModal {
  height: auto;

  @apply --marketplaceModalBaseStyles;

  padding: 0;
  background-color: var(--matterColorLight);
  /* margin-top: 5vh; */
  margin-bottom: 5vh;

  @media (--viewportMedium) {
    flex-basis: 80%;
  }

  max-width: 80%;

  @media (--viewportMobile) {
    padding: 0;
    max-width: 100%;
  }
}

/* Inquire styles */
.inquireMainContent {
  margin-top: 105px;
  flex-basis: 50%;
}

.enquirySidePanel {
  display: block;
  margin-top: 95px;
  margin-left: 60px;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: var(--matterColorNegative);

  padding-top: 40px;
  padding-left: 60px;

  /* The calendar needs 312px */
  flex-basis: 312px;
  flex-shrink: 0;

  margin-left: 30px;
  margin-top: 80px;
  width: max-content;

  @media (--viewportMobile) {
    margin: 0;
    padding-left: 20px;
  }
}

.category {
  color: var(--matterColorDarkBlue);
  margin-top: 5px;
  margin-bottom: 0px;
  font-size: 16px;
  text-align: left;
  line-height: 18px;
  font-weight: 600;

  & span {
    color: var(--matterColorDarkBlue);
    margin-top: 5px;
    margin-bottom: 0px;
    font-size: 16px;
    text-align: left;
    line-height: 14px;
    font-weight: 400;
  }
}

.subCategory {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 43px;
  color: var(--marketplaceColor);
  margin-top: 5px;
  margin-bottom: 0px;
  text-align: left;

  & span {
    font-weight: normal;
    font-size: 24px;
    line-height: 38px;
    color: #c4c4c4;
    /* padding: 0 0 0 10px; */
    margin: 0 20px 0 0;
  }
}

.name {
  font-size: 26px;
  font-weight: var(--fontWeightMedium);
}

.imageSlider :global .slick-slide {
  /* padding-right: 20px; */
  padding: 0 10px;

  @media (--viewportMobile) {
    padding-right: 0;
  }
}

.singleImageContainer {
  margin: 20px auto;
  border-radius: 12px;

  @media (max-width: 767px) {
    padding: 23px 20px;
  }
}

.mapImage {
  border-radius: 12px;
  overflow: hidden;
}

.map {
  width: 100%;
  aspect-ratio: 19 / 15;

  /* Static map: dimensions are 640px */
  padding: 0;
  border-radius: 12px;
  border: 5px solid var(--matterColorLight);
  cursor: pointer;
}

.image {
  width: 100%;
  aspect-ratio: 19 / 15;
  object-fit: cover;
  border-radius: 9px;
  /* border: 5px solid var(--matterColorLight); */
}

.cancelPolicy {
  padding: 16px 0 0 0;
  border-top: 1.5px solid var(--matterColorNegative);

  & h2 {
    font-weight: bold;
    font-size: 23px;
    line-height: 29px;
    color: #272727;

    @media (--viewportMobile) {
      font-size: 25px;
      line-height: 31px;
    }
  }

  & p {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    color: var(--matterColorDimmedText);
  }

  @media (--viewportMobile) {
    font-size: 18px;
    line-height: 30px;
    padding: 0 25px;
    border-top: 0;
  }
}

.cancelPolicySection {
  max-width: 1154px;
  margin: 40px auto 49px;
  padding: 0 24px;

  @media (--viewportMobile) {
    margin: 0 auto 31px;
  }
}

.promoListings {
  margin: 0 auto 77px;
  max-width: 1154px;

  @media (max-width: 768px) {
    padding: 0 24px;
  }

  @media (--viewportMobile) {
    padding: 0 25px;
    margin: 0 auto 60px 0;
  }
}

.promoListings :global(.LandingPagePromotedListings_newheader__1AytE) {
  padding: 28px 0 27px;

  @media (--viewportMobile) {
    padding: 0 0 40px;
  }
}

.promoListings :global(.LandingPagePromotedListings_title__2lSmp) {
  & span {
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;

    color: #272727;

    @media (--viewportMobile) {
      font-size: 30px;
      line-height: 38px;
    }
  }
}

.packagesDisplay {
  max-width: 1154px;
  margin: 0 auto;

  @media (--viewportMobile) {
    margin: 0 0 0 22px;
  }

  & h2 {
    & span {
      font-weight: bold;
      font-size: 30px;
      line-height: 38px;
      color: #272727;

      @media (--viewportMobile) {
        font-weight: 600;
        font-size: 30px;
        line-height: 38px;
      }
    }
  }
}

.sectionShare {
  display: flex;
}

.shareBtnWrapper {
  display: flex;
  position: absolute;
  z-index: 1;
  top: 30px;
  right: 50px;

  @media (--viewportMobile) {
    right: 20px;
  }
}

.shareBtnWrapper>.root>img {
  width: 20px;
  height: 20px;
}

.shareButton {
  width: 42px;
  height: 42px;
  background: #f5f5f5;
  border-radius: 22px;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin-right: 22px;

  /* @media (--viewportMobile) {
    width: 94px;
  } */
}

.likeButton {
  width: 42px;
  height: 42px;
  background: #f5f5f5;
  border-radius: 22px;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  cursor: pointer;

  /* @media (--viewportMobile) {
    width: 94px;
  } */
}

.whatsapp {
  margin-left: 20px;

  & svg {
    width: 38px;
    height: 38px;
  }
}

.contactWrapper {
  display: flex;
}

.paragraphButton {
  color: var(--marketplaceColor);
  cursor: pointer;
}

.paragraphDescription {
  font-family: 'Source Sans Pro';
  font-weight: normal;
  font-size: 18px;
  line-height: 35px;
  color: var(--matterColorDark);
  margin: 0;
  white-space: break-spaces;

  @media (--viewportMobile) {
    font-size: 18px;
    line-height: 35px;
  }
}

.SectionHead {
  display: flex;
  justify-content: space-between;
  /* margin-top: 20px; */
  max-width: 1501px;
  margin: 0px 120px -30px 120px;
  width: 100%;

  @media (--viewportLaptopDown) and (--viewportMedium) {
    margin: 0 50px;
  }

  @media (--viewportTabletDown) {
    margin: 18px 50px;
    padding: 0 24px;
  }

  @media (--viewportMobile) {
    padding: 0 20px;
    margin: 0px 12px -48px 12px;
  }
}

.topXpertExtra {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: 0;
  gap: 10px;

  @media (--viewportMobile) {
    justify-content: center;
    max-width: 300px;
    margin: 20px;
  }
}

.headerDetailsContainer {
  width: 100%;
  margin-bottom: 10px;

  @media (--viewportMobie) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.nameWrapper {
  display: flex;
  align-items: flex-end;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 38px;
  height: 49px;
  text-align: center;
  color: #000000;

  @media (--viewportMobile) {
    flex-direction: column;
    align-items: center;
  }
}

.starContent {
  display: flex;
  align-items: center;
}

.ratingWrapper svg {
  margin-top: -4px;
}

.ratingWrapper svg path {
  fill: var(--matterColorLight);
  stroke: #346bf6;
  stroke-width: 4px;
  height: 24px;
}

.filledRating svg path {
  fill: #346bf6 !important;
}

.ratingWrapper {
  font-size: 16px;
  padding: 8px;
  font-weight: 500;
  background: #f5f5f5;
  border-radius: 5px;
  display: flex;
  align-items: center;
  color: #346bf6;
  min-width: 100px;
  min-height: 41px;

  @media (--viewportLarge) {
    font-size: 14px;
  }
}

.reviewCount {
  font-weight: 600;
  font-size: 24px;
  line-height: 16px;
  color: #346bf6;
  margin-left: 5px;
}

.reviewCountWrapper {
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  color: rgba(52, 107, 246, 0.5);
  margin-left: 10px;
}

.IdentityWrapper span {
  margin-left: 12px;
}

.IdentityWrapper {
  background: #f5f5f5;
  border-radius: 5px;
  display: flex;
  align-items: center;
  max-width: 104px;
  padding: 11px;
  height: 41px;
}

.IdentityWrapper span {
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  text-align: right;
  color: #6e6f71;
}

.headerMainDetailsWrapper {
  display: flex;
  justify-content: space-between;

  @media (--viewportMobile) {
    flex-direction: column;
    justify-content: center;
  }
}

.headerUserNameWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 5px 0 25px 0;

  @media (--viewportMobile) {
    justify-content: center;
    margin-bottom: 20px;
  }
}

.headerExpertDetailsWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (--viewportMobile) {
    align-items: center;
  }
}

.headerAddressDetailsWrapper1 {
  display: flex;
  /* align-items: center; */
  align-items: end;
  justify-content: flex-start;
  min-height: 49px;
}

.headerAddressDetailsWrapper2 {
  display: flex;
  align-items: end;
  justify-content: flex-start;
  /* margin: 8px 0; */
}

.headerUserNameWrapper .imgWrap img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

.imgWrap {
  margin-right: 18px;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bannerContainer {
  display: flex;
  justify-content: center;
}

.banner {
  max-width: 1660px;
  width: 100%;
  position: relative;
}

.banner>img {
  width: 100%;
  aspect-ratio: 1440 / 384;
  object-fit: cover;

  @media (--viewportMobile) {
    height: 355px;
  }
}

.centerWrapper {
  display: flex;
  justify-content: center;
  margin-top: -300px;

  @media (--viewportSmall) and (max-width: 1366px) {
    margin-top: -140px;
  }

  @media (--viewportMobile) {
    margin-top: -250px;
  }
}

.headCenterWrapper {
  display: flex;
  justify-content: center;
}

.sliderContainer {
  max-width: 1501px;
  margin: 0 120px;
  width: 100%;

  @media (--viewportTabletDown) {
    margin: 0 50px;
  }

  @media (--viewportMobile) {
    margin: 0 30px;
  }
}

.topHeading {
  font-weight: 600;
  font-size: 22px;
  /* line-height: 15px; */
  padding-bottom: 0;
  text-align: right;
  margin-bottom: -5px;
  /* color: #6e6f71; */
  /* margin-right: 10px; */

  @media (--viewportMobile) {
    text-align: center;
    font-size: 24px;
  }
}

.topSubHeading {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #6e6f71;
  font-style: italic;
}

.topSubHeading1 {
  font-weight: 200;
  font-size: 16px;
  line-height: 22px;
  margin: 10px 0;
  /* padding-left: 50px; */
  color: #6e6f71;
  /* font-style: italic; */

  @media (--viewportMobile) {
    font-size: 20px;
  }
}

.topSubHeading2 {
  font-size: 14px;
  color: #6e6f71;
}

.topSubHeading3 {
  font-weight: 200;
  font-size: 16px;
  line-height: 22px;
  margin: 10px 0;
  /* padding-left: 50px; */
  color: #6e6f71;
  /* font-style: italic; */

  @media (--viewportMobile) {
    font-size: 20px;
    margin: 35px 0 15px 0;
  }
}

.detailSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;

  @media (--viewportMobile) {
    margin-bottom: 34px;
  }

  & h2 {
    margin: 0 !important;
    font-weight: 600;
    font-size: 25px;
    line-height: 31px;
    color: var(--marketplaceColorDarker);

    @media (--viewportMobile) {
      font-size: 20px;
      line-height: 25px;
    }
  }

  @media (--viewportMobile) {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
}

.detailSectionInfo {

  /* padding: 15px 0; */
  @media (--viewportMobile) {
    width: 76%;
    padding-left: 15px;
  }
}

.detailSectionAuthorNameLink:hover {
  text-decoration: none;
}

.detailInfo {
  font-weight: normal;
  font-size: 20px;
  line-height: 25px;
  color: var(--matterColorDimmedText);

  @media (--viewportMobile) {
    font-size: 14px;
    line-height: 18px;
  }
}

.reviewSpan {
  display: flex;
  align-items: center;

  color: var(--marketplaceColorDarker);

  &:not(:last-child) {
    padding: 0 83px 0 0;
  }

  & span {
    margin-left: 9px;
    display: block;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    color: var(--matterColorDarkBlue);
  }

  @media (--viewportMobile) {
    &:not(:last-child) {
      padding: 0 20px 0 0;
    }

    & span {
      font-size: 20px;
      line-height: 25px;
    }
  }
}

.whatsappSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
  column-gap: 8px;
  margin: 44px 0;

  @media (--viewportMobile) {
    width: 100%;
    margin: 69px 0 42px 0;
    justify-content: space-evenly;
  }
}

.whatsappSection :global(.SectionLevel_sectionLevel__3Jy-6) {
  margin-left: 16px;
  display: flex;
  align-items: center;
}

.listPublicInfo {
  line-height: 8px;
  padding: 0 0 0 33px;
  display: flex;
  flex-direction: column;
}

.authorDetailsContainer {
  display: flex;
  align-items: center;
  padding: 25px 0 24px 0;

  @media (--viewportMobile) {
    padding: 27px 0 37px 0;
  }
}

.authorDetails {
  margin: 0 0 0 15px;
  display: flex;
  flex-direction: column;

  @media (--viewportMobile) {
    margin: 0 0 0 15px;
  }
}

.authorDetailPromoHead {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  color: var(--marketplaceColorDarker);
  margin-bottom: 5px;

  @media (--viewportMobile) {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
  }
}

.authorDetailPromo {
  font-weight: normal;
  font-size: 22px;
  line-height: 28px;
  color: var(--marketplaceColor);

  @media (--viewportMobile) {
    font-size: 14px;
    line-height: 18px;
  }
}

.authorReviewInfo {
  padding: 5px 0 0 0;
  display: flex;
  align-items: center;

  @media (--viewportMobile) {
    justify-content: space-between;
    padding: 0 21px 0 6px;
  }
}

.authorReviewInfoDetail {
  padding: 0 0 37px;
  display: flex;
  align-items: center;

  @media (--viewportMobile) {
    padding: 0 0 45px 0;
  }
}

.descriptionFontStyle {
  color: var(--matterColorDimmedText);
  font-weight: 400;
  font-size: 20px;

  & span {
    font-size: 16px;
  }
}

/* .authorDisplayImage{
  height: 60px;
  width: 60px;
  border-radius: 50%;
} */

.authorDetailsLargeImageRelative {
  position: relative;
  height: 96px;
  width: 96px;
  border-radius: 50%;
  border: 3px solid var(--matterColorLightBlue);
  padding: 22px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (--viewportMobile) {
    height: 67px;
    width: 67px;

    & div {
      height: 54px;
      width: 54px;
      min-width: 54px;
    }
  }
}

.TopHead {
  display: flex;

  @media (--viewportMobile) {
    display: flex;
    flex-direction: column;
  }
}

.authorDescriptions {
  display: flex;
  column-count: 3;
  column-gap: 30px;

  & span {
    font-size: 18px;
    color: var(--matterColorDimmedText);
    font-weight: 400;
  }

  @media (--viewportMobile) {
    display: block;
    column-count: 1;
    margin-top: 23px;
  }
}

.authorDescriptionsContainer {
  max-width: 1154px;
  /* border-top: 1px solid #c4c4c4; */
  margin: 0 auto;
  padding: 0 12px 88px 12px;

  @media (max-width: 768px) {
    padding: 0 24px;
  }

  @media (--viewportMobile) {
    margin: 0 22px 10px;
    border-top: 1px solid #c4c4c4;
    padding: 0;
    padding-top: 19px;
  }
}

.greyReviewSection {
  display: flex;
  justify-content: center;
  padding: 26px 0 36px 0;

  @media (--viewportMobile) {
    padding: 37px 0 45px 0;
    margin-bottom: 0;
  }
}

.mainReviewContainer {
  background: var(--marketplaceColorLightWhiteI);
  padding: 30px 15px;
  max-width: 1531px;
  margin: 0 120px;
  width: calc(100% - 240px);

  @media (--viewportLaptopDown) {
    margin: 30px;
    width: calc(100% - 60px);
  }

  @media (--viewportMobile) {
    margin: 20px 15px;
    width: calc(100% - 30px);
  }

  & h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 38px;
    color: #272727;

    @media (--viewportMobile) {
      font-weight: 700;
      text-align: center;
      padding: 15px 0;
    }
  }
}

.headStar {
  width: 24px;
  height: 24px;
  margin: 0 6px 0 0;

  @media (--viewportMobile) {
    margin: 0 7px 0 0;
  }
}

.reviewStar {
  height: 29px;
  width: 29px;

  @media (--viewportMobile) {
    height: 25px;
    width: 25px;
  }
}

.iconCheckedShield {
  height: 28px;
  width: 25px;

  @media (--viewportMobile) {
    height: 25px;
    width: 25px;
  }
}

.reviewHeadSpan {
  display: flex;
  align-items: center;
  padding: 0 5px 0 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: var(--marketplaceColorDarker);
}

.HeadSpan {
  font-style: normal;
  margin-left: 15px;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: var(--marketplaceColorDarker);

  @media (--viewportMobile) {
    font-size: 13px;
    margin-left: 12px;
    line-height: 15px;
  }
}

.HeadSpan2 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  margin-left: 25px;
  color: var(--marketplaceColorDarker);

  @media (--viewportMobile) {
    font-size: 13px;
    margin-left: 13px;
    line-height: 16px;
  }
}

.bookingPanelContainer {
  /* width: 100%; */
  display: flex;
  justify-content: flex-end;

  @media (--viewportTabletDown) {
    width: 0%;
  }
}

.imageSlider {

  /* max-width: 1154px;
  margin: 0 auto; */
  @media (max-width: 768px) {
    padding: 0 12px;
  }

  @media (--viewportMobile) {
    padding: 0;
  }
}

.iconMasterVerified {
  width: 152px;
  height: 45px;
}

.avatarLargeImage {
  width: 78px;
  min-width: 78px;
  height: 78px;
}

.listingFooter :global(.Footer_content__3LKvi) {
  max-width: 1154px;
  padding: 0;

  @media (max-width: 768px) {
    padding: 0;
  }
}

.mobileData {
  padding: 0 1px;
  margin-top: 26px;

  @media (min-width: 1024px) {
    display: none;
  }
}

.desktopData {
  display: flex;
  align-items: center;

  @media (max-width: 1024px) {
    display: none;
  }
}

.mobileTopbar {
  /* Size */
  width: 100%;
  /* height: var(--topbarHeight); */
  height: auto;
  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 4px 20px 10px rgba(0, 0, 0, 0.02);

  /* fill */
  background-color: var(--matterColorLight);

  /* shadows */
  padding: 7px 22px 5px 27px;

  @media (--viewportLarge) {
    display: none;
  }

  @media (--viewportSmall) {
    padding: 0 24px;
  }
}

.smallImageCollection {
  display: flex;
  column-gap: 12px;

  @media (--viewportMedium) {
    column-gap: 16px;
  }
}

.landScapeImage {
  width: 100%;
  height: 289px;
  object-fit: cover;
  margin-bottom: 10px;
  border: 5px solid #ffffff;
  border-radius: 12px;

  @media (--viewportMedium) {
    height: 332px;
    margin-bottom: 21px;
  }
}

.smallImage {
  width: 48.5%;
  height: 234px;
  object-fit: cover;

  @media (--viewportMedium) {
    height: 331px;
  }
}

.mobileChatSection {
  position: fixed;
  bottom: 100px;
  right: 10px;
}

.whatsappInboxSection {
  max-width: 344px;
  height: 171px;
  background: linear-gradient(90deg,
      var(--matterColorLight) -26.02%,
      VAR(--matterColorLight) 129.31%);
  box-shadow: 0px -5px 40px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
  display: flex;
  padding: 32px 27px 29px;
  gap: 0 38px;

  & a {
    text-decoration: none !important;
  }

  & .iconWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    gap: 12px 0;

    & span {
      font-weight: bold;
      font-size: 19px;
      line-height: 11px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #233045;
    }
  }

  & .iconImageWrapper {
    width: 81px;
    height: 81px;

    & img {
      width: 100%;
      height: auto;
    }
  }
}

.mobileChatWrapper {
  width: 98px;
  height: 42px;
  display: flex;
  align-items: center;
  gap: 0 11px;
  background: var(--matterColorLight);
  border: 1px solid #f1f1f1;
  box-sizing: border-box;
  box-shadow: 0px 8px 26px rgba(33, 108, 123, 0.15);
  border-radius: 33px;

  & span {
    font-weight: bold;
    font-size: 14px;
    line-height: 12px;
    color: var(--matterColorDarkBlue);
  }
}

.avatar {
  height: 30px;
  width: 30px;
}

.activeUser {
  height: 52px;
  width: 34px;
  position: relative;
  margin-left: 7px;
  display: flex;
  align-items: center;
}

.active {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: #6cef3e;
  border: 3px solid var(--matterColorLight);
  top: 65%;
  right: 0;
  position: absolute;
}

.close {
  position: absolute;
  top: 4px;
  right: 10px;
  z-index: calc(100 + 1);
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  width: auto;
  margin: 0;
  border: 0;
}

.closeBtnWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.closeIcon {
  @apply --marketplaceModalCloseIcon;
  margin-left: 0;
  padding: 0;
}

.svgWrapper {
  display: flex;
  align-items: center;
  column-gap: 19px;
}

.svgWrapper svg {
  width: 25px;
}

.currentListingAddress {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-weight: normal;
  font-size: 13.5px;
  line-height: 17px;
  text-decoration-line: underline;
  color: var(--matterColorDark);
}

.timeInfoWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  @media (--viewportMobile) {
    width: 100%;
  }
}

.timesInfoTitle {
  width: 100%;
  font-weight: normal;
  font-size: 13.5px;
  line-height: 17px;
  color: var(--matterColorDark);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.goBackArrow {
  width: 18px;
  height: 18px;
  margin: 0 11px 0 0;

  @media (--viewportMobile) {
    margin: 0 7px 0 0;
  }
}

.backToSearch {
  max-width: 100%;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  color: var(--matterColorLight);
  padding: 3px 10px;
  background: var(--marketplaceColorBackground);
  border: 1.4px solid #324259;
  box-sizing: border-box;
  border-radius: 30px;
  margin-right: 9px;
  display: flex;
  align-items: center;
  cursor: pointer;

  & svg {
    width: 22px;
    height: 23px;
  }

  @media (--viewportMedium) {
    padding: 0 15px;
    font-size: 15px;
    line-height: 22px;
    margin-right: 24px;
    white-space: nowrap;

    & svg {
      width: 33px;
      height: 32px;
    }
  }

  @media (--viewportMobile) {
    & span {
      display: none;
    }
  }

  &:hover {
    color: var(--matterColorDarkBlue);
    background: var(--matterColorLight);

    & svg {
      & path {
        stroke: var(--matterColorDarkBlue);
      }
    }
  }
}

.mainContentWrapper h2 {
  font-weight: 700;
  font-size: 28px;
  line-height: 38px;
  text-transform: uppercase;
  color: #233045;
  margin-bottom: 16px;
  margin-top: 0;
}

.mainContentWrapper {
  max-width: 1501px;
  margin: 44px auto;
}

.btnWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
}

.carousel {}

.photoSliderWrapper .slick-slider .slick-list .slick-track .slick-slide {
  padding: 24px;
}

.photoSingleImageContainer {
  /* width: 188px !important; */
}

.photoSingleVideoContainer {
  /* width: 384px !important; */
  position: relative;
  margin: 2px;

  & svg {
    position: absolute;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
  }
}

.photoImage {
  width: 100%;
  height: auto;
  max-width: 100%;
  object-fit: cover;
  border-radius: 4%;
}

.videoImage {
  width: 100%;
  aspect-ratio: 1920 / 1080;
  background-color: black;
  height: auto;
  object-fit: contain;
  border-radius: 4%;
}

.seeMoreButton {
  background-color: var(--matterColorLight);
  border: 1px solid #00ab99;
  box-sizing: border-box;
  border-radius: 8px;
  font-weight: 600;
  font-size: 22px;
  padding: 0 24px;
  line-height: 55px;
  text-align: center;
  text-transform: capitalize;
  color: #00ab99;
  max-width: 250px;
}

.greyReviewSection :global(.slick-initialized .slick-list .slick-track .slick-slide):last-child {
  margin-right: 0;
}

.greyReviewSection [class*='seeMoreLessLink'] {
  margin-top: 12px;
}

.greyReviewSection [class*='reviewItem'] {
  margin: 0;
  padding: 0 20px 20px;
}

.greyReviewSection :global(.slick-initialized .slick-list .slick-track .slick-slide) {
  width: 328px !important;
  margin-right: 24px;
}

.videoGallery {
  position: fixed;
  z-index: 12000;
}




.favButtonWrapper {
  margin-bottom: -2px;
}

.favButtonNotSelected {
  cursor: pointer;
  display: block;

  &:hover {
    transform: scale(1.1);
    filter: invert(18%) sepia(96%) saturate(6390%) hue-rotate(332deg) brightness(93%) contrast(108%);
  }
}

.titleWrapper {
  display: flex;
  flex-direction: row;
}


.favButtonSelected {
  cursor: pointer;
  display: block;
  filter: invert(18%) sepia(96%) saturate(6390%) hue-rotate(332deg) brightness(93%) contrast(108%);

  &:hover {
    transform: scale(1.1);
  }
}




.travelBusinessInfo li .textWrap {
  font-size: 16px;
  margin: 0 0 0 5px;
  line-height: 30px;
}

.travelBusinessInfo li .ml5 {
  margin-left: 24px;
}

.travelBusinessInfo li p {
  font-size: 16px;
  margin: 0 0 0 5px;
  line-height: 30px;
  /* white-space: nowrap; */
  white-space: wrap;
}

.travelBusinessInfo li h5 {
  font-weight: 700;
  line-height: 30px;
  white-space: nowrap;
  margin: 0 0 0 24px;
}

.travelBusinessInfo li img {
  margin-right: 8px;
  position: absolute;
  left: 0;
  top: 9px;
}

.travelBusinessInfo li {
  list-style: none;
  color: #272727;
  display: flex;
  position: relative;
  align-items: flex-start;
}

.travelBusinessInfo h2 {
  font-weight: 700;
  font-size: 28px;
  line-height: 38px;
  color: #233045;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* margin-top: 40px; */
}

.travelBusinessInfo {
  max-width: 1501px;
  margin: 40px 120px;
  width: 100%;
  padding: 0 12px;

  @media (--viewportMobile) {
    margin: 20px 15px;
  }

  @media (--viewportSmall) and (--viewportLaptopDown) {
    margin: 30px;
  }
}

.travelBusinessInfoContainer {
  display: flex;
  justify-content: center;
}

.verticalAlignCenter {
  display: flex;
  align-items: center;
}

.businessIdentityWrapper {
  display: flex;
  align-items: center;
  column-gap: 2px;

  @media (--viewportSmall) {
    column-gap: 5px;
  }
}

.profileIdentityWrapper {
  width: 104px;
  height: 41px;
  background: #f5f5f5;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 11px;
}

.profileIdentityWrapper span {
  font-size: 10px;
  line-height: 15px;
  color: #6e6f71;
  font-weight: 600;
  text-align: right;
}