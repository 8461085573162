@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --SearchPage_containerHeight: calc(100vh - var(--topbarHeightDesktop));
}

.layoutWrapperContainer {
  /* composes: container; */
  /* parent aka root is flexbox, this container takes all available space */
  flex-shrink: 0;

  /* This container uses flexbox layout */
  display: flex;
  flex-direction: column;
  width: 100%;

  /* When side nav is shown as a column, Topbar's box-shadow should be visible */
  z-index: calc(var(--zIndexTopbar) - 1);

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: center;
  }
}

.layoutWrapperFilterColumn {
  display: none;

  @media (--viewportMedium) {
    /* Layout */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 24px 0 0 24px;
  }
  @media (--viewportLarge) {
    /* Layout */
    padding: 40px 0 0 36px;

    flex-direction: column;
    justify-content: flex-start;
    border: none;
    box-shadow: none;
    background-color: transparent;
  }

  @media (min-width: 1440px) {
    padding: 40px 0 0 calc((100vw - 1368px) / 2);
  }
  @media (--viewportXLarge) {
    padding: 40px 0 0 calc((100vw - 1800px) / 2);
  }
}

.filterColumnContent {
  width: 210px;
  padding: 8px 24px 0 0;

  @media (--viewportMedium) {
    padding: 8px 24px 24px 0;
  }

  @media (--viewportLarge) {
    width: 240px;
    padding: 8px 36px 24px 0;
  }

  @media (min-width: 1440px) {
    padding: 8px 60px 24px 0;
  }
}
.filter:first-child {
  padding-top: 0;
}

.resetAllFiltersButton,
.resetAllButton {
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);

  /* Layout */
  display: inline;
  margin: 17px 0 0 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--matterColor);
  }

  @media (--viewportMedium) {
    margin: 24px 0 0 0;
    padding: 3px 0 5px 0;
  }
}
.layoutWrapperMain {
  /* Expand to the full remaining width of the viewport */
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0 24px 0 24px;

  @media (--viewportMedium) {
    /**
     * Calculate right padding to match Footer right border
     * and left padding together with border should be 60px.
     */
    padding: 24px 24px 0 24px;
    background-color: var(--matterColorLight);
    border-left-width: 1px;
    border-left-style: solid;
    border-left-color: var(--matterColorNegative);
  }

  @media (--viewportLarge) {
    /**
     * Calculate right padding to match Footer right border
     * and left padding together with border should be 60px.
     */
    padding: 40px 36px 0 36px;
  }

  @media (min-width: 1440px) {
    padding: 40px calc((100vw - 1368px) / 2) 0 59px;
  }
  @media (--viewportXLarge) {
    padding: 40px calc((100vw - 1800px) / 2) 0 59px;
  }
}
.resetAllFiltersButton {
  color: var(--marketplaceColor);
  margin: 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}
/* Can be given to Topbar when a modal is opened
 * so that the Topbar is not rendered on top of it.*/
.container {
  max-width: 1154px;
  margin: 0 auto;
}

.subContainer {
  /* display: flex;
   @media (--viewportMobile) {
     flex-direction: column-reverse;
   } */
}

.topSection {
  padding: 44px 50px 18px 80px;
  background-size: cover;
  background: var(--matterColorLight);
  background-repeat: no-repeat;
  height: auto;
  margin-bottom: 24px;
  @media (--viewportTabletDown) {
    background-size: 100% 250px;
    padding-bottom: 0;
    padding: 22px 24px 60px;
    /* display: none; */
  }
}

.listings {
  padding-top: 22px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.productListings {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.searchResultContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 2px 0 0;
  height: 100%;
  position: relative;
  @media (--viewportMobile) {
    padding: 2px 0 200px;
  }
  @media (--viewportMedium) {
    /**
      * .container is using flexbox,
      * This specifies that searchResultContainer is taking 60% from the viewport width
      */
    /* flex-basis: 50%; */
  }

  @media (--viewportLarge) {
    /* flex-basis: 62.5%; */
  }
}

.newSearchInProgress {
  opacity: 0.1;
}
.error {
  color: var(--failColor);
  @media (--viewportMedium) {
    padding-left: 75px;
  }
}
.searchListingsPanel {
  flex-grow: 1;
  & nav {
    background: var(--matterColorLightGrey);
  }
}
.searchFiltersPanel {
  flex-grow: 1;
  display: flex;
  padding: 0 24px 24px 24px;

  @media (--viewportLarge) {
    padding: 0 36px 36px 36px;
  }
}
.mainPanelHeader {
  display: flex;
  flex-direction: column;
  /* margin: 0 52px; */
  padding: 0 0 0 80px;
  @media (--viewportMobile) {
    padding: 20px 24px;
  }
}

.mainPanel {
  display: none;

  @media (--viewportMedium) {
    display: flex;
  }
}

.mainPanelMapVariant {
  composes: mainPanel;

  @media (--viewportMedium) {
    padding: 18px 24px 0 24px;
    margin-bottom: 21px;
  }

  @media (--viewportLarge) {
    padding: 23px 36px 0 36px;
  }
}

.reviewContainer {
  height: 524px;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  background: #bdddde;
  @media (--viewportMobile) {
    height: auto;
    padding: 30px 0;
  }
}
.reviewContent {
  height: 232px;
  background: var(--matterColorLight);
  border-radius: 5px;
  width: 75%;
  padding: 32px;
  position: relative;
  justify-content: center;
  align-items: center;
  display: flex;
  @media (--viewportMobile) {
    height: auto;
    padding: 16px;
  }
}
.reviewContent::after {
  content: '';
  width: 0;
  height: 0;
  border-left: 18px solid transparent;
  border-right: 18px solid transparent;
  border-top: 18px solid var(--matterColorLight);
  bottom: -7%;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  @media (--viewportMobile) {
    bottom: -3%;
  }
}
.reviewProfile {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 32px;
}
.reviewAvatar {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  @media (--viewportMobile) {
  }
}
.quoteIcon {
  font-size: 50px;
  top: 20%;
  left: 30px;
  position: absolute;
  @media (--viewportMobile) {
    top: 5%;
  }
}

.reviewText {
  margin: 0 0 0 48px;
  font-size: 18px;
  line-height: 32px;
  font-family: 'Source Sans Pro';
  font-weight: normal;
  @media (--viewportMobile) {
    font-size: 16px;
    margin: 30px 0 0 10px;
  }
}

.reviewImage {
  width: 56px;
  height: 56px;
}

.reviewStar {
  display: grid;
}

.reviewProfileSection {
  display: flex;
  width: 75%;
  justify-content: space-evenly;
  @media (--viewportMobile) {
    width: 100%;
    /* justify-content: space-between; */
  }
}
.reviewName {
  width: 300px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  line-height: 20px;
}

.listingTypeContainer {
  margin-bottom: 30px;
  padding: 0 24px;
  @media (--viewportMobile) {
    & :global(.ListingTypeFilter_checkboxLabel__1tPEm) {
      padding: 10px 7px;
      font-size: 14px;
    }
  }
}

.link {
  /* Font */
  @apply --marketplaceH4FontStyles;
  line-height: 20px;
  color: var(--matterColor);
  transition: var(--transitionStyleButton);

  margin-top: 0;
  margin-bottom: 12px;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.noRecordFound {
  width: 100%;
  display: flex;
  justify-content: center;
}

.totalStays {
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #6e6f71;
  line-height: 30px;
}

.staysLocation {
  padding-top: 50px;
  margin: 0;
  & span {
    font-weight: 700;
    font-size: 35px;
    line-height: 35px;
    text-transform: capitalize;
    line-height: 35px;
    color: #272727;
  }
  & p {
    margin-top: 0;
  }
  @media (--viewportMobile) {
  }
}

.subHeading {
  margin: -10px 0 20px 0 !important;
  position: relative;
  z-index: 1;
  line-height: 0;
  & span {
    padding: 0 12px;
    margin: 16px 0 0;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-transform: capitalize;
    color: var(--matterColorAnti);
    background: var(--matterColorLight);
  }
  &::after {
    content: '';
    height: 2px;
    width: 100%;
    background: var(--lightBackground);
    top: 60%;
    left: 0;
    position: absolute;
    z-index: -1;
  }
}

.sectionMapContainer {
  margin: 0 120px;
  max-width: 1501px;

  @media (--viewportLaptopDown) {
    margin: 0 50px;
  }

  @media (--viewportMobile) {
    margin: 0 10px;
  }
}

.draggableWrapper {
  position: relative;
  z-index: 2;
  width: 100%;
  background: var(--matterColorLight);

  @media (--viewportLarge) {
    /* flex-basis: 62.5%; */
    /* padding: 23px 0 0 0; */
    z-index: 0;
  }
}

.draggableHandler {
  padding: 5px 0;
  background: var(--matterColorLightGrey);
  cursor: grab;
  @media (--viewportMobile) {
    border-radius: 27px 27px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--matterColorLight);
    padding: 9px 0 11px;
  }
}

.mapBtn {
  position: fixed;
  bottom: 100px;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 10px;
  width: 100px;
  height: auto;
  z-index: 3;
  background: var(--matterColorLight);
  border-radius: 20px;
  box-shadow: var(--boxShadowFilterButton);
  text-align: center;
  cursor: pointer;
  transition: visibility 0s 0.3s, opacity 0.3s linear;
  opacity: 0;
  visibility: hidden;
  & svg {
    margin-right: 10px;
    fill: var(--marketplaceColorDark);
    width: 20px;
  }
  &.shown {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s linear;
  }
}

.mapWrapper {
  width: 100%;
  height: 100%;
}
.mapWrapper :global .mapboxgl-canvas {
  width: 37.5vw !important;
  @media (min-width: 1750px) {
    width: calc(100vw - 1089px) !important;
  }
  @media (--viewportTabletDown) {
    width: 100% !important;
    height: 100% !important;
  }
}

.mapPanel {
  position: fixed;
  /* top: -60px; */
  top: -8px;
  z-index: 1;

  @media (--viewportLarge) {
    /* position: static; */
    position: sticky;
    top: 114px;
    right: 0;
    height: 100vh;
    /* margin-top: 48px; */
    flex-basis: 37.5%;
    z-index: 0;
  }
}

.map {
  width: 100vw;
  height: 100vh;

  @media (--viewportMedium) {
    /* Map is fixed so that it doesn't scroll along search results */
    position: sticky;
    /* top: var(--topbarHeightDesktop); */
    top: 114px;
    right: 0;

    /* Fixed content needs width relative to viewport */
    width: 100vw;
    height: var(--SearchPage_containerHeight);
  }

  @media (min-width: 1750px) {
    width: calc(100vw - 1089px) !important;
  }

  @media (--viewportLarge) {
    width: 37.5vw;
  }
}

.resultHead {
  @media (--viewportTabletDown) {
    margin-top: 20px;
  }
  @media (max-width: 768px) {
    padding-left: 20px;
  }
}

.modeHeadingWrap {
  display: flex;
  column-gap: 22px;
}

.modeItems {
  display: flex;
  column-gap: 17px;

  & .modeItem {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
    text-transform: capitalize;
    color: #6e6f71;
    box-sizing: border-box;
    white-space: nowrap;
  }

  & .selectedModeItem {
    color: var(--marketplaceColor);
    & svg path {
      fill: var(--marketplaceColor);
    }
  }
}

.mobileResultHead {
  padding: 22px 11px 8px;
  display: none;

  @media (--viewportMobile) {
    display: block;
  }
}

.footer {
  /* display: none;
  @media (--viewportLarge) {
    display: block;
  } */
}

.classCalendarContainer {
  max-width: 100%;
  @media (--viewportMobile) {
    margin: 30px 22px 24px;
    padding-left: 0;
  }
}

.searchFiltersMobileList {
  padding: 16px 0;

  @media (--viewportMedium) {
    display: none !important;
  }
}

.topSectionWrapper {
  @media (--viewportMobile) {
    display: none;
  }
}

.mobileFilterTypeElement {
  @media (--viewportMedium) {
    display: none;
  }
}

.filterTypeButtonWrapper {
  display: flex;
  gap: 0 11px;
  padding: 28px 15px 28px 70px;
  background: var(--matterColorLight);
  @media (--viewportMobile) {
    gap: 0 7px;
    width: 100%;
    padding: 0px 19px 21px 17px;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  & .filterTypeButton {
    background: var(--matterColorLight);
    border: 1.5px solid #f1f1f1;
    padding: 13px 22px;
    box-sizing: border-box;
    border-radius: 50px;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    color: var(--marketplaceColorDarkest);
    @media (--viewportMobile) {
      font-size: 13px;
      line-height: 20px;
      white-space: nowrap;
      padding: 10px 18px 12px;
    }
  }

  & .selectedFilterTypeButton {
    background: #e7fbff;
    color: var(--marketplaceColor);
  }
}

.filterKeywordWrap {
  justify-content: left;
  align-items: center;
  margin-left: 77px;
  display: none;
  @media (--viewportMedium) {
    display: flex;
  }
}

.filterWrap {
  width: 375px;
  height: 46px;
  padding: 0 6px 0 16px;
  background: var(--matterColorLight);
  border-radius: 28px;
  border: 1px solid #d6d6d6;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0 8px;
  @media (--viewportMobile) {
    width: 100%;
    border-radius: 28px;
    margin-bottom: 18px;
  }
}
.filterWrap label {
  padding: 0 10px;
  margin: 0 20px 0 0;
  @media (--viewportMobile) {
    margin: 0 6px 0 0;
  }
}

.filterWrap svg {
  width: 21px;
  height: 21px;
  transform: scaleX(-1);
}
.filterKeywordWrap input {
  width: 214px;
  border-bottom: none;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: var(--matterColorDimmedText);
  @media (--viewportMobile) {
    width: 330px;
  }
}

.btnapply {
  width: 85px;
  min-width: 63px;
  border-radius: 16.5601px;
  min-height: 33px;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  text-transform: capitalize;
  margin-left: 14px;
  color: var(--matterColorLight);
  background: var(--marketplaceColor);
  @media (--viewportMobile) {
    display: none;
  }
}
.classMode {
  padding: 0 0 20px 75px;
  display: flex;
  justify-content: center;
  @media (--viewportMobile) {
    display: none;
  }
}
.partnershipWrapper {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.partnershipWrapper p {
  font-style: italic;
  font-weight: 400;
  font-size: 16.5295px;
  line-height: 21px;
  /* identical to box height */

  text-align: center;

  color: #6e6f71;
}
.partnershipButtonWrapper {
  padding: 8px 0px;
  margin: 1rem 1rem 3rem 1rem;
}

.partnershipButton {
  background: var(--marketplaceColor);
  border-radius: 5px;
  /* font-style: italic; */
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: var(--matterColorLight);
  width: 185px;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    text-decoration: none;
  }

  @media (--viewportMobile) {
    max-width: 10rem;
  }
}

.businessText {
  margin-bottom: 100px;
  @media (--viewportTabletDown) {
    margin-bottom: 50px;
  }
}

.pageWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
