@import '../../marketplace.css';

.header {
  margin-bottom: 24px;
}

.headerTitle {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  font-style: normal;
  font-weight: bold;
  color: #253045;
  margin: 10px 40px;
}

.iconWrap {
  display: flex;
  align-items: center;
  margin-right: 8px;
  justify-content: center;
  width: 32px;
  height: 32px;
  background: #f5f7fc;
  border-radius: 8px;
}

.headerTitleText {
  font-size: 16px;
}

.headerDate {
  color: var(--marketplaceColor);
}

.headerMain {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  margin-left: 40px;
}
.gross {
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 48px;
  color: var(--marketplaceColorDark);
  @media (--viewportMobile) {
    font-size: 36px;
    line-height: 36px;
  }
}
.paymentNum {
  margin-left: 5px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #9097a3;
}

.chartSection {
  padding: 40px;
  border-radius: 8px;
}

.chartMenu {
  display: flex;
  margin-bottom: 24px;
  width: 360px;
  border-bottom: 1px solid #e3e7f0;
  @media (--viewportMobile) {
    width: 100%;
  }
}

.chatMenuItem {
  margin-right: 32px;
  height: 40px;
  font-size: 14px;
  cursor: pointer;
  color: var(--marketplaceColorDark);
  transition: color 0.2s linear;
  &:hover {
    color: var(--matterColor);
  }
}
.chatMenuItemActive {
  position: relative;
  color: var(--matterColor);
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 4px;
    background: var(--marketplaceColorDark);
    border-radius: 4px;

    @media (--viewportMobile) {
      width: 20px;
    }
  }
}

.periodGross {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 36px;
  flex-wrap: wrap;
  width: 57%;
  @media (--viewportMobile) {
    width: 100%;
  }
}

.grossHeader {
  margin-bottom: 7px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  color: #253045;
}

.periodDateWrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.chartWrap {
  overflow: auto;
}

.dateIcon {
  margin: 0 10px;
}

.img {
  background-color: var(--marketplaceColor);
  border: 2px solid #D7E3F5;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 64px;
  width: 30px;
  height: 30px;
}

.left {
  transform: scaleX(-1);
}

.date {
  font-size: 16px;
  margin: 0 15px;

  @media (--viewportMobile) {
    font-size: 14px;
  }
}
