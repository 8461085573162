@import '../../marketplace.css';

.root {
  position: relative;
  width: 100%;
  background: var(--matterColorLight) url('../../assets/loader.gif') no-repeat center;
  &:after {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--matterColorLight) url('../../assets/loader.gif') no-repeat center;
    font-family: 'Helvetica';
    font-weight: 300;
    line-height: 2;
    text-align: center;
    content: '';
  }
}

.noImageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--matterColorNegative);
}

.noImageWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.noImageIcon {
  padding-top: 24px;
  box-sizing: content-box;
  stroke: var(--matterColor);
}

.noImageText {
  @apply --marketplaceH6FontStyles;
  color: var(--matterColor);
  padding-bottom: 24px;

  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}
