@import '../../marketplace.css';

.root {
  /* Layout */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  /* Content of EditListingWizard should have smaller z-index than Topbar */
  /* z-index: 0; */
}

.tabsContainer {
  flex-grow: 1;
  /* Layout */
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  @media (--viewportLarge) {
    flex-direction: row;
    /* justify-content: center; */
  }
}

.nav {
  /* Layout */
  display: flex;
  flex-direction: row;
  padding: 0;
  flex-shrink: 0;
  /* background-color: var(--matterColorLight); */
  background-color: var(--marketplaceColorBackground);
  box-shadow: var(--boxShadowLight);
  /* border-top: 1px solid var(--matterColorNegative); */
  overflow-x: scroll;
  height: 104px;
  align-items: center;

  @media (--viewportLarge) {
    max-width: 279px;
    width: 279px;
    /* padding: 128px 0 82px 36px; */
    flex-direction: column;
    overflow-x: auto;
    align-items: flex-start;
    /* background-color: var(--matterColorBright); */
    background-color: var(--marketplaceColorBackground);
    box-shadow: none;
    border-top: none;
    position: sticky;
    top: 0px;
    height: 100vh;
  }

  /* @media (--viewportLargeWithPaddings) {
    /* padding: 128px 0 82px calc((100% - 1056px) / 2); */
  /*} */
}

.tab {
  margin-right: 8px;
  white-space: nowrap;

  &:first-child {
    margin-left: 0;

    /* Padding added to tab so that it will be visible after call to scrollToTab */
    padding-left: 24px;
  }

  &:last-child {
    /* Padding added to tab so that it will be visible after call to scrollToTab */
    padding-right: 24px;
  }

  @media (--viewportLarge) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
}

.panel {
  flex-grow: 1;

  @media (--viewportLarge) {
    padding: 88px 59px 82px 59px;
    border-left: 1px solid var(--matterColorNegative);
    background-color: var(--matterColorLight);
  }

  @media (--viewportLargeWithPaddings) {
    padding: 88px 80px 82px 80px;
  }
}

.payoutDetails {
  margin-bottom: 100px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.modalTitle {
  @apply --marketplaceModalTitleStyles;
}

.modalPayoutDetailsWrapper {
  @media (--viewportMedium) {
    width: 604px;
    padding-top: 11px;
  }
}

.requestDescriptionContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 53px;
  margin: 112px 0 64px 285px;
  @media (--viewportMobile) {
    margin: 34px 0 -5px;
    flex-direction: column-reverse;
  }
}

.requestInfoWrapper {
  max-width: 374px;
  @media (--viewportMobile) {
    max-width: 100%;
    padding: 22px;
    margin-top: 25px;
    background-color: var(--marketplaceColorBackground);
  }
  & .requsetTitleBlue {
    font-weight: bold;
    font-size: 30px;
    line-height: 45px;
    color: var(--marketplaceColor);
  }
  & .requsetTitle {
    font-weight: bold;
    font-size: 30px;
    line-height: 45px;
    color: var(--matterColorDarkBlue);
    @media (--viewportMobile) {
      color: var(--matterColorLight);
    }
  }
  & p {
    font-weight: normal;
    font-size: 15px;
    line-height: 30px;
    color: var(--matterColorDarkBlue);
    margin-top: 22px;
    @media (--viewportMobile) {
      color: var(--matterColorLight);
    }
  }
}

.postRequestGifContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 454px;
  @media (--viewportMobile) {
    width: 100%;
    padding: 0 22px;
  }
}

.postRequestGif {
  width: 315px;
  height: 315px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 23px;
  z-index: 2;
  @media (--viewportMobile) {
    height: auto;
    width: 80%;
  }
}

.imagePostRequestGifBackground {
  width: 100%;
  max-width: 392px;
  max-height: 241px;
  position: absolute;
  @media (--viewportMobile) {
    width: 90%;
    height: auto;
  }
}

.payoutModalInfoNote {
  display: inline-table;
  padding: 10px;
  font-weight: normal;
  font-size: 16px;
  height: 18px;
  background-color: #ffffcc;
  border-left: 6px solid #ffeb3b;
}

.noteFont {
  font-weight: bold;
  font-size: 16px;
  height: 18px;
}

.panelNavWrapper{
  width: 100%;
}

.photoNav{
  padding: 0 65px;
}
