@import '../../marketplace.css';

.root {
  position: relative;
  padding-top: 30px;
  padding-bottom: 17px;
  border-bottom: 1px solid #293851;
  @media (--viewportMobile) {
    padding: 23px 0 14px;
  }
}

.filterLabel {
  margin: 0;
  padding: 4px 0 2px 0;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: var(--matterColorLight);
}

.averagePriceInfo{
    font-size: 14px;
    line-height: 18px;
    color: #CFCFCF;
}

.labelButton {
  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  padding: 0;
  cursor: pointer;
}

.formWrapper {
  /* padding-left: 20px; */
}

.clearButton {
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);

  /* Layout */
  display: inline;
  float: right;
  margin-top: 6px;
  padding: 0;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;

  &:focus,
  &:hover {
    color: var(--matterColor);
  }
}
