@import '../../../../marketplace.css';



.enquiriesRoot{
    width: 100%;
    display: flex;
    @media (--viewportMobile) {
        display: block;
    }
}

.cardItems{
    padding: 0 24px;
    @media (--viewportMedium) {
        padding: 0 30px 0 0;
    }
}

.leftSide{
    width: 30%;
    border-right: 1px solid #D9D9D9;
    padding-top: 30px;
}

.mobileSenderList{
    width: 100%;
    padding: 30px 0 0;
    display: none;
}

.showMobileSenderList{
    display: block;
}

.rightSide{
    width: 70%;
    position: relative;
}

.mobileChatBoxInfo{
    width: 100%;
    position: relative;
    display: none;
    padding: 0 24px;
}

.showMobileChatBoxInfo{
    display: block;
}


.headSection {
    padding-bottom: 25px;
    @media (--viewportMobile) {
        border-bottom: 1px solid #D9D9D9;
        padding: 0 24px 25px;
    }
}

.chatBoxmessage{
     display: flex;
     justify-content: flex-start;
     padding-top: 24px;
}


.msgHeadingWrapper{
    display: flex;
    align-items: center;

    & .masged{
        padding-left:22px;
        font-size: 26px;
        font-weight: 700;
     line-height: 35px;
     text-align: center;
     color: #233045;


    }
} 

.inboxSearchWrapper{
    display: flex;
    justify-content: space-between;
    padding-top: 29px;
    align-items: center;
    padding-bottom: 31px;

}

.filterKeywordWrap {
  margin-right: 5px;
}

.filterSvg{
padding-right: 30px;
}

.filterWrap {
    width: 100%;
    height: 46px;
    padding: 0 6px 0 16px;
    background: var(--matterColorLight);
    border-radius:28px;
    border: 1px solid #d6d6d6;
    background: #F8F8F8;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media (--viewportMobile) {
        width: 100%;
        max-width: 287px;
        height: 44px;
        border-radius: 28px;
        padding: 0 8px 0 16px;
        /* margin-bottom: 18px; */
    }
}
.filterWrap label {
    padding: 0 10px;
    margin: 0 20px 0 0;
    @media (--viewportMobile) {
        margin: 0 6px 0 0;
    }
}

.filterWrap svg {
    width: 21px;
    height: 21px;
    transform: scaleX(-1);
    stroke: #C9C9C9;
}

.filterKeywordWrap input {
    width: 100%;
    border-bottom: none;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: var(--matterColorDimmedText);
    @media (--viewportMobile) {
        width: 100%;
        &::placeholder {
        font-size: 12px;
        line-height: 20px;
        }
    }
}

.filterKeywordWrap input::placeholder{
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    padding-left: 12px;
    color: #AAAAAA;
}








